class CronoRecursosVH extends AmaisVH {
	
	idCronoListener: string = "crono-recursos";

	constructor() {
		super(CronoRecursosVH.name);
	}

	gerarHtmlCronometroRecursos(to: CfgsCronoRecursosTO): string {

		if (!to) return "";

		const title = to.isCronoPorProva ? this.getMsg("FP_FRONT_RecursoVH_081") : this.getMsg("FP_FRONT_RecursoVH_008");

		return `
			<div contagem-regressiva>
				<span class="label label-default" title="${title}" data-millis-restantes="${to.millisRestantes}">
					${this.getTempoRestanteRecursos(to.millisRestantes)}
				</span>
			</div>
		`;
	}

	getTempoRestanteRecursos(millis) {
		if (millis <= 0) {
			return this.getMsg("FP_FRONT_RecursoVH_010")
		} else {
			return UtilData.getDuracao({ millis: millis });
		}
	}

	pararCronometroRecursos() {
		UtilCrono.delListener(this.idCronoListener);
	}
	
	async iniciarCronometroRecursos(cfgs: CfgsInicioCronoRecursos) {

		if (!cfgs.cfgsCronoRecursosTO) return;

		try {
			
			this.pararCronometroRecursos();

			if (cfgs.cfgsCronoRecursosTO.isCronoPorProva) {

				this.logger.info(`Iniciando crono recursos com ${cfgs.cfgsCronoRecursosTO.millisRestantes}ms restantes para a prova.`);

				UtilCrono.addListener(this.idCronoListener, async (msDecorridos) => {
					await this.handleCronoTick(msDecorridos, cfgs.onTempoProvaEsgotado);
				});

			} else if (cfgs.cfgsCronoRecursosTO.isCronoPorQuestao) {
				
				this.logger.info(`Iniciando crono recursos com ${cfgs.cfgsCronoRecursosTO.millisRestantes}ms restantes para a codRespostaQuestao ${cfgs.cfgsCronoRecursosTO.codRespostaQuestao}.`);

				UtilCrono.addListener(this.idCronoListener, async (msDecorridos) => {
					await this.handleCronoTick(msDecorridos, cfgs.onTempoQuestaoEsgotado);
				});
	
				await this.call("RecursoFCD/iniciarCronometroInterposicaoRecurso", cfgs.cfgsCronoRecursosTO.codRespostaQuestao);

			}
			
		} catch (e) {
			this.logger.error("Erro ao iniciar cronômetro", e);
		}
	}
	
	async handleCronoTick(msDecorridos: number, onTempoEsgotado: Function) {

		if (this.isEmpty(msDecorridos) || msDecorridos < 1) return; // por segurança

		const $barraNavegacao = $('[barra-navegacao-recursos]');

		if ($barraNavegacao.length === 0) {
			this.logger.info("A tela de recursos foi fechada. Parando cronômetro.");
			this.pararCronometroRecursos();
			return;
		}

		const $contagem = $barraNavegacao.find("[contagem-regressiva] span");
		let millisRestantes = $contagem.data("millis-restantes");

		millisRestantes = millisRestantes - msDecorridos;

		if (millisRestantes <= 0) {
			this.pararCronometroRecursos();
			await onTempoEsgotado();
		} else {
			$contagem.data("millis-restantes", millisRestantes);
		}

		$contagem.text(this.getTempoRestanteRecursos(millisRestantes));
	}

	buildCfgsCronoRecursosTO(to: ExibicaoTelaInterposicaoRecursoTO, toQuestao: RecursosInterpostosDoUsuarioTO): CfgsCronoRecursosTO {

		if (to.milisegundosRestantesParaAberturaRecurso > 0) {
			return <CfgsCronoRecursosTO> {
				codProvaFeita: to.codProvaFeita,
				isCronoPorProva: true,
				isCronoPorQuestao: false,
				millisRestantes: to.milisegundosRestantesParaAberturaRecurso,
			}
		} else if (this.isEmpty(to.milisegundosRestantesParaAberturaRecurso) && toQuestao?.millisRestantesParaInterposicao > 0) {
			return <CfgsCronoRecursosTO> {
				codProvaFeita: to.codProvaFeita,
				codRespostaQuestao: toQuestao.codRespostaQuestao,
				isCronoPorProva: true,
				isCronoPorQuestao: false,
				millisRestantes: toQuestao.millisRestantesParaInterposicao
			}
		}
	}
}

type CfgsCronoRecursosTO = {
	codProvaFeita: number;
	codRespostaQuestao?: number;
	isCronoPorProva: boolean;
	isCronoPorQuestao: boolean;
	millisRestantes: number;
}
type CfgsInicioCronoRecursos = {
	cfgsCronoRecursosTO: CfgsCronoRecursosTO, 
	onTempoProvaEsgotado: Function, 
	onTempoQuestaoEsgotado: Function 
}
const cronoRecursosVH = new CronoRecursosVH();