class EncerramentoPFVH extends PFVH {
	
	codProvaFeitaEmVerificacao: any;
	posProvaCallback: Function;
	qtdeErrosFinalizacao = 0;

	constructor() {
		super(EncerramentoPFVH.name);
	}

	onFiscalizacaoMonitoradaInterrompida() {
		const $aplicacaoProva = this.get$AplicacaoProva();
		
		if (!$aplicacaoProva) return;
		
		const codProvaFeita = $aplicacaoProva.attr("cod-prova-feita");
		
		if (!codProvaFeita) return;
		
		this.logger.info("onFiscalizacaoMonitoradaInterrompida chamando encerrarProva");
		
		this.encerrarProva(codProvaFeita, false, null, true); // anulando
		
		this.exibirAlerta({
			titulo: this.getMsg("MSG_VH_APR_190"),
			msg: this.getMsg("MSG_VH_APR_191"),
			botoes: [{label: "OK"}]
		});
	}
	
	/**
	 * @returns se o encerramento foi concluído com sucesso
	 */
	async encerrarProva(codProvaFeita: number, encerradaPeloCronometro: boolean, regraMonitoramentoViolada?, anularPorTimoutFiscalizacaoMonitorada?): Promise<boolean> {
		
		const $aplicacaoProva = this.get$AplicacaoProva();
		
		if (!$aplicacaoProva) return false;
		
		if ($aplicacaoProva.data("fp-is-salvando-prova-feita")) {
			if (encerradaPeloCronometro !== true) {
				await this.exibirAlerta({ msg: this.getMsg("FP_FRONT_EncerramentoPFVH_001") });
			}
			return false;
		}
		
		$aplicacaoProva.data("fp-is-finalizando", true);

		const finalizacaoProvaFeitaTO = {
			codProvaFeita: Number(codProvaFeita),
			encerradaPeloCronometro: encerradaPeloCronometro,
			regraMonitoramentoViolada: regraMonitoramentoViolada,
			anularPorTimoutFiscalizacaoMonitorada: anularPorTimoutFiscalizacaoMonitorada,
			salvamentoProvaFeitaTO: salvamentoPFVH.getSalvamentoProvaFeitaTO(),
		}
		
		/* DEVE SALVAR TODAS AS RESPOSTAS NA FINALIZAÇÃO */ {
			
			finalizacaoProvaFeitaTO.salvamentoProvaFeitaTO.arraySalvamentoRespostaTOs = [];
			
			$("[painel-respostas] [numerador]").each((i, numerador) => {
				const salvamentoRespostaTO = $(numerador).data("salvamentoRespostaTO");
				if (salvamentoRespostaTO) {
					finalizacaoProvaFeitaTO.salvamentoProvaFeitaTO.arraySalvamentoRespostaTOs.push(salvamentoRespostaTO);
				}
			});
		}

		let finalizou = false;
		
		try {
			codProvaFeita = await this.call({
				endpoint: "AplicacaoProvaFCD/finalizarProvaFeita",
				params: [finalizacaoProvaFeitaTO],
				msgCarregando: this.getMsg("MSG_VH_APR_158") + "...",
				blockUiOnCarregando: true,
				onRequestError: async (e: BackendRequestError) => {

					$aplicacaoProva.data("fp-is-finalizando", false);
					
					if (e.isProvaAnulada() || e.isAplicacaoEncerrada()) {
						this.handleProvaAnulada(e.msgErro);
						return BackendRequestError.ERRO_TRATADO;
					}
					
					return BackendRequestError.ERRO_NAO_TRATADO;
				}
			});
			
			finalizou = true;
			this.qtdeErrosFinalizacao = 0;

			this.codProvaFeitaEmVerificacao = codProvaFeita;

			const visualizacaoResultadoAutorizadaTO: VisualizacaoResultadoAutorizadaTO = await this.call("AplicacaoProvaFCD/verificarVisualizacaoResultadoAutorizada", codProvaFeita);

			if (!visualizacaoResultadoAutorizadaTO.isProctoringHabilitadoRecursos) {
				if(fiscalizacaoMonitoradaVH?.fiscalizacaoMonitoradaTO?.proctoringId === 'fp') {
					setTimeout(async () => {
						await fiscalizacaoMonitoradaVH.pararMonitoramento();
					}, 5000);
				} else {
					await fiscalizacaoMonitoradaVH.pararMonitoramento();
				}
			}

			await questVH.aplicarQuestionario(visualizacaoResultadoAutorizadaTO.idAplicacaoQuestPosProva);

			if (visualizacaoResultadoAutorizadaTO.isPodeAbrirRecursos) {
				await aberturaRecursosVH.verificarPreCondicoesRecursos(visualizacaoResultadoAutorizadaTO.codProvaFeita, true);

			} else if (visualizacaoResultadoAutorizadaTO.isEncerrarSecaoLinkExterno) {
				await this.exibirAlerta({ msg: visualizacaoResultadoAutorizadaTO.msgEncerrarLinkExterno });
				await loginVH.handleClickBotaoSair(true);
	
			} else if (visualizacaoResultadoAutorizadaTO.isVisualizacaoResultadoAutorizada) {
				await aplicacaoProvaVH.exibirDetalhesProvaRealizada(visualizacaoResultadoAutorizadaTO.codProvaFeita);
	
			} else if (this.posProvaCallback) {
				await this.posProvaCallback();
				this.posProvaCallback = null;
	
			} else {
				UtilHash.carregarTelaInicial();
			}

		} catch (ignored) {
			this.qtdeErrosFinalizacao ++;
			this.logger.warn("Erro ao finalizar PF: " + this.qtdeErrosFinalizacao);

			if(fiscalizacaoMonitoradaVH?.fiscalizacaoMonitoradaTO?.proctoringId === 'fp') {
				if(this.qtdeErrosFinalizacao === 1) {
					setTimeout(async () => {
						if (fiscalizacaoMonitoradaVH?.proctoringService?.isIniciado()) {
							this.logger.warn("Finalizando proctoring FP, pois excedeu o tempo limite após erros");
							await fiscalizacaoMonitoradaVH.pararMonitoramento();
						}
					}, 30000);
				}
				if(this.qtdeErrosFinalizacao > 3) {
					this.logger.warn("Finalizando proctoring FP, pois excedeu a quantidade de erros: " + this.qtdeErrosFinalizacao);
					await fiscalizacaoMonitoradaVH.pararMonitoramento();
				}
			}
		} finally {
			if (!finalizou) {
				$aplicacaoProva.data("fp-is-finalizando", false);
			}
		}

		return finalizou;
	}

	handleProvaAnulada(msg) {

		monitoramentoPFVH.desativarMonitoramentoSaidaDoNavegador();

		this.limpar();
		this.append(msg);
		this.exibir();

		try {
			this.fecharTodasPopups();
		} catch (e) {}

		setTimeout(async () => {
			await this.exibirAlerta({ msg: msg });
			await fiscalizacaoMonitoradaVH.pararMonitoramento();
			UtilHash.carregarTelaInicial();
		}, 300);
	}
	
	setPosProvaCallback(cb: Function) {
		this.posProvaCallback = cb;
	}

	async encerrarProvaPorTempoEsgotado(aplicacaoProvaFeitaTO: AplicacaoProvaFeitaTO): Promise<boolean> {
		
		if (aplicacaoProvaFeitaTO.deveEncerrarProvaPorTempoEsgotado !== true) return false;

		$("#divAplicacaoProva").data("fp-is-finalizando", true);

		const finalizacaoProvaFeitaTO = {
			codProvaFeita: aplicacaoProvaFeitaTO.codProvaFeita,
			encerradaPeloCronometro: true,
		}
				
		await this.call("AplicacaoProvaFCD/finalizarProvaFeita", finalizacaoProvaFeitaTO);

		await fiscalizacaoMonitoradaVH.pararMonitoramento();

		await this.exibirAlerta({
			msg: this.getMsg("MSG_VH_APR_136") + " " + UtilData.tempoEmSegundosParaUserFriendly(aplicacaoProvaFeitaTO.tempoParaResposta) + " " + this.getMsg("MSG_VH_APR_137"),
			botoes: [{ label: this.getMsg("MSG_VH_003") }],
		});

		UtilHash.carregarTelaInicial();

		return true;
	}
	
	isEncerrada($aplicacaoProva: any) {
		return !$aplicacaoProva || $aplicacaoProva.length === 0 || $aplicacaoProva.closest("body").length === 0;
	}

	isEncerrando($aplicacaoProva: any) {
		return $aplicacaoProva?.data("fp-is-finalizando");
	}

	isEncerradaOuEncerrando($aplicacaoProva: any) {
		return this.isEncerrada($aplicacaoProva) || this.isEncerrando($aplicacaoProva);
	}

	async encerrarProvaPeloCronometro(numTentativa: number) {

		const millisEntreTentativas = 5_000;
		let finalizou = false;

		try {
			await this.fecharTodasPopups();
			finalizou = await salvamentoPFVH.verificarSalvamentoEEncerrar(true);

		} catch (e) {
			this.logger.error(`Erro na tentativa ${numTentativa} de encerrar prova por tempo esgotado`, e);
		}

		if (finalizou) {
			this.logger.info("Prova encerrada com sucesso por tempo esgotado.");
			return;
		}

		if (numTentativa >= 10) {
			this.logger.warn(`Tentativa final ${numTentativa} de encerrar a prova por tempo esgotado falhou. Abortando encerramento.`);
			return;
		}

		this.logger.warn(`Tentativa ${numTentativa} de encerrar a prova por tempo esgotado falhou. Aguardando ${millisEntreTentativas}ms para próxima.`);

		await this.sleep(millisEntreTentativas);

		await this.encerrarProvaPeloCronometro(numTentativa + 1);
	}
}

type VisualizacaoResultadoAutorizadaTO = {
	isVisualizacaoResultadoAutorizada: boolean;
	isEncerrarSecaoLinkExterno: boolean;
	msgEncerrarLinkExterno: string;
	codProvaFeita: number;
	idAplicacaoQuestPosProva: number;
	codAgendamento: number;
	isPodeAbrirRecursos: boolean;
	isProctoringHabilitadoRecursos: boolean;
}

const encerramentoPFVH = new EncerramentoPFVH();