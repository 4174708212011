class AplicacaoImpressaVH extends AmaisVH {

	private isExibirExcluidas: boolean;
	private listaOpcaoListaTOCadernos: any;

	constructor() {
		super(AplicacaoImpressaVH.name);
	}

	async listarAplicacoesImpressas(codProva: number, codAgendamento: number = null, isExibirExcluidas: boolean = null) {

		if (isExibirExcluidas != null) {
			this.isExibirExcluidas = isExibirExcluidas;
		}

		const exibicaoAplicacoesImpressasTO = await this.call("AplicacaoImpressaFCD/listar", codProva, this.isExibirExcluidas);

		if (exibicaoAplicacoesImpressasTO.exibirAlertaRanking) {
			cadastroProvaVH.exibirMsgAlertaRanking(exibicaoAplicacoesImpressasTO.isRankingDesatualizado, exibicaoAplicacoesImpressasTO.isRankingEmProcessamento, codProva);
		}

		this.limpar(true);

		if (!exibicaoAplicacoesImpressasTO.isArquivada) {
			if (exibicaoAplicacoesImpressasTO.numQuestoes == 0) {
				this.addTexto(this.getMsg("FP_FRONT_AplicacaoImpressaVH_001"));
			} else if (exibicaoAplicacoesImpressasTO.numQuestoesSemGabarito > 0) {
				this.addTexto(this.getMsg("FP_FRONT_AplicacaoImpressaVH_002"));
			} else {
				this.addTexto(this.getMsg("FP_FRONT_AplicacaoImpressaVH_003"));
				this.addBotao({
					id: "btnCriarAplicacaoImpressa",
					label: `<i class='fa fa-plus-circle'></i> ${this.getMsg("FP_FRONT_AplicacaoImpressaVH_005")}`,
					css: "float: right",
					classe: "ai-botao-nova btn-success btn-sm",
					onClick: () => {
						aplicacaoImpressaVH.criarNovaAI(codProva);
					}
				});
				if (exibicaoAplicacoesImpressasTO.possuiAplicacaoArquivada) {
					this.addCheckbox({
						label: this.getMsg("FP_FRONT_AplicacaoImpressaVH_004"),
						id: "isExibirExcluidas",
						css: "float: right; width: auto; padding-right: 33px; padding-top: 5px;",
						ignorarFormulario: true,
						valor: this.isExibirExcluidas,
						onChange: () => {
							this.isExibirExcluidas = this.getValor("isExibirExcluidas");
							this.listarAplicacoesImpressas(codProva, codAgendamento);
						}
					});
				}
			}
		}

		this.addEspacamentoHorizontal("1px");

		for (let i = 0; i < exibicaoAplicacoesImpressasTO.listaListagemAplicacoesImpressasTO.length; i++) {
			let listagemAplicacoesImpressasTO = exibicaoAplicacoesImpressasTO.listaListagemAplicacoesImpressasTO[i];

			this.exibirAplicacaoImpressa(listagemAplicacoesImpressasTO);
		}

		this.exibir();

		if (codAgendamento) {
			UtilWindow.scrollTo("[aplicacao-impressa='" + codAgendamento + "']", true);
		}
	}

	getDescricaoTipoEmbaralhamento(isSecoesRandomizadas, isQuestoesRandomizadas, isItensRandomizados) {

		let r = [];

		if (isSecoesRandomizadas == true) r.push(this.getMsg("FP_FRONT_AplicacaoImpressaVH_006"));
		if (isQuestoesRandomizadas == true) r.push(this.getMsg("FP_FRONT_AplicacaoImpressaVH_007"));
		if (isItensRandomizados == true) r.push(this.getMsg("FP_FRONT_AplicacaoImpressaVH_008"));

		if (r.length == 3) {
			return r[0] + ", " + r[1].toLowerCase() + " e " + r[2].toLowerCase();
		} else if (r.length == 2) {
			return r[0] + " e " + r[1].toLowerCase();
		} else if (r.length == 1) {
			return r[0];
		} else {
			return this.getMsg("FP_FRONT_AplicacaoImpressaVH_009");
		}
	}

	exibirAplicacaoImpressa(listagemAplicacoesImpressasTO) {

		let titulo = "<strong>" + listagemAplicacoesImpressasTO.nomeTipoFase + "</strong> " + this.getMsg("FP_FRONT_AplicacaoImpressaVH_010", UtilData.toDDMMYYYYHHMM(listagemAplicacoesImpressasTO.dataGeracao), listagemAplicacoesImpressasTO.idAplicacao);
		let possuiSegmento = listagemAplicacoesImpressasTO.nomeSegmento != null;

		if (listagemAplicacoesImpressasTO.isArquivada) {
			titulo += ` <span class="label label-default">${this.getMsg("FP_FRONT_AplicacaoImpressaVH_011")}</span>`;
		} else if (listagemAplicacoesImpressasTO.isFinalizada) {
			titulo += ` <span class="label label-default">${this.getMsg("FP_FRONT_AplicacaoImpressaVH_012")}</span>`;
		} else if (listagemAplicacoesImpressasTO.isEncerrada) {
			titulo += ` <span class="label label-default">${this.getMsg("FP_FRONT_AplicacaoImpressaVH_013")}</span>`;
		}

		this.append(`
			<div class="col-md-12">
				<div aplicacao-impressa="${listagemAplicacoesImpressasTO.idAplicacao}" class="panel panel-default">
					<div class="panel-heading">
						<h3 class="panel-title">${titulo}</h3>
					</div>
					<div class="panel-body">
						<div class='row'>
							<div class='row'>
								<div class='col-md-12'>
		`);

		this.append(`
			<div class="${possuiSegmento ? "col-md-6" : "col-md-12"}">
				<p>
					<strong>${this.getMsg("FP_FRONT_AplicacaoImpressaVH_014")} ${listagemAplicacoesImpressasTO.nomeTipoFase.toLowerCase()}</strong>: 
		`);
		let inicio = UtilData.toDDMMYYYYHHMM(listagemAplicacoesImpressasTO.dataInicioAplicacao);
		let fim = UtilData.toDDMMYYYYHHMM(listagemAplicacoesImpressasTO.dataFimAplicacao);

		if (inicio && fim) {
			if (inicio == fim) {
				this.append(inicio);
			} else {
				this.append(this.getMsg("FP_FRONT_AplicacaoImpressaVH_015", inicio, fim));
			}
		} else if (inicio) {
			this.append(this.getMsg("FP_FRONT_AplicacaoImpressaVH_016", inicio));
		} else if (fim) {
			this.append(this.getMsg("FP_FRONT_AplicacaoImpressaVH_017", fim));
		} else {
			this.append(this.getMsg("FP_FRONT_AplicacaoImpressaVH_018"));
		}

		this.append("</p></div>");

		if (possuiSegmento) {
			this.append("<div class='col-md-6'>");
			this.append(`<p><strong>${this.getMsg("FP_FRONT_AplicacaoImpressaVH_019")}</strong>: ` + listagemAplicacoesImpressasTO.nomeSegmento + "</p>");
			this.append("</div>");
		}

		if (listagemAplicacoesImpressasTO.nomeUsuarioFiscal) {
			this.append("<div class='col-md-6'>");
			this.append(`<p><strong>${this.getMsg("FP_FRONT_AplicacaoImpressaVH_020")}</strong>: ` + listagemAplicacoesImpressasTO.nomeUsuarioFiscal + "</p>");
			this.append("</div>");
		}

		this.append("</div></div>");

		const possuiCorrecoes = listagemAplicacoesImpressasTO.numCorrecoes > 0;

		if (listagemAplicacoesImpressasTO.tipoCaderno == "POR_AVALIADO") {
			this.append("<div class='col-md-6'>");
			this.append("<p><strong>Cadernos</strong></p>");
			this.append(`<p>${this.getMsg("FP_FRONT_AplicacaoImpressaVH_021")}: ${listagemAplicacoesImpressasTO.tipoCadernoDescricao}</p>`);
			const embaralhamento = this.getDescricaoTipoEmbaralhamento(listagemAplicacoesImpressasTO.isCadernoPorAvaliadoComEmbaralhamentoDeSecoes, listagemAplicacoesImpressasTO.isCadernoPorAvaliadoComEmbaralhamentoDeQuestoes, listagemAplicacoesImpressasTO.isCadernoPorAvaliadoComEmbaralhamentoDeItens);
			this.append(`<p>${this.getMsg("FP_FRONT_AplicacaoImpressaVH_022")}: ${embaralhamento}</p>`);
			this.append("</div>");

		} else {
			for (const listagemCadernoTO of listagemAplicacoesImpressasTO.listaListagemCadernoTO) {

				const labelCaderno = listagemCadernoTO.nome ? listagemCadernoTO.nome : this.getMsg("FP_FRONT_AplicacaoImpressaVH_023");
				const embaralhamento = this.getDescricaoTipoEmbaralhamento(listagemCadernoTO.isSecoesRandomizadas, listagemCadernoTO.isQuestoesRandomizadas, listagemCadernoTO.isItensRandomizados);

				this.append(`<div id-caderno='${listagemCadernoTO.idCaderno}' class='col-md-6'>`);
				this.append(`<p><strong>${labelCaderno} <small> #${listagemCadernoTO.codProvaFeitaCaderno}</small></strong></p>`);
				this.append(`<p>${this.getMsg("FP_FRONT_AplicacaoImpressaVH_024")}: ${listagemAplicacoesImpressasTO.tipoCadernoDescricao}</p>`);
				this.append(`<p>Embaralhamento: ${embaralhamento}</p>`);
				this.append(`<p>${this.getCfg("LABEL_MENU_CADASTRO_ALUNO")} utilizando: ${listagemCadernoTO.numAvaliadosComCaderno}</p>`);
				this.append(`<p>Gabarito: `);
				for (let numQuestao in listagemCadernoTO.mapGabarito) {
					let g = listagemCadernoTO.mapGabarito[numQuestao];
					if (this.isEmpty(g)) continue;
					this.append(`<strong>${numQuestao}</strong> ${g} `);
				}
				this.append("</p>");

				if (!listagemAplicacoesImpressasTO.isEncerrada) {
					this.append(`<p>${this.getMsg("FP_FRONT_AplicacaoImpressaVH_025")}: `);
					this.append(this.montarLink(listagemCadernoTO.pathCadernoDocx, "caderno-docx"));
					this.append(this.montarLink(listagemCadernoTO.pathCadernoPdf, "caderno-pdf"));
					if (listagemCadernoTO.pathGabaritoPdf) {
						this.append(this.montarLink(listagemCadernoTO.pathGabaritoPdf, "caderno-xlsx"));
					}
					if (listagemCadernoTO.pathGabaritoXls) {
						this.append(this.montarLink(listagemCadernoTO.pathGabaritoXls, "caderno-xlsx"));
					}
					this.append("</p>");
				}

				this.append("</div>");
			}
		}

		if (listagemAplicacoesImpressasTO.tipoFolhaResposta) {
			this.append("<div frs class='col-md-6'>");
			this.append(`<p><strong>${this.getMsg("FP_FRONT_AplicacaoImpressaVH_026")}</strong></p>`);
			this.append(`<p>${this.getMsg("FP_FRONT_AplicacaoImpressaVH_027")}: ${listagemAplicacoesImpressasTO.tipoFolhaResposta}</p>`);

			if (listagemAplicacoesImpressasTO.listaNomesTurmas.length > 0) {
				const turmas = listagemAplicacoesImpressasTO.listaNomesTurmas.join(", ");
				this.append(`<p>${this.getCfg("LABEL_LISTAGEM_TURMAS")}: ${turmas}</p>`);
			}

			if (listagemAplicacoesImpressasTO.numAvaliados > 0) {
				this.append(`<p>${this.getCfg("LABEL_MENU_CADASTRO_ALUNO")}: `);

				if (this.isAdministrador() && !listagemAplicacoesImpressasTO.isEncerrada && listagemAplicacoesImpressasTO.pathFRsPdf && !listagemAplicacoesImpressasTO.isArquivada) {
					this.append(`<a onclick='aplicacaoImpressaVH.exibirEdicaoFRVersusCadernos(${listagemAplicacoesImpressasTO.idAplicacao},${listagemAplicacoesImpressasTO.codProva})'>${listagemAplicacoesImpressasTO.numAvaliados}</a>`);
				} else {
					this.append(listagemAplicacoesImpressasTO.numAvaliados);
				}

				this.append("</p>");
			}

			if (possuiCorrecoes) {
				this.append(`<p>${this.getMsg("FP_FRONT_AplicacaoImpressaVH_028")}: ${listagemAplicacoesImpressasTO.numCorrecoes}</p>`);
			}

			if (!listagemAplicacoesImpressasTO.isEncerrada) {
				this.append(`<p>${this.getMsg("FP_FRONT_AplicacaoImpressaVH_029")}: `);
				if (listagemAplicacoesImpressasTO.pathFRsPdf) {
					this.append(this.montarLink(listagemAplicacoesImpressasTO.pathFRsPdf, "frs-pdf"));
				} else {
					this.append(this.addBotao({
						label: this.getMsg("FP_FRONT_AplicacaoImpressaVH_030"), 
						onClick: async () => {
							await this.call("AplicacaoImpressaFCD/gerarFRs", listagemAplicacoesImpressasTO.idAplicacao);
							try {
								await this.listarAplicacoesImpressas(listagemAplicacoesImpressasTO.codProva)
							} catch (ignored) {}
						}
					}));
				}
				this.append("</p>");
			}

			if (Cookies.get("fp_clicou_orientacoes_fr")) {
				this.append(`
					<p>
						<a href="https://s3-sa-east-1.amazonaws.com/mxmz1/arqs/Orientac%CC%A7o%CC%83es+para+FR.pdf" target="_blank">
							${this.getMsg("FP_FRONT_AplicacaoImpressaVH_031")}
						</a>
					</p>
				`);
			} else {
				this.append(`
					<p>
						<a class="btn btn-warning" href="https://s3-sa-east-1.amazonaws.com/mxmz1/arqs/Orientac%CC%A7o%CC%83es+para+FR.pdf" onclick="Cookies.set('fp_clicou_orientacoes_fr', true);" target="_blank">
							<b>${this.getMsg("FP_FRONT_AplicacaoImpressaVH_032")}</b>
						</a>
					</p>
				`);
			}

			this.append("</div>");
		}

		if (listagemAplicacoesImpressasTO.listaListagemDocTO?.length) {
			this.append("<div documentos class='col-md-6'>");
			this.append(`<p><strong>${this.getMsg("FP_FRONT_AplicacaoImpressaVH_033")}</strong></p>`);
			this.append("<div class='row' paths>");
			for (const listagemDocTO of listagemAplicacoesImpressasTO.listaListagemDocTO) {
				this.append(this.montarLinkDocumento(listagemDocTO));
			}
			this.append('</div></div>');
		}

		this.append('</div>');
		this.append('</div>');
		this.append('<div class="panel-footer" style="text-align: right">');
		this.append("<div class='btn-group'>");

		if (!this.isFiscal(false)) {
			this.addBotao({
				label: listagemAplicacoesImpressasTO.isArquivada ? this.getMsg("FP_FRONT_AplicacaoImpressaVH_034") : this.getMsg("FP_FRONT_AplicacaoImpressaVH_035"),
				classe: "btn-sm " + (listagemAplicacoesImpressasTO.isArquivada ? "" : "btn-danger"),
				dica: listagemAplicacoesImpressasTO.isArquivada ? "" : this.getMsg("FP_FRONT_AplicacaoImpressaVH_036"),
				onClick: () => {
					this.excluirAplicacaoImpressa(listagemAplicacoesImpressasTO.idAplicacao, listagemAplicacoesImpressasTO.isArquivada);
				}
			});
		}

		if (!this.isFiscal(false) && !listagemAplicacoesImpressasTO.isArquivada) {
			this.addBotao({
				label: `<i class="fa fa-sliders"></i> ${this.getMsg("FP_FRONT_AplicacaoImpressaVH_037")}`,
				classe: "btn-sm",
				html: "ai-botao-configuracoes",
				visivel: !listagemAplicacoesImpressasTO.isFinalizada,
				onClick: async () => {
					await aplicacaoImpressaVH.editarFase(
						listagemAplicacoesImpressasTO.idAplicacao,
						listagemAplicacoesImpressasTO.idFaseAplicacao,
						listagemAplicacoesImpressasTO.idFaseRecursos,
						listagemAplicacoesImpressasTO.idsFaseIntermediaria
					);
				}
			});
		}

		const displayBaixarZip = !listagemAplicacoesImpressasTO.possuiAlgumArquivo || listagemAplicacoesImpressasTO.isEncerrada ? 'style="display:none"' : '';
		this.append(`
			<a botao-zip type="button" class="btn btn-sm btn-default" onclick="aplicacaoImpressaVH.baixarZip(${listagemAplicacoesImpressasTO.idAplicacao}, this)" ${displayBaixarZip}>
				<i class="fa fa-file-archive-o"></i> 
				${this.getMsg("FP_FRONT_AplicacaoImpressaVH_038")}
			</a>
		`);

		if (this.isAdministrador()) {
			this.append(`
				<a botao-auditoria type="button" class="btn btn-sm btn-default" onclick="loginVH.exibirAcessosDoAgendamento(${listagemAplicacoesImpressasTO.idAplicacao})">
					<i class="fa fa-eye"></i> 
					${this.getMsg("FP_FRONT_AplicacaoImpressaVH_039")}
				</a>
			`);
		}

		if (!this.isFiscal(false)) {
			if (listagemAplicacoesImpressasTO.numAvaliados > 0 && !listagemAplicacoesImpressasTO.isArquivada) {
				this.append(`
					<a ai-botao-enviar-email type="button" class="btn btn-sm btn-default" onclick="agendamentoProvaVH.exibirEdicaoEmail(${listagemAplicacoesImpressasTO.idAplicacao}, this)">
						<i class="fa fa-envelope-o"></i> 
						${this.getMsg("FP_FRONT_AplicacaoImpressaVH_040")}
					</a>
				`);
			}

			if (!listagemAplicacoesImpressasTO.isEncerrada && !listagemAplicacoesImpressasTO.isArquivada) {
				this.addBotao({
					label: "<i class='fa fa-stop'></i> " + this.getMsg("FP_FRONT_AplicacaoImpressaVH_041"),
					classe: "btn-sm",
					onClick: async () => {
						const deveArquivarAplicacaoPorEstarSemRespostas = (possuiCorrecoes === false);
						await agendamentoProvaVH.encerrarAgendamento(listagemAplicacoesImpressasTO.idAplicacao, null, deveArquivarAplicacaoPorEstarSemRespostas);
					}
				});
				if (possuiCorrecoes) {
					this.addBotao({
						label: "<i class='fa fa-trash'></i> " + this.getMsg("FP_FRONT_AplicacaoImpressaVH_042"),
						classe: "btn-sm",
						onClick: async () => {
							await aplicacaoImpressaVH.anularCorrecoesAplicacaoImpressa(listagemAplicacoesImpressasTO.idAplicacao);
						}
					});
				}
			}

			if (listagemAplicacoesImpressasTO.isEncerrada && !listagemAplicacoesImpressasTO.isFinalizada && !listagemAplicacoesImpressasTO.isArquivada) {
				this.append(`
					<button class='btn btn-sm btn-default' onclick='agendamentoProvaVH.finalizarAgendamento(${listagemAplicacoesImpressasTO.idAplicacao})'>
					<i class='fa fa-stop'></i> 
					${this.getMsg("FP_FRONT_AplicacaoImpressaVH_043")}
					</button>
				`);
			}

			if (possuiCorrecoes && !listagemAplicacoesImpressasTO.isFinalizada && !listagemAplicacoesImpressasTO.isArquivada) {
				this.append(`
					<button ai-botao-atualizar-pontuacoes type="button" class="btn btn-sm btn-default" onclick="aplicacaoImpressaVH.atualizarGabaritosAplicacaoImpressa(${listagemAplicacoesImpressasTO.idAplicacao}, true)">
						${this.getMsg("FP_FRONT_AplicacaoImpressaVH_044")}
					</button>
				`);
			}

			if (listagemAplicacoesImpressasTO.isEncerrada && !listagemAplicacoesImpressasTO.isArquivada) {
				this.append(`
					<button class='btn btn-sm btn-default' onclick='aplicacaoImpressaVH.reabrirAplicacao(${listagemAplicacoesImpressasTO.codProva},${listagemAplicacoesImpressasTO.idAplicacao})'> 
						<i class='fa fa-undo'></i> 
						${this.getMsg("FP_FRONT_AplicacaoImpressaVH_045")}
					</button>
				`);
			}

			if (possuiCorrecoes && !listagemAplicacoesImpressasTO.isArquivada) {
				this.append(`
					<button class='btn btn-sm btn-default' onclick='cadastroProvaVH.exibirAbasProva(5, ${listagemAplicacoesImpressasTO.codProva}, ${listagemAplicacoesImpressasTO.idAplicacao})'>
						${this.getMsg("FP_FRONT_AplicacaoImpressaVH_046")}
					</button>
				`);
			}
		}

		if (!listagemAplicacoesImpressasTO.isEncerrada && !listagemAplicacoesImpressasTO.isArquivada) {
			if (this.isCfgHabilitada("HABILITAR_IMPORTACAO_CARTAO_RESPOSTA") && listagemAplicacoesImpressasTO.listaListagemCadernoTO.length == 1) {
				this.append(`
					<a class="btn btn-sm btn-default" onclick="importacaoCartaoRespostaVH.iniciarImportacao(${listagemAplicacoesImpressasTO.idAplicacao})">
						${this.getMsg("FP_FRONT_AplicacaoImpressaVH_047")}
					</a>
				`);
			}
		}

		if (listagemAplicacoesImpressasTO.dataInicializacao && !listagemAplicacoesImpressasTO.isArquivada) {
			this.append(`
				<button class='btn btn-sm btn-default btn-primary' 
						onclick='importacaoCartaoRespostaVH.exibirSituacaoEnvioAgendamento(${listagemAplicacoesImpressasTO.idAplicacao})' 
						title='${this.getMsg("FP_FRONT_AplicacaoImpressaVH_048")}'>
					<i class='fa fa-upload'></i> 
					Situação do envio
				</button>
			`);
		}

		this.append(`
						</div>
					</div>
				</div>
			</div>
		`);
	}

	async reabrirAplicacao(codProva, idAplicacao) {
		await this.call("AgendamentoProvaFCD/reabrirAplicacao", idAplicacao);
		try {
			await this.listarAplicacoesImpressas(codProva);
		} catch (ignored) {}
	}

	anularCorrecoesAplicacaoImpressa(idAplicacao) {
		this.exibirAlerta({
			msg: `<p>${this.getMsg("FP_FRONT_AplicacaoImpressaVH_049")}</p>`,
			botoes: [{
				label: this.getMsg("FP_FRONT_AplicacaoImpressaVH_050"), 
				classe: "btn-danger", 
				onClick: async () => {
					const codProva = await this.call("AplicacaoImpressaFCD/anularCorrecoesAplicacaoImpressa", idAplicacao);
					try {
						await this.listarAplicacoesImpressas(codProva);
					} catch (ignored) {}
				}
			}]
		})
	}

	async baixarZip(idAplicacao, botao) {
		
		// $(botao).text(this.getMsg("FP_FRONT_AplicacaoImpressaVH_051")).attr("disabled", "disabled").off("click").attr("onclick", "");

		const path = await this.call("AplicacaoImpressaFCD/baixarZip", idAplicacao);

		$(botao)
			.removeAttr("onclick").off("click")
			.attr("href", path).attr("target", "_blank")
			.text(UtilArquivo.getNomeArquivo(path))
			.removeAttr("disabled")
			.addClass("fp-link-aguardar-geracao-arquivo");

		this.ativarLinksAguardandoGeracao();
	}

	atualizarGabaritosAplicacaoImpressa(idAplicacao, isImpressa) {

		const alertaGabarito = isImpressa ? this.getMsg("FP_FRONT_AplicacaoImpressaVH_112") : "";

		this.exibirAlerta({
			msg: `
				<p>
					${this.getMsg("FP_FRONT_AplicacaoImpressaVH_108")}
				</p>
				<p>
					${this.getMsg("FP_FRONT_AplicacaoImpressaVH_109")}
				</p>
				<p>
					${this.getMsg("FP_FRONT_AplicacaoImpressaVH_110")}
				</p>
				<p>
					${this.getMsg("FP_FRONT_AplicacaoImpressaVH_111")}
				</p>
				<p>
					${alertaGabarito}
				</p>
			`,
			botoes: [{
				label: this.getMsg("FP_FRONT_AplicacaoImpressaVH_053"),
				classe: "btn-primary",
				onClick: async () => {
					await this.call("AplicacaoImpressaFCD/atualizarGabaritosAplicacaoImpressa", idAplicacao);
					document.location.reload();
				}
			}]
		})
	}

	montarLink(path, classe) {

		if (!path) return "";

		if (path.startsWith("ERRO:")) {
			return "<i class='fa fa-exclamation-triangle'></i> " + path.substr(5);
		}

		let nome = UtilArquivo.getNomeArquivoSemHifens(path);
		let download = (nome.endsWith("pdf") ? " download='" + nome + "' " : "");

		return this.addLink({
			retornarHtml: true,
			classe: `fp-link-aguardar-geracao-arquivo " + ${classe || ""}`,
			css: "font-size: 13px; padding-top: 3px;",
			href: path,
			html: `target='_blank' ${download}`,
			label: nome
		})
	}

	montarLinkDocumento(listagemDocTO) {

		if (!listagemDocTO || !listagemDocTO.path) return "";

		let h = ["<div class='col-md-12'><div class='row'><div class='col-md-6'>"];

		h.push(this.montarLink(listagemDocTO.path, "documento-docx"));
		h.push("</div>");
		h.push("<div class='col-md-6'>");

		if (listagemDocTO.listaListagemDocPaginaTO.length > 0) {

			h.push(`${this.getMsg("FP_FRONT_AplicacaoImpressaVH_054")}: <div class='btn-group' style='margin-left: 10px'>`);

			for (const listagemDocPaginaTO of listagemDocTO.listaListagemDocPaginaTO) {

				h.push(" <a class='btn btn-default btn-sm' ");

				if (listagemDocPaginaTO.pathPaginaPreenchida) {
					h.push("target='_blank' href='" + listagemDocPaginaTO.pathPaginaPreenchida + "' ")
				} else {
					h.push(` disabled='disabled' title='${this.getMsg("FP_FRONT_AplicacaoImpressaVH_055")}' style='background-color: lightgray' `);
				}

				h.push(">" + listagemDocPaginaTO.numPagina + "</a>");
			}

			h.push("</div>");
		}

		h.push("</div></div></div>");

		return h.join("");
	}

	excluirAplicacaoImpressa(idAplicacao, isArquivada) {

		const prosseguir = async () => {
			const codProva = await this.call("AplicacaoImpressaFCD/excluir", idAplicacao);
			try {
				await this.listarAplicacoesImpressas(codProva);
			} catch (ignored) {}
		};

		if (isArquivada) {
			prosseguir();
		} else {
			this.exibirAlerta({
				msg: this.getMsg("FP_FRONT_AplicacaoImpressaVH_056"),
				botoes: [{ label: this.getMsg("FP_FRONT_AplicacaoImpressaVH_057"), classe: "btn-danger", onClick: prosseguir }]
			});
		}
	}

	async criarNovaAI(codProva) {

		const dadosParaCriacaoAplicacaoImpressaTO = await this.call("AplicacaoImpressaFCD/recuperarDadosParaCriacao", codProva);

		const cfgsTemplate = await this.recuperarTemplate(dadosParaCriacaoAplicacaoImpressaTO.pathDoTemplateNoArmazenamento);

		this.limpar(true);

		this.addSubtitulo(this.getMsg("FP_FRONT_AplicacaoImpressaVH_058"));

		this.addFormulario();

		this.append(`
			<div class="row" id="edicao-nova-ai">
				<div class="col-md-4">
		`);

		if (dadosParaCriacaoAplicacaoImpressaTO.permiteReaplicacao) {

			const verificarPendenciasReaplicacao = async () => {
				const tipoFase = this.getValor("tipoFase");
				const numReaplicacao = this.getValor("NUM_REAPLICACAO");

				if (tipoFase !== "REAPLICACAO") return true;

				return await cadastroAOVH.verificarSeHaPendenciasParaNovaFase(codProva, tipoFase, numReaplicacao, true);
			}

			this.addRadioGroup({
				collection: dadosParaCriacaoAplicacaoImpressaTO.collectionOpcaoListaTOTipoAplicacaoFase.map(opcaoListaTOTipoAplicacaoFase => {
					return {
						id: opcaoListaTOTipoAplicacaoFase.id,
						descricao: `
							<span>${opcaoListaTOTipoAplicacaoFase.nome}</span>
							<br>
							<small>${opcaoListaTOTipoAplicacaoFase.descricao}</small>
						`,
						dica: false
					}
				}),
				label: "Tipo da fase",
				id: "tipoFase",
				classe: "col-md-12",
				visivel: false, // fp-custom
				onChange: async () => {
					if (await verificarPendenciasReaplicacao()) {
						await this.aplicarRegrasTipoCaderno()
					}
				}
			});
			
			await this.addSelect({
				label: "Número da reaplicação",
				collection: dadosParaCriacaoAplicacaoImpressaTO.listaNumsReaplicacao,
				id: "NUM_REAPLICACAO",
				classe: "col-md-12",
				obrigatorio: false,
				visivel: false,
				onChange: async () => {
					await verificarPendenciasReaplicacao();
				}
			});
		}

		if (this.getIsAmbienteSegmentado()) {
			await this.addSelect({
				id: "codEmpresa",
				label: this.getMsg("FP_FRONT_AplicacaoImpressaVH_059"),
				classe: "col-md-12",
				obrigatorio: true,
				collection: dadosParaCriacaoAplicacaoImpressaTO.collectionSegmentosTO,
				valor: dadosParaCriacaoAplicacaoImpressaTO.codEmpresa,
				onChange: async () => {
					
					this.setValor("idsTurmas", null);
					this.updateSelect({ id: "idsTurmas", collection: [] });
					
					const visivelNoMomento = $("#idsTurmas").closest(".form-group").is(":visible");
					
					if (visivelNoMomento) {
						await this.show("idsTurmas");
					} else {
						await this.hide("idsTurmas");
					}
				}
			});
		}

		if (this.hasValue(dadosParaCriacaoAplicacaoImpressaTO.listaOpcaoListaTOFiscais)) {
			await this.addSelect({
				label: this.getMsg("FP_FRONT_AplicacaoImpressaVH_060"),
				collection: dadosParaCriacaoAplicacaoImpressaTO.listaOpcaoListaTOFiscais,
				id: "codUsuarioFiscal",
				classe: "col-md-12 fp-small-business-hidden"
			});
		}

		this.addCampoTexto({
			label: this.getMsg("FP_FRONT_AplicacaoImpressaVH_061"),
			id: "dataAplicacao",
			tipo: "DATAHORA",
			classe: "col-md-12",
			cssInput: "width: 40%",
			obrigatorio: cfgsTemplate.isDataAplicacaoObrigatoria
		});

		if (cfgsTemplate.isExibirDuracao) {
			this.addCampoTexto({
				label: this.getMsg("FP_FRONT_AplicacaoImpressaVH_062"),
				id: "tempoParaResposta",
				tipo: "NUMERO",
				sufixo: this.getMsg("FP_FRONT_AplicacaoImpressaVH_063"),
				classe: "col-md-12",
				cssInput: "width: 40%",
				obrigatorio: true
			});
		}

		this.append("</div>");

		this.append("<div class='col-md-4'>");

		await this.addSelect({
			id: "tipoCaderno",
			label: this.getMsg("FP_FRONT_AplicacaoImpressaVH_064"),
			classe: "col-md-12",
			obrigatorio: true,
			collection: [{ 
				id: "UNICO", 
				dica: false, 
				nome: this.getMsg("FP_FRONT_AplicacaoImpressaVH_065"), 
				descricao: this.getMsg("FP_FRONT_AplicacaoImpressaVH_069") 
			}, { 
				id: "VARIOS", 
				dica: false, 
				nome: this.getMsg("FP_FRONT_AplicacaoImpressaVH_066"), 
				descricao: this.getMsg("FP_FRONT_AplicacaoImpressaVH_070") 
			}, { 
				id: "REUSO", 
				dica: false, 
				nome: this.getMsg("FP_FRONT_AplicacaoImpressaVH_067"), 
				descricao: this.getMsg("FP_FRONT_AplicacaoImpressaVH_071"), 
				disabled: this.isEmpty(dadosParaCriacaoAplicacaoImpressaTO.listCadernosExistentes)
			}, { 
				id: "POR_AVALIADO", 
				dica: false, 
				nome: this.getMsg("FP_FRONT_AplicacaoImpressaVH_068"), 
				descricao: this.getMsg("FP_FRONT_AplicacaoImpressaVH_072") 
			}],
			onChange: () => this.aplicarRegrasTipoCaderno()
		});

		this.addCampoTexto({
			id: "numCadernos",
			label: this.getMsg("FP_FRONT_AplicacaoImpressaVH_073"),
			valor: 2,
			tipo: "NUMERO",
			maxLength: "2",
			classe: "col-md-12",
			css: "display: none",
			onChange: () => {
				let numCadernos = this.getValor("numCadernos");
				let $cads = $("[nomes-cadernos]");

				$cads.find("[num-caderno]").each(function () {
					let $t = $(this);
					if ($t.attr("num-caderno") > numCadernos) {
						$t.hide();
					}
				})

				if (numCadernos == 0) return;

				let i = 1;

				while (i <= numCadernos) {
					let $c = $cads.find("[num-caderno='" + i + "']");

					if (!$c.length) {
						$cads.append("<br><input class='form-control' type='text' value='Caderno " + i + "' num-caderno='" + i + "'><br>");
					} else {
						$c.show();
					}

					i++;
				}
			}
		});

		this.append("<div nomes-cadernos class='col-md-12 form-group' style='display: none'>");
		this.append(`<label class="control-label">${this.getMsg("FP_FRONT_AplicacaoImpressaVH_074")}:</label>`);
		this.append("</div>");

		this.addCheckbox({ id: "isSecoesRandomizadas", classe: "col-md-12", label: this.getMsg("FP_FRONT_AplicacaoImpressaVH_075"), valor: false, visivel: false });
		this.addCheckbox({ 
			id: "isQuestoesRandomizadas", 
			classe: "col-md-12", 
			label: this.getMsg("FP_FRONT_AplicacaoImpressaVH_076") + (dadosParaCriacaoAplicacaoImpressaTO.possuiSecaoComSorteio ? ` <small>${this.getMsg("FP_FRONT_CadastroAOVH_189")}</small>` : ""), 
			valor: dadosParaCriacaoAplicacaoImpressaTO.possuiSecaoComSorteio, 
			habilitado: !dadosParaCriacaoAplicacaoImpressaTO.possuiSecaoComSorteio, 
			visivel: false 
		});
		this.addCheckbox({ id: "isItensRandomizados", classe: "col-md-12", label: this.getMsg("FP_FRONT_AplicacaoImpressaVH_077"), valor: false, visivel: false });

		await this.addSelect({
			collection: dadosParaCriacaoAplicacaoImpressaTO.listCadernosExistentes,
			id: "idsCadernosExistentes",
			label: this.getMsg("FP_FRONT_AplicacaoImpressaVH_078"),
			classe: "col-md-12",
			css: "display: none",
			multiplo: true,
		});

		this.append("</div>");
		this.append("<div class='col-md-4'>");

		await this.addSelect({
			id: "tipoFR",
			label: this.getMsg("FP_FRONT_AplicacaoImpressaVH_079"),
			classe: "col-md-12",
			obrigatorio: true,
			collection: dadosParaCriacaoAplicacaoImpressaTO.listaOpcaoListaTOTiposFR,
			onChange: async () => {

				if (this.getValor("tipoFR") == "IDENTIFICADA") {

					if (this.getValor("tipoCaderno") == "POR_AVALIADO") {
						await this.hide("isIncluirNaoNominalEmNominal");
						await this.show("idsTurmas");

					} else {
						await this.show("idsTurmas", "isIncluirNaoNominalEmNominal");
					}

				} else {
					await this.hide("idsTurmas", "isIncluirNaoNominalEmNominal");
				}

				if (this.getValor("tipoFR") == "COLETA_ID") {
					await this.show("identificador");
					$("#identificador").trigger("change");

				} else {
					await this.hide("identificador", "identificadorNumDigitos", "identificadorNomeCampo");
				}

				if (this.getValor("tipoFR") == "PLANILHA") {
					await this.show("idsTurmas");
					await this.hide("tamanhoPagina");
				} else {
					await this.show("tamanhoPagina");
				}

				await this.atualizarOpcaoSeparacaoAIsTurmas();
			}
		});

		await this.addSelect({
			id: "idsTurmas",
			classe: "col-md-12",
			obrigatorio: true,
			multiplo: true,
			css: "display: none",
			label: this.getCfg("LABEL_LISTAGEM_TURMAS"),
			dicaComoValorInicial: `(${this.getMsg("FP_FRONT_AplicacaoImpressaVH_080")})`,
			onChange: async () => {
				await this.atualizarOpcaoSeparacaoAIsTurmas();
			},
			loadOpcoesBack: {
				endpoint: "ListagemSelecaoFCD/listarTurmasPorSegmentosEAvaliacoes",
				payload: {
					codEmpresa: () => this.getValor("codEmpresa") || dadosParaCriacaoAplicacaoImpressaTO.codEmpresa,
					codsAvaliacoesFolhas: dadosParaCriacaoAplicacaoImpressaTO.codsAvaliacoesDaProva,
					codsTurmasSelecionadas: null,
				},
				numMinimoCaracteres: 0
			},
		});

		this.addCheckbox({
			id: "isIncluirMarcacaoDaOpcaoDeCaderno",
			classe: "col-md-12",
			label: this.getMsg("FP_FRONT_AplicacaoImpressaVH_081"),
			valor: false,
			visivel: false
		});

		this.addCheckbox({
			id: "isIncluirNaoNominalEmNominal",
			classe: "col-md-12",
			label: this.getMsg("FP_FRONT_AplicacaoImpressaVH_082"),
			css: "display: none",
			valor: false,
			onChange: async () => {
				let isIncluirNaoNominalEmNominal = this.getValor("isIncluirNaoNominalEmNominal");
				let identificador = this.getValor("identificador");
				if (isIncluirNaoNominalEmNominal) {
					await this.show("identificador");
					$("#identificador").trigger("change");
				} else if (identificador == "MATRICULA") {
					await this.hide("identificador", "identificadorNumDigitos", "identificadorNomeCampo");
				}
			}
		});

		await this.addSelect({
			collection: dadosParaCriacaoAplicacaoImpressaTO.listOpcaoListaTOIdsFRNaoNominal,
			id: "identificador",
			label: this.getMsg("FP_FRONT_AplicacaoImpressaVH_083"),
			classe: "col-md-12",
			css: "display: none",
			onChange: async () => {
				let identificador = this.getValor("identificador");

				if (identificador == "CPF") {
					await this.hide("identificadorNumDigitos", "identificadorNomeCampo");
				} else if (identificador == "MATRICULA") {
					await this.hide("identificadorNomeCampo")
					await this.show("identificadorNumDigitos")
				} else if (identificador == "OUTRO") {
					await this.show("identificadorNumDigitos", "identificadorNomeCampo");
				}
			}
		});
		this.addCampoTexto({ 
			label: this.getMsg("FP_FRONT_AplicacaoImpressaVH_084"), 
			id: "identificadorNomeCampo", 
			css: "display: none", 
			classe: "col-md-12" 
		});
		this.addCampoTexto({ 
			label: this.getMsg("FP_FRONT_AplicacaoImpressaVH_085"), 
			id: "identificadorNumDigitos", 
			css: "display: none", 
			tipo: "NUMERO", 
			classe: "col-md-12", 
			mascara: "9?9" 
		});

		if (this.hasValue(dadosParaCriacaoAplicacaoImpressaTO.listaOpcaoListaTOLayoutPaginas)) {
			await this.addSelect({
				collection: dadosParaCriacaoAplicacaoImpressaTO.listaOpcaoListaTOLayoutPaginas,
				id: "tamanhoPagina",
				label: this.getMsg("FP_FRONT_AplicacaoImpressaVH_086"),
				classe: "col-md-12",
				obrigatorio: true
			});
		}

		this.addCheckbox({
			id: "isSepararAIsTurmas",
			classe: "col-md-12",
			label: this.getMsg("FP_FRONT_AplicacaoImpressaVH_087") + this.getCfg("LABEL_LISTAGEM_TURMAS").toLowerCase() + "?",
			css: "display: none",
			valor: false
		});

		this.append("</div>");

		this.append("</div>");

		this.addEspacamentoHorizontal("20px");

		this.append('<div class="form-group col-md-12">');

		this.addBotao({
			label: this.getMsg("FP_FRONT_AplicacaoImpressaVH_088"), 
			classe: "btn-success pull-right", 
			onClick: async (botao) => {

				if (!this.validarCamposObrigatorios("tipoCaderno", "tipoFR")) return;

				this.verificarObrigatorios(botao);

				let geracaoProvaImpressaTO = {
					codProva: codProva,
					codEmpresa: this.getValor("codEmpresa"),
					tipoCaderno: this.getValor("tipoCaderno"),
					tipoFR: this.getValor("tipoFR"),
					tipoFase: this.getValor("tipoFase"),
					numReaplicacao: this.getValor("NUM_REAPLICACAO"),
					idsTurmas: this.getValor("idsTurmas"),
					dataAplicacao: this.getValor("dataAplicacao"),
					tamanhoPagina: this.getValor("tamanhoPagina"),
					isSecoesRandomizadas: this.getValor("isSecoesRandomizadas"),
					isQuestoesRandomizadas: this.getValor("isQuestoesRandomizadas"),
					isItensRandomizados: this.getValor("isItensRandomizados"),
					identificador: this.getValor("identificador"),
					identificadorNomeCampo: this.getValor("identificadorNomeCampo"),
					identificadorNumDigitos: this.getValor("identificadorNumDigitos"),
					idsCadernosExistentes: this.getValor("idsCadernosExistentes"),
					isIncluirMarcacaoDaOpcaoDeCaderno: this.getValor("isIncluirMarcacaoDaOpcaoDeCaderno"),
					tempoParaResposta: this.getValor("tempoParaResposta"),
					codUsuarioFiscal: this.getValor("codUsuarioFiscal"),
					nomesCadernos: []
				}

				if (geracaoProvaImpressaTO.tipoFase) {
					const isOK = await cadastroAOVH.verificarSeHaPendenciasParaNovaFase(codProva, geracaoProvaImpressaTO.tipoFase, null, true);

					if (isOK === false) {
						return;
					}
				}

				if (geracaoProvaImpressaTO.identificadorNumDigitos > 12) {
					this.exibirAlerta({ msg: "O limite de dígitos para o identificador da folha de resposta não nominal são 12 dígitos." });
					return;
				}

				if (geracaoProvaImpressaTO.tempoParaResposta != null) {
					geracaoProvaImpressaTO.tempoParaResposta = geracaoProvaImpressaTO.tempoParaResposta * 60;
				}

				$("[num-caderno]:visible").each(function () {
					let v = $(this).val();
					if (v == null || v.trim() == "") return;
					geracaoProvaImpressaTO.nomesCadernos.push(v);
				});

				if (geracaoProvaImpressaTO.tipoCaderno == "VARIOS") {
					if (!this.validarCamposObrigatorios("numCadernos", "[num-caderno]:visible")) return;

					if (!geracaoProvaImpressaTO.isSecoesRandomizadas && !geracaoProvaImpressaTO.isQuestoesRandomizadas && !geracaoProvaImpressaTO.isItensRandomizados) {
						this.exibirAlerta({ msg: this.getMsg("FP_FRONT_AplicacaoImpressaVH_089") });
						return;
					}
				}

				if (geracaoProvaImpressaTO.tipoCaderno == "POR_AVALIADO") {
					if (!geracaoProvaImpressaTO.isSecoesRandomizadas && !geracaoProvaImpressaTO.isQuestoesRandomizadas && !geracaoProvaImpressaTO.isItensRandomizados) {
						this.exibirAlerta({ msg: this.getMsg("FP_FRONT_AplicacaoImpressaVH_090") });
						return;
					}
				}

				if (geracaoProvaImpressaTO.tipoCaderno == "REUSO") {
					if (!this.validarCamposObrigatorios("idsCadernosExistentes")) return;
				}

				if (geracaoProvaImpressaTO.tipoFR == "IDENTIFICADA" || geracaoProvaImpressaTO.tipoCaderno == "POR_AVALIADO") {
					if (!this.validarCamposObrigatorios("idsTurmas")) return;
				}

				if (this.getValor("tipoFR") == "COLETA_ID") {
					if (!this.validarCamposObrigatorios("identificador")) return;
					if (geracaoProvaImpressaTO.identificador == "MATRICULA") {
						if (!this.validarCamposObrigatorios("identificadorNumDigitos")) return;
					} else if (geracaoProvaImpressaTO.identificador == "OUTRO") {
						if (!this.validarCamposObrigatorios("identificadorNomeCampo", "identificadorNumDigitos")) return;
					}
				}

				let isSepararAIsTurmas = this.getValor("isSepararAIsTurmas");

				if (isSepararAIsTurmas) {
					await this.call("AplicacaoImpressaFCD/gerarUmaAIPorTurma", geracaoProvaImpressaTO);
					try {
						await this.listarAplicacoesImpressas(codProva);
					} catch (ignored) {}

				} else {
					await this.call("AplicacaoImpressaFCD/gerar", geracaoProvaImpressaTO);
					try {
						await this.listarAplicacoesImpressas(codProva);
					} catch (ignored) {}
				}
			}
		});

		this.append("</div>");
		this.append("</div>");
		this.append("</div>");

		this.exibir();
	}

	async recuperarTemplate(url: string) {
		const valor = await UtilArmazenamento.carregarMetadata(url, "fp-template-var");

		const cfgsTemplate = {
			isExibirDuracao: false,
			isDataAplicacaoObrigatoria: false
		}

		if (valor) {
			const vars = valor.toString().split(",").map(v => v.trim());
			cfgsTemplate.isExibirDuracao = vars.includes("APLICACAO_DURACAO_RESPOSTA");
			cfgsTemplate.isDataAplicacaoObrigatoria = vars.includes("APLICACAO_DATA") || vars.includes("APLICACAO_DATA_MES_ANO") || vars.includes("APLICACAO_DATA_POR_EXTENSO");
		}

		return cfgsTemplate;
	}

	async atualizarOpcaoSeparacaoAIsTurmas() {
		let idsTurmas = this.getValor("idsTurmas");
		let tipoCaderno = this.getValor("tipoCaderno");
		let tipoFR = this.getValor("tipoFR");

		if ((tipoCaderno == "REUSO" || tipoFR == "PLANILHA") && idsTurmas && idsTurmas.length > 1) {
			await this.show("isSepararAIsTurmas");
		} else {
			await this.hide("isSepararAIsTurmas");
		}
	}

	async exibirEdicaoFRVersusCadernos(idAplicacao, codProva) {
		
		const listagemEdicaoFRVersusCadernoTO = await this.call("AplicacaoImpressaFCD/recuperarEdicaoFRVersusCaderno", idAplicacao);

		this.listaOpcaoListaTOCadernos = listagemEdicaoFRVersusCadernoTO.listaOpcaoListaTOCadernos;
		this.addPopup({
			id: "popup_frs_x_cadernos",
			titulo: this.getMsg("FP_FRONT_AplicacaoImpressaVH_091"),
			width: "900px",
			botoes: [{ label: this.getMsg("FP_FRONT_AplicacaoImpressaVH_092") }, {
				label: this.getMsg("FP_FRONT_AplicacaoImpressaVH_093"), 
				classe: "btn-primary", 
				onClick: async () => {

					let salvamentoTrocaCadernosTO = {
						idAplicacao: idAplicacao,
						listaTrocaCadernoTO: []
					}

					$("#popup_frs_x_cadernos [id^='codUsuarioFR']").each(function () {
						let id = $(this).attr("id");
						let codUsuario = id.substr(12);
						let idCaderno = aplicacaoImpressaVH.getValor(id);
						salvamentoTrocaCadernosTO.listaTrocaCadernoTO.push({
							codUsuario: codUsuario,
							idCaderno: idCaderno
						});
					});

					await this.call("AplicacaoImpressaFCD/trocarCadernos", salvamentoTrocaCadernosTO);
					try {
						await this.listarAplicacoesImpressas(codProva);
					} catch (ignored) {}
				}
			}]
		});

		this.addTexto(this.getMsg("FP_FRONT_AplicacaoImpressaVH_094"));

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: this.getMsg("FP_FRONT_AplicacaoImpressaVH_097"), prop: "nomeRespondente"});
		colunas.push({titulo: this.getMsg("FP_FRONT_AplicacaoImpressaVH_096"), prop: this.montarCaderno, classe: "descricao"});
		colunas.push({titulo: this.getMsg("FP_FRONT_AplicacaoImpressaVH_095"), prop: "nota", formato: "numero"});

		await this.addTabela({
			collection: listagemEdicaoFRVersusCadernoTO.listaEdicaoFRVersusCadernoTO,
			colunas: colunas,
		})

		this.exibirPopups();
	}

	async montarCaderno(edicaoFRVersusCadernoTO) {
		return await this.addSelect({
			id: "codUsuarioFR" + edicaoFRVersusCadernoTO.codUsuario,
			collection: UtilJson.clonar(this.listaOpcaoListaTOCadernos),
			classe: "col-md-12",
			valor: edicaoFRVersusCadernoTO.idCaderno,
			retornarHtml: true,
			ignorarTagForm: true
		});
	}

	async editarFase(idAplicacao, idFaseAplicacao, idFaseRecursos, idsFaseIntermediaria) {

		let numFase;
		let fasesRemovidas = [];
		let edicaoFaseTORecursos = await this.call("AgendamentoProvaFCD/editarFase", idAplicacao, idFaseRecursos, TipoAplicacaoFase.RECURSOS);

		this.addPopup({
			titulo: this.getMsg("FP_FRONT_AplicacaoImpressaVH_098"),
			id: "popup_edicao_fase_impressa",
			width: "90%",
			botoes: [{
				label: this.getMsg("FP_FRONT_AplicacaoImpressaVH_100"),
				classe: "btn-primary",
				onClick: async () => {
					const listaSalvamentoFaseTO: any[] = [];
					const isComRecursos = this.getValor("isComRecursos") == "OPCAO_RECURSOS_PERIODO";

					if (isComRecursos) {

						if (!this.validarCamposObrigatorios("fase_recursos_dataInicio", "fase_recursos_dataFim")) return false;

						const salvamentoFaseTORecursos = {
							idAplicacao: edicaoFaseTORecursos.idAplicacao,
							idFase: edicaoFaseTORecursos.idFase,
							tipoAplicacaoFase: edicaoFaseTORecursos.tipoFase,
							dataInicio: this.getValor("fase_recursos_dataInicio"),
							dataFim: this.getValor("fase_recursos_dataFim"),
							listaSalvamentoCfgFaseTO: []
						}

						if (salvamentoFaseTORecursos.dataFim && salvamentoFaseTORecursos.dataInicio >= salvamentoFaseTORecursos.dataFim) {
							await this.exibirAlerta({
								msg: this.getMsg("FP_FRONT_AplicacaoImpressaVH_101"),
								botoes: [{ label: this.getMsg("MSG_VH_003") }],
							});
							this.focar("fase_recursos_dataInicio");
							return false;
						}

						for (let i = 0; i < edicaoFaseTORecursos.listaEdicaoCfgFaseTO.length; i++) {
							let edicaoCfgFaseTO = edicaoFaseTORecursos.listaEdicaoCfgFaseTO[i];
							const id = edicaoFaseTORecursos.tipoFase + "_" + edicaoCfgFaseTO.tipoCfgFase;

							if ((edicaoCfgFaseTO.isBooleano && this.getValor(id) == true)
								|| (edicaoCfgFaseTO.isInteiro && this.hasValue(this.getValor(id)))) {
								let salvamentoCfgFaseTO = {
									idCfgFase: edicaoCfgFaseTO.idCfgFase,
									tipoCfgFase: edicaoCfgFaseTO.tipoCfgFase,
									valor: this.getValor(id)
								}
								salvamentoFaseTORecursos.listaSalvamentoCfgFaseTO.push(salvamentoCfgFaseTO);
							}
						}

						listaSalvamentoFaseTO.push(salvamentoFaseTORecursos);

					} else if (edicaoFaseTORecursos.idFase) {
						const salvamentoFaseTORecursos = {
							idFase: edicaoFaseTORecursos.idFase,
							isRemovido: true
						}

						listaSalvamentoFaseTO.push(salvamentoFaseTORecursos);
					}

					const fases = $("#container-fases-visualizacao [tipo-fase]").map((i, fase) => fase);

					for (let i = 0; i < fases.length; i++) {

						const $fase = $(fases[i]);
						const numFase = $fase.attr("num-fase");
						const edicaoFaseTO = $fase.data("edicaoFaseTO");

						if (!this.validarCamposObrigatorios(numFase + "fase_dataInicio", numFase + "fase_dataFim")) return false;

						let salvamentoFaseTO = cadastroAOVH.criarObjetoSalvamentoFase($fase, numFase, edicaoFaseTO, idAplicacao);

						if (salvamentoFaseTO.dataFim && salvamentoFaseTO.dataInicio.getTime() >= salvamentoFaseTO.dataFim.getTime()) {
							await this.exibirAlerta({
								msg: this.getMsg("FP_FRONT_CadastroAOVH_123"),
								botoes: [{ label: this.getMsg("MSG_VH_003") }],
							});
							this.focar(numFase + "fase_dataInicio");
							return;
						}

						listaSalvamentoFaseTO.push(salvamentoFaseTO);
					}

					if(!this.isEmpty(fasesRemovidas)){
						for(let idFase of fasesRemovidas){
							const salvamentoFaseTORecursos = {
								idFase:idFase, isRemovido: true
							}
							listaSalvamentoFaseTO.push(salvamentoFaseTORecursos);
						}
					}

					await this.call("AgendamentoProvaFCD/salvarFase", listaSalvamentoFaseTO);
					this.listarAplicacoesImpressas(edicaoFaseTORecursos.codProva);
				}
			}]
		});

		this.addAbas({
			abas: [{
				id: "aba-container-recursos",
				label: this.getCfg("LABEL_RECURSOS"),
				onClick: async () => {
					console.log(this.getMsg("FP_FRONT_AplicacaoImpressaVH_104"));
					await this.hide("container-visualizacao");
					await this.show("container-recursos");
				}
			}, {
				id: "aba-container-visualizacao",
				label: this.getMsg("FP_FRONT_AplicacaoImpressaVH_105"),
				onClick: async () => {
					console.log(this.getMsg("FP_FRONT_AplicacaoImpressaVH_105"));
					await this.show("container-visualizacao");
					await this.hide("container-recursos");
				}
			}], 
			numAbaAtiva: 0, 
			id: 'abas_fase_aplicacao_impressa', 
			idAlvo: "popup_cabecalhopopup_edicao_fase_impressa", 
			noHref: true
		});

		this.append("<div id='container-recursos'>");
		this.addFormulario();
		
		this.addRadioGroup({
			id: "isComRecursos",
			label: this.getMsg("FP_FRONT_CadastroAOVH_111"),
			collection: [
				{ id: "OPCAO_RECURSOS_NAO", descricao: this.getMsg("FP_FRONT_CadastroAOVH_112") },
				{ id: "OPCAO_RECURSOS_PERIODO", descricao: this.getMsg("FP_FRONT_CadastroAOVH_113") }
			],
			classe: "col-md-4 col-md-12",
			valor: edicaoFaseTORecursos.idFase ? "OPCAO_RECURSOS_PERIODO" : "OPCAO_RECURSOS_NAO",
			onChange: async () => await this.hide("ai-fase-recursos", this.getValor("isComRecursos") == "OPCAO_RECURSOS_NAO")
		});

		const style = edicaoFaseTORecursos.idFase ? `` : `style="display:none"`;
		this.append(`<div class="col-md-12" id="ai-fase-recursos" ${style}>`);
		
		this.addSubtitulo(this.getCfg("LABEL_RECURSOS"));

		this.append(`<div class="row">`);

		this.addCampoTexto({
			id: "fase_recursos_dataInicio",
			label: "De",
			valor: edicaoFaseTORecursos.idFase ? edicaoFaseTORecursos.dataInicio : null,
			tipo: "DATAHORA",
			classe: "col-md-4 col-md-12",
			obrigatorio: true
		})

		this.addCampoTexto({
			id: "fase_recursos_dataFim",
			label: "Até",
			valor: edicaoFaseTORecursos.dataFim,
			tipo: "DATAHORA",
			classe: "col-md-4 col-md-12",
			obrigatorio: true
		});

		this.addEspacamentoHorizontal();

		for (let i = 0; i < edicaoFaseTORecursos.listaEdicaoCfgFaseTO.length; i++) {
			let edicaoCfgFaseTO = edicaoFaseTORecursos.listaEdicaoCfgFaseTO[i];
			
			const permitida = edicaoFaseTORecursos.mapaTipoFasePorTipoCfg[edicaoCfgFaseTO.tipoCfgFase].includes(edicaoFaseTORecursos.tipoFase)
				&& edicaoFaseTORecursos.mapaTipoAplicacaoPorTipoCfg[edicaoCfgFaseTO.tipoCfgFase].includes(edicaoFaseTORecursos.tipoAplicacao.toString())
				&& !["AVALIADO_VISUALIZA_RESULTADO","AVALIADO_VISUALIZA_CORRECAO","AVALIADO_VISUALIZA_GABARITO","AVALIADO_FAZ_DOWNLOAD"].includes(edicaoCfgFaseTO.tipoCfgFase);

			if (!permitida) continue;

			if (edicaoCfgFaseTO.isBooleano) {
				this.addCheckbox({
					id: edicaoFaseTORecursos.tipoFase + "_" + edicaoCfgFaseTO.tipoCfgFase,
					label: edicaoCfgFaseTO.descricaoCfg,
					valor: edicaoCfgFaseTO.valor != null,
					classe: "col-md-4 col-md-12",
				});
			} else if (edicaoCfgFaseTO.isInteiro) {
				this.addCampoTexto({
					id: edicaoFaseTORecursos.tipoFase + "_" + edicaoCfgFaseTO.tipoCfgFase,
					label: edicaoCfgFaseTO.descricaoCfg,
					valor: edicaoCfgFaseTO.valor,
					classe: "col-md-4 col-md-12",
					mascara: "9?99",
				});
			}
		}
		this.append("</div></div>");

		this.fecharFormulario();
		this.append("</div>");

		const adicionarFaseVisualizacao = (edicaoFaseTOIntermediaria) => {

			numFase = numFase || 0;
			numFase++;

			this.setIdTarget("container-fases-visualizacao");
			let titulo = `<strong>${this.getMsg("FP_FRONT_AplicacaoImpressaVH_113")}</strong>`;
			if(edicaoFaseTOIntermediaria.idFase){
				titulo += `&nbsp&nbsp<small>#${edicaoFaseTOIntermediaria.idFase}</small>`
			}
			titulo += this.addLink({
				label: `<i class='fa fa-trash'></i></a>`,
				dica: this.getMsg("FP_FRONT_AplicacaoImpressaVH_107"),
				classe: "pull-right",
				css: "padding: 0px",
				onClick: (event) => {
					$(event.target).closest("[tipo-fase]").slideUp(function () {
						const edicaoFaseTO = $(this).data("edicaoFaseTO");
						if(edicaoFaseTO && edicaoFaseTO.idFase){
							fasesRemovidas.push(edicaoFaseTO.idFase);
						}
						$(this).remove();
					});
				},
				retornarHtml: true
			});

			this.append(`
				<div fase-visualizacao tipo-fase="${TipoAplicacaoFase.INTERMEDIARIA}" class="panel panel-default" num-fase='${numFase}' id-fase='${edicaoFaseTOIntermediaria.idFase}'>
				<div class="panel-heading">
					<h3 class="panel-title">${titulo}</h3>
				</div>
				<div class="panel-body">
			`);

			this.addCampoTexto({
				idComponente: "fase_dataInicio",
				id: numFase + "fase_dataInicio",
				label: this.getMsg("FP_FRONT_AplicacaoImpressaVH_114"),
				valor: edicaoFaseTOIntermediaria.dataInicio,
				tipo: "DATAHORA",
				classe: "col-md-4 col-md-12",
				obrigatorio: true
			})

			this.addCampoTexto({
				idComponente: "fase_dataFim",
				id: numFase + "fase_dataFim",
				label: this.getMsg("FP_FRONT_AplicacaoImpressaVH_115"),
				valor: edicaoFaseTOIntermediaria.dataFim,
				tipo: "DATAHORA",
				classe: "col-md-4 col-md-12",
				obrigatorio: true
			});

			this.addEspacamentoHorizontal();

			for (let i = 0; i < edicaoFaseTOIntermediaria.listaEdicaoCfgFaseTO.length; i++) {
				let edicaoCfgFaseTO = edicaoFaseTOIntermediaria.listaEdicaoCfgFaseTO[i];

				const permitida = edicaoFaseTOIntermediaria.mapaTipoFasePorTipoCfg[edicaoCfgFaseTO.tipoCfgFase].includes(edicaoFaseTOIntermediaria.tipoFase)
					&& edicaoFaseTOIntermediaria.mapaTipoAplicacaoPorTipoCfg[edicaoCfgFaseTO.tipoCfgFase].includes(edicaoFaseTOIntermediaria.tipoAplicacao.toString());

				if (!permitida) continue;

				if (edicaoCfgFaseTO.isBooleano) {
					this.addCheckbox({
						idComponente: edicaoCfgFaseTO.tipoCfgFase,
						id: numFase + edicaoCfgFaseTO.tipoCfgFase,
						label: edicaoCfgFaseTO.descricaoCfg,
						valor: edicaoCfgFaseTO.valor != null,
						classe: "col-md-4 col-md-12",
						onChange: () => cadastroAOVH.atualizarCfgsFaseDependentes(),
					});
				}
			}

			this.append("</div></div>");
			this.setIdTarget(null);
			this.exibir();

			$(`[num-fase=${numFase}]`).data("edicaoFaseTO", edicaoFaseTOIntermediaria);
		}

		this.append(`
			<div id='container-visualizacao' style="display: none;">
				<div class="col-md-12" style="margin-bottom: 20px;">
		`);

		this.addBotao({
			id: "btn_inserir_fase_visualizacao",
			classe: "btn-success",
			label: `<i class='fa fa-plus-circle'></i> ${this.getMsg("FP_FRONT_AplicacaoImpressaVH_106")}`,
			css: "float:right;",
			onClick: async () => {
				let edicaoFaseTOIntermediaria = await this.call("AgendamentoProvaFCD/editarFase", idAplicacao, null, TipoAplicacaoFase.INTERMEDIARIA);
				adicionarFaseVisualizacao(edicaoFaseTOIntermediaria);
			}
		});

		this.append(`
			</div><div id="container-fases-visualizacao" class="col-md-12"></div></div>
		`);

		this.exibirPopups();

		cadastroAOVH.atualizarCfgsFaseDependentes();

		if(!this.isEmpty(idsFaseIntermediaria)){
			for(let idFase of idsFaseIntermediaria){
				let edicaoFaseTOIntermediaria = await this.call("AgendamentoProvaFCD/editarFase", idAplicacao, idFase, TipoAplicacaoFase.INTERMEDIARIA);
				adicionarFaseVisualizacao(edicaoFaseTOIntermediaria);
			}
		}
	}

	aplicarRegrasTipoCaderno() {
		const tipoCaderno = this.getValor("tipoCaderno");

		this.enable("tipoFR");

		if (this.getValor("tipoFase") === "REAPLICACAO") {
			// RESTRIÇÕES REAPLICAÇÃO
			
			if (this.hasValue(tipoCaderno)) {
				if (tipoCaderno === "POR_AVALIADO") {
					this.setValor("tipoFR", "IDENTIFICADA");
				} else {
					this.setValor("tipoFR", "COLETA_ID");
				}

				this.disable("tipoFR");
			}

			this.show("NUM_REAPLICACAO");
			this.tornarObrigatorio("NUM_REAPLICACAO");

		} else {
			this.hide("NUM_REAPLICACAO");
			this.tornarObrigatorio("NUM_REAPLICACAO", false);
			this.setValor("NUM_REAPLICACAO", null);
		}

		if (tipoCaderno == "VARIOS") {
			this.hide("idsCadernosExistentes");
			this.show("numCadernos", "[nomes-cadernos]", "isSecoesRandomizadas", "isQuestoesRandomizadas", "isItensRandomizados", "isIncluirMarcacaoDaOpcaoDeCaderno");
			$("#numCadernos").trigger("change");

		} else if (tipoCaderno == "UNICO") {
			this.hide("numCadernos", "[nomes-cadernos]", "idsCadernosExistentes", "isIncluirMarcacaoDaOpcaoDeCaderno");
			this.show("isSecoesRandomizadas", "isQuestoesRandomizadas", "isItensRandomizados");

		} else if (tipoCaderno == "REUSO") {
			this.hide("numCadernos", "[nomes-cadernos]", "isSecoesRandomizadas", "isQuestoesRandomizadas", "isItensRandomizados");
			this.show("idsCadernosExistentes", "isIncluirMarcacaoDaOpcaoDeCaderno");

		} else if (tipoCaderno == "POR_AVALIADO") {
			this.hide("idsCadernosExistentes", "numCadernos", "[nomes-cadernos]", "isIncluirMarcacaoDaOpcaoDeCaderno", "isIncluirNaoNominalEmNominal");
			this.show("isSecoesRandomizadas", "isQuestoesRandomizadas", "isItensRandomizados");
		}

		if (tipoCaderno == "POR_AVALIADO" || this.getValor("tipoFR") == "IDENTIFICADA" || this.getValor("tipoFR") == "PLANILHA") {
			this.show("idsTurmas");
		} else {
			this.hide("idsTurmas");
		}

		this.atualizarOpcaoSeparacaoAIsTurmas();
	}
}

const aplicacaoImpressaVH = new AplicacaoImpressaVH();
