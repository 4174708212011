class RelNotasEPontuacoesVH extends DataWarehouseVH {

	codProva: number;
	codAgendamento: number;
	ordenacao: any;
	possuiTentativasOuReaplicacao: boolean;

	constructor() {
		super(RelNotasEPontuacoesVH.name);
	}

	async exibirRelNotasEPontuacoes(codProva: number, possuiTentativasOuReaplicacao: boolean, codAgendamento?: number) {

		// T O D O : precisa carregar a info do agendamento no filtro
		codAgendamento = null;

		this.codProva = codProva;
		this.codAgendamento = codAgendamento;
		this.possuiTentativasOuReaplicacao = possuiTentativasOuReaplicacao;

		this.ordenacao = null;

		let cfgsConsultaIndicePFsTO: CfgsConsultaIndicePFsTO = {
			filtrosAtivos: {},
			isExibirSomentePFDaAU: !possuiTentativasOuReaplicacao
		};

		let resultadoNotasEPontuacoesTO: ResultadoNotasEPontuacoesTO = await this.call("RelatorioProvasFCD/gerarNotasEPontuacoes", this.codProva, this.codAgendamento, cfgsConsultaIndicePFsTO);

		if (resultadoNotasEPontuacoesTO.exibirAlertaRanking) {
			cadastroProvaVH.exibirMsgAlertaRanking(resultadoNotasEPontuacoesTO.isRankingDesatualizado, resultadoNotasEPontuacoesTO.isRankingEmProcessamento, codProva);
		}

		this.append("<div class='d-flex justify-end' style='margin-bottom: 15px'>");
		this.addBotaoBusca("filtrosPFs");
		this.append("</div>");

		await this.addFiltrosPFs({
			onAplicarFiltros: async () => {
				cfgsConsultaIndicePFsTO = this.getCfgsConsultaIndicePFsTO();

				resultadoNotasEPontuacoesTO = await this.call("RelatorioProvasFCD/gerarNotasEPontuacoes", this.codProva, this.codAgendamento, cfgsConsultaIndicePFsTO);

				await this.atualizarTabela(cfgsConsultaIndicePFsTO, resultadoNotasEPontuacoesTO);
			},
			cfgsConsultaIndicePFsTO: cfgsConsultaIndicePFsTO,
			restricoesListagensPF: [{
				campo: CampoIndicePFs.COD_PROVA, 
				valores: [codProva]
			}],
			filtrosSuprimidos: [CampoIndicePFs.COD_PROVA],
			suprimirExibirNotasEPontuacoes: true,
			esconderFiltroBuscaTextual: !this.isAdministrador() && this.isCfgHabilitada("FUNCIONALIDADE_CORRECAO_DISCURSIVAS_IMPARCIAL")
		});

		this.append(`
			<div id="tabela-rel-notas-e-pontuacoes" class="tabela-estatisticas"></div>
		`);

		this.exibir();

		await this.atualizarTabela(this.getCfgsConsultaIndicePFsTO(), resultadoNotasEPontuacoesTO);
	}

	async atualizarTabela(cfgsConsultaIndicePFsTO: CfgsConsultaIndicePFsTO, resultadoNotasEPontuacoesTO: ResultadoNotasEPontuacoesTO) {

		const listaPFNotasEPontuacoesTO = resultadoNotasEPontuacoesTO.listaPFNotasEPontuacoesTO;

		listaPFNotasEPontuacoesTO?.forEach(to => {
			if (to.nomeUsuario) {
				to.nomeUsuario = to.nomeUsuario.toUpperCase()
				return;
			}
			to.nomeUsuario = this.getMsg('FP_FRONT_RelatorioProvasVH_106', to.codProvaFeita)
		});

		$("#tabela-rel-notas-e-pontuacoes").html("");
		this.setIdTarget("tabela-rel-notas-e-pontuacoes");

		const propLinkCadastroUsuario = "<i class='fa fa-user' cod-prova-feita='${codProvaFeita}'></i>"
		const colunas: ColunaAddTabela[] = [];
		const propId = [];
		const onEdicao = [];

		if(resultadoNotasEPontuacoesTO.exibirAlertaRanking && listaPFNotasEPontuacoesTO?.some(to => to.posicaoRanking)) {
			propId.push("");
			onEdicao.push("");
			colunas.push({titulo: this.getMsg("FP_FRONT_RelatorioProvasVH_112"), prop: "posicaoRanking", formato: "numero"});
		}

		if (this.isAdministrador() || (!this.isAluno() && !this.isCfgHabilitada("FUNCIONALIDADE_CORRECAO_DISCURSIVAS_IMPARCIAL"))) {
			colunas.push({titulo: "", prop: propLinkCadastroUsuario})
			propId.push("codUsuario");
			onEdicao.push(cadastroUsuarioVH.editarUsuario);
		}

		propId.push("codProvaFeita");
		onEdicao.push(aplicacaoProvaVH.exibirDetalhesProvaRealizada);
		colunas.push({titulo: this.getCfg("LABEL_ALUNO"), prop:"nomeUsuario", classe: "descricao" })

		if (resultadoNotasEPontuacoesTO.nomesCamposEscolhidosParaExibicao) {
			for (let idCampo of Object.keys(resultadoNotasEPontuacoesTO.nomesCamposEscolhidosParaExibicao)) {
				const campo = CampoIndicePFs.from(idCampo);
				const nomePropriedade = resultadoNotasEPontuacoesTO.nomesCamposEscolhidosParaExibicao[idCampo];
				colunas.push({titulo: campo.getNome(), prop: nomePropriedade, formato: campo.formato});
			}
		}

		colunas.push({titulo: this.getMsg("FP_FRONT_RelatorioProvasVH_006"), prop: this.montarColunaMonitoramento, regraExibicao: (collection) => collection?.some(to => typeof to.isComportamentoSuspeito === "boolean") });
		colunas.push({titulo: this.getMsg("FP_FRONT_RelatorioProvasVH_008"), prop: this.montarColunaFacematch, regraExibicao: (collection) => collection?.some(to => typeof to.isTeveProblemaFacematch === "boolean") });
		colunas.push({titulo: this.getMsg("MSG_VH_RP_32"), prop: "dataFimPF", formato: "DD/MM/YY HH:mm"});
		colunas.push({titulo: this.getMsg("MSG_VH_RP_33"), prop: "nota", formato: "numero"});
		colunas.push({titulo: this.getMsg("MSG_VH_RP_34"), prop: "notaMaxima", formato: "numero"});
		colunas.push({titulo: this.getMsg("MSG_VH_RP_37"), prop: "pontuacaoObtida", formato: "numero"});
		colunas.push({titulo: this.getMsg("MSG_VH_RP_36"), prop: "pontuacaoMinima", formato: "numero", regraExibicao: (collection) => collection?.some(to => typeof to.pontuacaoMinima === "number") });
		colunas.push({titulo: this.getMsg("MSG_VH_RP_35"), prop: "pontuacaoMaxima", formato: "numero", regraExibicao: (collection) => collection?.some(to => typeof to.pontuacaoMaxima === "number") });
		colunas.push({titulo: this.getMsg("MSG_VH_RP_38"), prop: "pontuacaoDescontada", formato: "numero", regraExibicao: (collection) => collection?.some(to => typeof to.pontuacaoDescontada === "number") });
		colunas.push({titulo: this.getMsg("FP_FRONT_RelatorioProvasVH_100"), prop: "mencaoObtida", regraExibicao: (collection) => collection?.some(to => to.mencaoObtida) });
		colunas.push({titulo: this.getMsg("MSG_VH_RP_150"), prop: "situacaoDiscursivas", regraExibicao: (collection) => collection?.some(to => to.situacaoDiscursivas) });
		colunas.push({titulo: this.getMsg("MSG_VH_RP_39"), prop: this.montarColunaEnvioCorrecao, regraExibicao: (collection) => collection?.some(to => typeof to.isEnvioCorrecaoSolicitado === "boolean") });
		colunas.push({titulo: this.getMsg("MSG_VH_RP_40"), prop: this.montarColunaAprovacaoECertificado, regraExibicao: (collection) => collection?.some(to => this.hasValue(to.isAtingiuPontuacaoMinima)) });

		if (this.isCfgHabilitada("EMITE_CERTIFICADOS") || this.isCfgHabilitada("GERA_CERTIFICADOS")) {
			colunas.push({titulo: this.getMsg("MSG_VH_RP_41"), prop: this.montarColunaEntregaCertificado});
		}

		colunas.push({titulo: this.getMsg("MSG_VH_RP_42"), prop: this.montarColunaObservacoes, regraExibicao: (collection) => collection?.some(to => to.isArquivada || to.isAnulada || to.obsEntregaCertificado || to.tipoAplicacaoFase || to.isUsuarioArquivado || to.isAplicacaoArquivada || to.numDesconexoes > 0) });
		colunas.push({titulo: this.getMsg("MSG_VH_RP_151"), prop: this.montarColunaTentativas, regraExibicao: (collection) => collection?.some(pfNotasEPontuacoesTO => pfNotasEPontuacoesTO.numTentativasNaAplicacao) });

		await this.addTabela({
			id: "relNotasEPontuacoes",
			collection: resultadoNotasEPontuacoesTO.listaPFNotasEPontuacoesTO,
			propId: propId,
			colunas: colunas,
			selecao: true,
			paginacao: {
				paginacaoTO: resultadoNotasEPontuacoesTO.paginacaoTO,
				onCarregarPagina: async (paginacaoTO) => {

					cfgsConsultaIndicePFsTO.paginacaoTO = paginacaoTO;

					resultadoNotasEPontuacoesTO = await this.call("RelatorioProvasFCD/gerarNotasEPontuacoes", this.codProva, this.codAgendamento, cfgsConsultaIndicePFsTO);

					return resultadoNotasEPontuacoesTO.listaPFNotasEPontuacoesTO;
				}
			},
			onEdicao: onEdicao,
			ordenacao: this.ordenacao,
			onOrdenacaoColuna: async (coluna: ColunaAddTabela, isOrdenacaoDecrescente: boolean) => {

				const propColuna = coluna.prop;

				if (propColuna === propLinkCadastroUsuario) {
					cfgsConsultaIndicePFsTO.campoOrdenacao = CampoIndicePFs.COD_USUARIO.toNomeProp();

				} else if (propColuna === this.montarColunaMonitoramento) {
					cfgsConsultaIndicePFsTO.campoOrdenacao = CampoIndicePFs.NUM_SAIDAS_NAVEGADOR.toNomeProp();

				} else if (propColuna === this.montarColunaFacematch) {
					cfgsConsultaIndicePFsTO.campoOrdenacao = CampoIndicePFs.IS_TEVE_PROBLEMA_FACEMATCH.toNomeProp();

				} else if (propColuna === this.montarColunaEnvioCorrecao) {
					cfgsConsultaIndicePFsTO.campoOrdenacao = CampoIndicePFs.DATA_ENVIO_CORRECAO.toNomeProp();

				} else if (propColuna === this.montarColunaAprovacaoECertificado) {
					cfgsConsultaIndicePFsTO.campoOrdenacao = CampoIndicePFs.IS_ATINGIU_PONTUACAO_MINIMA.toNomeProp();

				} else if (propColuna === this.montarColunaEntregaCertificado) {
					cfgsConsultaIndicePFsTO.campoOrdenacao = CampoIndicePFs.DATA_ENTREGA_CERTIFICADO.toNomeProp();

				} else if (propColuna === this.montarColunaTentativas) {
					cfgsConsultaIndicePFsTO.campoOrdenacao = CampoIndicePFs.NUM_TENTATIVAS_NA_APLICACAO.toNomeProp();
					isOrdenacaoDecrescente = true;

				} else if (propColuna === "situacaoDiscursivas") {
					cfgsConsultaIndicePFsTO.campoOrdenacao = CampoIndicePFs.NUM_DISCURSIVAS_PENDENTES.toNomeProp();

				} else {
					cfgsConsultaIndicePFsTO.campoOrdenacao = propColuna;
				}

				if (cfgsConsultaIndicePFsTO.campoOrdenacao == null) {

					if (typeof propColuna === "function") return;

					this.logger.warn("Coluna " + propColuna + " sem correspondência.");

					return;
				}

				cfgsConsultaIndicePFsTO.isOrdenacaoDecrescente = isOrdenacaoDecrescente;
				cfgsConsultaIndicePFsTO.paginacaoTO = null;

				resultadoNotasEPontuacoesTO = await this.call("RelatorioProvasFCD/gerarNotasEPontuacoes", this.codProva, this.codAgendamento, cfgsConsultaIndicePFsTO);

				this.ordenacao = [[propColuna, isOrdenacaoDecrescente ? 1 : 0]];

				await this.atualizarTabela(cfgsConsultaIndicePFsTO, resultadoNotasEPontuacoesTO);
			}
		});

		this.append(`<div class="btn-group pull-right">`);

		this.addBotao({
			id: "botao-enviar-correcoes-por-email",
			label: `<i class="fa fa-envelope"></i> ${this.getMsg("MSG_VH_RP_28")}`,
			classe: "btn",
			onClick: async () => await gestaoPFVH.enviarEmailComCorrecoes(this.getCodsPFsSelecionados(), async () => {
				await this.exibirRelNotasEPontuacoes(this.codProva, this.possuiTentativasOuReaplicacao, this.codAgendamento);
			})
		});

		this.addBotao({
			id: "btn_anular_provas",
			label: `<i class="fa fa-ban text-danger"></i> ${this.getMsg("FP_FRONT_RelatorioProvasVH_020")}`,
			onClick: async () => await gestaoPFVH.anularProvasRespondidas(this.getCodsPFsSelecionados(), async () => {
				await this.exibirRelNotasEPontuacoes(this.codProva, this.possuiTentativasOuReaplicacao, this.codAgendamento);
			})
		});

		if (UtilAuth.possuiAcesso(TipoFuncionalidade.RECURSOS_VISUALIZACAO) && resultadoNotasEPontuacoesTO.isProvaComRecursos) {
			this.addBotao({
				label: `<i class="fa fa-legal"></i> ${this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_120")}`,
				onClick: async () => await recursoVH.listarRecursosDaProva(null, this.codProva),
			});
		}

		this.append(`</div>`);

		this.exibir();
	}

	getCodsPFsSelecionados() {
		const inputs = Array.from($("#relNotasEPontuacoes tr > td:first-child input:checked").get());
		const codsPFs = inputs.map((input: HTMLInputElement) => Number(input.getAttribute("codProvaFeita")));
		if (this.isEmpty(codsPFs)) {
			this.exibirAlerta({ msg: this.getMsg("FP_FRONT_RelatorioProvasVH_021") });
			return null;
		}
		return codsPFs;
	}

	montarColunaMonitoramento(pfNotasEPontuacoesTO) {
		return this.addLink({
			label: pfNotasEPontuacoesTO.isComportamentoSuspeito ? this.getMsg("FP_FRONT_RelatorioProvasVH_058") : this.getMsg("FP_FRONT_RelatorioProvasVH_057"),
			onClick: async () => await loginVH.exibirAcessosProvaFeita(pfNotasEPontuacoesTO.codProvaFeita),
			classe: "btn-sm",
			css: "text-align: center;" + (pfNotasEPontuacoesTO.isComportamentoSuspeito ? "color:red;" : ""),
			retornarHtml: true,
		});
	}

	montarColunaFacematch(pfNotasEPontuacoesTO) {
		if (pfNotasEPontuacoesTO.isTeveProblemaFacematch === true) {
			return this.addBotao({
				label: "<i class='fa fa-user-circle fa-lg'></i>",
				classe: "btn-warning btn-sm",
				dica: this.getMsg("FP_FRONT_RelatorioProvasVH_007"),
				onClick: async () => {
					await agendamentoProvaVH.exibirModalFaceMatch(pfNotasEPontuacoesTO.codProvaFeita, pfNotasEPontuacoesTO.codUsaurio, pfNotasEPontuacoesTO.nomeUsuario);
				},
				retornarHtml: true
			});
		} else {
			return "OK";
		}
	}

	montarColunaEnvioCorrecao(pfNotasEPontuacoesTO) {
		if (!pfNotasEPontuacoesTO.isEnvioCorrecaoSolicitado) {
			return "";
		} else if (!pfNotasEPontuacoesTO.dataEnvioCorrecao) {
			return "<i class='fa fa-external-link' title='" + this.getMsg("MSG_VH_RP_52") + "...'></i>";
		} else {
			return "<i class='fa fa-envelope' title='" + this.getMsg("MSG_VH_RP_53") + "'></i>";
		}
	}

	montarColunaAprovacaoECertificado(pfNotasEPontuacoesTO) {
		if (pfNotasEPontuacoesTO.isAtingiuPontuacaoMinima === true) {

			if (this.isCfgHabilitada("GERA_CERTIFICADOS")) {
				return this.addLink({
					label: "<i class='fa fa-lg fa-certificate text-success'></i> " + (pfNotasEPontuacoesTO.numCertificado || ""),
					dica: this.getMsg("MSG_VH_RP_60"),
					onClick: async () => await gestaoPFVH.abrirTelaCertificado(pfNotasEPontuacoesTO.codAgendamentoUsuario),
					retornarHtml: true
				});
			} else {
				return "<i class='fa fa-lg fa-check-circle text-success' title='Aprovado'></i>"
			}

		} else if (pfNotasEPontuacoesTO.isAtingiuPontuacaoMinima === false) {
			return "<i class='fa fa-lg fa-times-circle text-danger' title='Reprovado'></i>"
		}

	}

	montarColunaEntregaCertificado(pfNotasEPontuacoesTO) {
		if (pfNotasEPontuacoesTO.dataEntregaCertificado) {
			return this.addLink({
				label: UtilData.toDDMMYYYY(pfNotasEPontuacoesTO.dataEntregaCertificado),
				onClick: async () => {
					await gestaoPFVH.registrarEntregaCertificado(pfNotasEPontuacoesTO, async () => {
						await this.exibirRelNotasEPontuacoes(this.codProva, this.possuiTentativasOuReaplicacao);
					});
				},
				retornarHtml: true
			});
		}
		if (pfNotasEPontuacoesTO.isAtingiuPontuacaoMinima === true && this.isCfgHabilitada("EMITE_CERTIFICADOS")) {
			return this.addLink({
				label: this.getMsg("MSG_VH_RP_54"),
				onClick: async () => {
					await gestaoPFVH.registrarEntregaCertificado(pfNotasEPontuacoesTO, async () => {
						await this.exibirRelNotasEPontuacoes(this.codProva, this.possuiTentativasOuReaplicacao);
					});
				},
				retornarHtml: true
			});
		}
	}

	montarColunaObservacoes(pfNotasEPontuacoesTO) {
		const h = [];
		if (pfNotasEPontuacoesTO.tipoAplicacaoFase) h.push(pfNotasEPontuacoesTO.tipoAplicacaoFase.getNome());
		if (pfNotasEPontuacoesTO.isAnulada) h.push(`<span class='label label-danger'>${this.getMsg("FP_FRONT_RelatorioProvasVH_065")}</span>`);
		if (pfNotasEPontuacoesTO.isArquivada) h.push(`<span class='label label-default'>${this.getMsg("FP_FRONT_RelatorioProvasVH_064")}</span>`);
		if (pfNotasEPontuacoesTO.isUsuarioArquivado) h.push(`<span class='label label-default'>${this.getMsg("FP_FRONT_RelatorioProvasVH_114")}</span>`);
		if (pfNotasEPontuacoesTO.isAplicacaoArquivada) h.push(`<span class='label label-default'>${this.getMsg("FP_FRONT_RelNotasEPontuacoesVH_003")}</span>`);
		if (pfNotasEPontuacoesTO.obsEntregaCertificado) h.push(pfNotasEPontuacoesTO.obsEntregaCertificado);
		if (pfNotasEPontuacoesTO.numDesconexoes > 0) h.push(`<span title="${this.getMsg("FP_FRONT_RelNotasEPontuacoesVH_002")}">${this.getMsg("FP_FRONT_RelNotasEPontuacoesVH_001", pfNotasEPontuacoesTO.numDesconexoes)}</span>`);
		return h.join("<br>");
	}

	montarColunaTentativas(pfNotasEPontuacoesTO) {
		if (!pfNotasEPontuacoesTO.numTentativasNaAplicacao) return "";
		return this.addBotao({
			classe: "btn btn-default",
			label: pfNotasEPontuacoesTO.numTentativasNaAplicacao,
			onClick: async () => await relatorioProvasVH.exibirTentativas(pfNotasEPontuacoesTO.codAgendamentoUsuario),
			retornarHtml: true
		});
	}
}

const relNotasEPontuacoesVH = new RelNotasEPontuacoesVH();

type StatusExibicaoRankingTO = {
	exibirAlertaRanking: boolean;
	isRankingDesatualizado: boolean;
	isRankingEmProcessamento: boolean;
	possuiAgendamentoNaoEncerrado: boolean;
	possuiDiscursivaNaoCorrigida: boolean;
	possuiAgendamentos: boolean;
}
type ResultadoNotasEPontuacoesTO = {
	isProvaComRecursos: boolean;
	isProvaComCorrecaoDeDiscursivas: boolean;
	paginacaoTO: PaginacaoTO;
	listaPFNotasEPontuacoesTO: any[];
	nomesCamposEscolhidosParaExibicao: object;
} & StatusExibicaoRankingTO;