class NeftProctoringService extends ProctoringService {

	msgEventTypes: any;
	proctoringIniciado = false;
	neft: any;
	
	constructor() {
		
		super(NeftProctoringService.name);

		this.msgEventTypes = {
			"recording-started": UtilMsg.getMsg("FP_FRONT_NeftProctoringService_005"),
			"local-media": UtilMsg.getMsg("FP_FRONT_NeftProctoringService_006"),
			"media-ended": UtilMsg.getMsg("FP_FRONT_NeftProctoringService_007"),
			"multiple-screens-detected": UtilMsg.getMsg("FP_FRONT_NeftProctoringService_008"),
			"request-user-media-error": UtilMsg.getMsg("FP_FRONT_NeftProctoringService_004")
		}
	}
	
	async pedirAutorizacoesUsuario(onAutorizacoesConcedidas: Function) {

		const { neft } = await UtilBoot.carregarProctoringNeft();
		this.neft = neft;

		try {
			const codProvaFeita = this.fiscalizacaoMonitoradaTO.codProvaFeita;

			if(codProvaFeita){
				neft(codProvaFeita);

				neft.onEvent.subscribe((event: any) => {
					const eventType = event.detail.type;
					const eventData = event.detail.data;

					if(eventType === 'recording-started') {
						this.proctoringIniciado = true;
						onAutorizacoesConcedidas();
					}

					if(eventType === 'request-user-media-error') {
						this.proctoringIniciado = false;

						this.chamarOnErro({
							type: eventType,
							title: `${this.msgEventTypes[eventType]} - ${eventType}`,
							message: eventData || UtilMsg.getMsg("FP_FRONT_NeftProctoringService_002")
						});
					} else {
						let msg = this.msgEventTypes[eventType];
						if(msg){
							msg = `${msg} - ${eventType}`;
						} else {
							msg = UtilMsg.getMsg("FP_FRONT_NeftProctoringService_009");
						}
						this.triggerMsgLog({
							msg: msg,
							msgIntegracao: eventData
						});
					}

					console.log(`Neft event received - type: ${eventType} - data: ${eventData}`);
				});
			}
		} catch (e) {
			console.error(e);
			this.chamarOnErro(e);
			throw new Error(e);
		}
	}

	chamarOnErro(e: any){
		this.proctoringIniciado = false;
		let msgUsuario = UtilMsg.getMsg("FP_FRONT_NeftProctoringService_003");
		const msg = e.message || e;
		let msgAuditoria = e.title || UtilMsg.getMsg("FP_FRONT_NeftProctoringService_001");

		this.triggerErro({
			msgUsuario: msgUsuario,
			msgAuditoria: msgAuditoria,
			msgIntegracaoAuditoria: {msg}
		});

		this.neft.stop();
	}
	
	iniciarGravacao() {}
	
	isIniciado() {
		return this.proctoringIniciado === true;
	}

	async parar() {
		if (this.isIniciado()) {
			try {
				this.proctoringIniciado = false;
				this.neft.stop();
			} catch (e) {
				console.error("Erro ao parar proctoring Neft", e);
			}
		}
	}
}
