class CadastroProvaVH extends AmaisVH {

	isExibicaoEmPopup: boolean;
	recuperacaoProvaTO: any;
	// codsEmpresasBusca: number[];
	// isExibirArquivados: boolean;
	// buscaTextual: string;
	// codsAvaliacoesFiltro: number[];
	// dataAplicacaoInicioBusca: string;
	// dataAplicacaoFimBusca: string;
	// codsUsuariosCriadores: number[];
	filtrosProvasTO = new FiltrosProvasTO();
	numMinimoCaracteresSelectAvaliacoes = 2;
	// numeroDaPagina: any;
	// inicioPeriodoCadastroBusca: string;
	// fimPeriodoCadastroBusca: string;
	// inicioPeriodoAtualizacaoBusca: string;
	// fimPeriodoAtualizacaoBusca: string;

	constructor() {
		super(CadastroProvaVH.name);
		this.addOperacaoParaHash("listpr", this.listarProvas);
		this.addOperacaoParaHash("edpr", this.exibirAbasProva);
		this.addOperacaoParaHash("cppps", this.exibirProvasPorSituacao);
		this.addOperacaoParaHash("cpas", this.exibirAbasPorSituacao);
		this.addOperacaoParaHash("cpnp", this.exibirCadastroNovaProva);
	}

	getFiltrosProvasTO(limparPaginacao = false): FiltrosProvasTO {
		if (limparPaginacao) {
			this.filtrosProvasTO.paginacaoTO = null;
		}
		return this.filtrosProvasTO;
	}

	async exibirProvasPorSituacao(busca?: string) {

		this.filtrosProvasTO.setBuscaTextual(busca);

		UtilHash.registrarHistorico(this.exibirProvasPorSituacao, busca);

		const exibicaoProvasPorSituacaoTO: ExibicaoProvasPorSituacaoTO = await this.call(
			"CadastroProvaFCD/listarProvasPorGrupo", 
			this.filtrosProvasTO
		);

		const exibicaoProvasEmConstrucaoTO = exibicaoProvasPorSituacaoTO.exibicaoProvasEmConstrucaoTO;
		const exibicaoProvasRevisadasTO = exibicaoProvasPorSituacaoTO.exibicaoProvasRevisadasTO;
		const exibicaoProvasEmAplicacaoTO = exibicaoProvasPorSituacaoTO.exibicaoProvasEmAplicacaoTO;
		const exibicaoProvasAplicadasTO = exibicaoProvasPorSituacaoTO.exibicaoProvasAplicadasTO;
	
		if ($("#dashboard_provas_por_situacao").length == 0) {

			this.limpar(false, true);
			this.setTitulo(this.getMsg("FP_FRONT_CadastroProvaVH_001"));

			if (this.isAnalista()) {
				this.setIdTarget("tituloSuperiorDireito");

				this.appendJs(() => $('#tituloSuperiorDireito').attr('style', 'float: none;'));

				this.append("<div class='btn-group hidden-xs pull-right'>");
				this.append(this.gerarHtmlBotoesProvas(exibicaoProvasPorSituacaoTO.possuiAIsAtivas));
				this.append("</div>");

				this.setIdTarget(null);

				await this.addFiltrosProvas({
					isExibirAvaliacoes: exibicaoProvasPorSituacaoTO.isExibirAvaliacoes,
					onFiltrosAlterados: () => {
						this.exibirProvasPorSituacao(this.filtrosProvasTO.buscaTextual);
					}
				});
			}

			this.append("<div class='row' id='dashboard_provas_por_situacao'></div>");

			this.addEspacamentoHorizontal("100px");

			this.exibir();
		}

		$("#dashboard_provas_por_situacao").html("");
		this.setIdTarget("dashboard_provas_por_situacao");

		let badgeTodasEmConstrucao = "";
		let botaoTodasEmConstrucao = "";
		
		if (this.hasValue(exibicaoProvasPorSituacaoTO.numTotalProvasEmConstrucao)) {
			badgeTodasEmConstrucao = `<span class="badge">${exibicaoProvasPorSituacaoTO.numTotalProvasEmConstrucao}</span>`;
			// botaoTodasEmConstrucao = ` 
			// 	<button class='btn btn-default btn-sm pull-right' num-aba-provas=0> 
			// 		${this.getMsg("FP_FRONT_CadastroProvaVH_003")} ${exibicaoProvasPorSituacaoTO.numTotalProvasEmConstrucao}
			// 	</button>
			// `;
		}

		this.append(`
			<div dash-provas class='col-sm-12' style='padding-left: 0px; padding-right: 0px;'>
				<div dash-linha class="row">
					<div>
						<div class='panel panel-default'>
							<div class='panel-heading'>
								<h3>
									${badgeTodasEmConstrucao}
									<a num-aba-provas=0>
										${this.getMsg("FP_FRONT_CadastroProvaVH_002")}
									</a>
									${botaoTodasEmConstrucao}
								</h3>
							</div>
							<div class='panel-body'>
		`);
		await this.exibirListagemProvasEmConstrucao(exibicaoProvasEmConstrucaoTO);
		this.append(`
							</div>
						</div>
					</div>
		`);

		if (exibicaoProvasRevisadasTO) {
			
			let badge = "";
			let botao = "";

			if (this.hasValue(exibicaoProvasPorSituacaoTO.numTotalProvasRevisadas)) {
				badge = `<span class="badge">${exibicaoProvasPorSituacaoTO.numTotalProvasRevisadas}</span>`;
				// botao = `
				// 	<button class='btn btn-default btn-sm pull-right' num-aba-provas=1> 
				// 		${this.getMsg("FP_FRONT_CadastroProvaVH_005")} ${exibicaoProvasPorSituacaoTO.numTotalProvasRevisadas}
				// 	</button>
				// `;
			}

			this.append(`
					<div>
						<div class='panel panel-default'>
							<div class='panel-heading'>
								<h3>
									${badge}
									<a num-aba-provas=1>
										${this.getMsg("FP_FRONT_CadastroProvaVH_004")}
									</a>
									${botao}
								</h3>
							</div>
							<div class='panel-body'>
			`);
			await this.exibirListagemProvasRevisadas(exibicaoProvasRevisadasTO);
			this.append(`
							</div>
						</div>
					</div>
			`);
		}

		this.append("</div>");

		if (exibicaoProvasEmAplicacaoTO || exibicaoProvasAplicadasTO) {

			this.append(`<div dash-linha class="row">`);

			if (exibicaoProvasEmAplicacaoTO) {

				let badge = "";
				let buttonVerTodas = "";
				let buttonDashUsuariosOnline = "";

				if (this.hasValue(exibicaoProvasPorSituacaoTO.numTotalProvasEmAplicacao)) {
					badge = `<span class="badge">${exibicaoProvasPorSituacaoTO.numTotalProvasEmAplicacao}</span>`;
					// buttonVerTodas = `
					// 	<button class='btn btn-default btn-sm pull-right' num-aba-provas=2> 
					// 		${this.getMsg("FP_FRONT_CadastroProvaVH_007")} 
					// 		${exibicaoProvasPorSituacaoTO.numTotalProvasEmAplicacao}
					// 	</button>
					// `;
				}

				if (this.isAdministrador() || this.isAdministradorSistema()) {
					buttonDashUsuariosOnline = `
						<a class='btn btn-default btn-sm' href='${UtilHash.getHash(dashboardUsuarioVH.exibirUsuariosOnline)}' title="${this.getMsg("FP_FRONT_CadastroProvaVH_186")}">
							<i class='fa fa-user-circle'></i>
						</a>
					`;
				}

				this.append(`
					<div>
						<div class='panel panel-default'>
							<div class='panel-heading'>
								<h3>
									${badge}
									<a num-aba-provas=2>
										${this.getMsg("FP_FRONT_CadastroProvaVH_006")}
									</a>
									<div class='btn-group pull-right'>
										<a class='btn btn-default btn-sm' href='${UtilHash.getHash(aplicacaoAbertaVH.listar)}'>
											<i class='fa fa-link'></i>
										</a>
										${buttonDashUsuariosOnline}
										${buttonVerTodas}
									</div>
								</h3>
							</div>
							<div class='panel-body'>
				`);

				await this.exibirListagemProvasEmAplicacao(exibicaoProvasEmAplicacaoTO);

				this.append(`
							</div>
						</div>
					</div>
				`);
			}

			if (exibicaoProvasAplicadasTO) {

				let badge = "";
				let botao = "";
	
				if (this.hasValue(exibicaoProvasPorSituacaoTO.numTotalProvasAplicadas)) {
					badge = `<span class="badge">${exibicaoProvasPorSituacaoTO.numTotalProvasAplicadas}</span>`;
					// botao = `
					// 	<button class='btn btn-default btn-sm pull-right' num-aba-provas=3> 
					// 		${this.getMsg("FP_FRONT_CadastroProvaVH_008")} ${exibicaoProvasPorSituacaoTO.numTotalProvasAplicadas}
					// 	</button>
					// `;
				}

				this.append(`
						<div>
							<div class='panel panel-default'>
								<div class='panel-heading'>
									<h3>
										${badge}
										<a num-aba-provas=3>
											${this.getMsg("FP_FRONT_CadastroProvaVH_009")}
										</a>
										${botao}
									</h3>
								</div>
								<div class='panel-body'>
				`);
				await this.exibirListagemProvasAplicadas(exibicaoProvasAplicadasTO);
				this.append(`
								</div>
							</div>
						</div>
				`);
			}

			this.append("</div>");
		}

		this.append("</div>");

		this.exibir();

		this.setIdTarget(null);

		$("[num-aba-provas]").on("click", ({ target }) => {
			this.exibirAbasPorSituacao($(target).attr("num-aba-provas"), this.filtrosProvasTO.buscaTextual);
		})
	}

	async listarProvas() {

		const atualizarListaProvas = async (paginacaoTO?) => {

			this.filtrosProvasTO.paginacaoTO = paginacaoTO;

			let exibicaoListaProvasTO: ExibicaoListaProvasTO = await this.call("CadastroProvaFCD/listarPagina", this.filtrosProvasTO);

			this.setHtml("exibicao_lista_provas", "");
			this.setIdTarget("exibicao_lista_provas");

			await this.addTabela({
				collection: exibicaoListaProvasTO.collectionListagemProvaTO,
				id: "listagem_provas",
				propId: "codProva",
				colunas: [{
					titulo: this.getMsg("FP_FRONT_CadastroProvaVH_013"),
					prop: this.montarTituloListagem,
					classe: "descricao",
				}, {
					titulo: this.getMsg("FP_FRONT_CadastroProvaVH_040"),
					prop: "segmento",
				}, {
					titulo:  this.getMsg("FP_FRONT_CadastroProvaVH_014"),
					prop: "numQuestoes",
				}, {
					titulo: this.getMsg("FP_FRONT_CadastroProvaVH_015"),
					prop: "dataCadastro",
					formato: "DD/MM/YY",
				}, {
					titulo: this.getMsg("FP_FRONT_CadastroProvaVH_183"),
					prop: "nomeUsuarioCriador",
				}, {
					titulo: this.getMsg("FP_FRONT_CadastroProvaVH_016"),
					prop: "numUsuariosAvaliados",
				}, {
					titulo: this.getMsg("FP_FRONT_CadastroProvaVH_017"),
					prop: "numUsuariosAguardando",
				}, {
					titulo: "",
					prop: this.montarBotoesListagemProva,
					classe: "table-actions-provas",
				}],
				paginacao: {
					paginacaoTO: exibicaoListaProvasTO.paginacaoTO,
					botoesAdicionais: [{
						label: `<i class="fas fa-list-ol"></i>`,
						onClick: async () => await this.inserirNomesSecoesNoTituloDaProva(),
						dica: "Exibir seções"
					}],
					exibirPaginacaoRodape: true,
					onCarregarPagina: async (paginacaoTO) => {
						await atualizarListaProvas(paginacaoTO);
					},
				},
				ordenar: false,
				msgListaVazia: this.getMsg("FP_FRONT_CadastroProvaVH_018"),
				selecao: this.isAdministrador(),
				atibutoParaDesabilitarCheckbox: 'isArquivada',
			});

			setTimeout(() => {
				$('#listagem_provas input[type="checkbox"]').change(() => {
					const selecoesListagemProva = this.getValor("listagem_provas").map(Number);
					const btnArquivarProvas = $('#btn-arquivar-provas');
					if (selecoesListagemProva?.length) {
						btnArquivarProvas.show();
						return;
					}
					btnArquivarProvas.hide();
				})

			}, 300);

			if (this.isAdministrador()) {
				this.addBotao({
					label: this.getMsg("FP_FRONT_CadastroProvaVH_200"),
					classe: "btn-danger pull-right",
					id: "btn-arquivar-provas",
					visivel: false,
					onClick: async () => {
						this.exibirAlerta({
							titulo: `<i class="fas fa-exclamation-triangle"></i> ${this.getMsg('MSG_VH_A_15')}`,
							msg: this.getMsg("FP_FRONT_CadastroProvaVH_202"),
							botoes: [
								{
									label: this.getMsg("FP_FRONT_CadastroProvaVH_201")
								},
								{
									label: this.getMsg("FP_FRONT_CadastroProvaVH_203"),
									classe: "btn-danger",
									onClick: async () => {
										const codsProvas = this.getValor("listagem_provas").map(Number)
								 		await this.call("CadastroProvaFCD/excluirProvas", codsProvas);
										await atualizarListaProvas();
									}
								}
							]
						});
					}
				});
			}

			this.exibir();
		}

		UtilHash.registrarHistorico(this.listarProvas);

		let exibicaoFiltrosListaProvasTO: ExibicaoFiltrosListaProvasTO = await this.call("CadastroProvaFCD/listarProvas");

		this.limpar();

		this.setTitulo(this.getMsg("FP_FRONT_CadastroProvaVH_010"));

		if (this.isAnalista()) {
			this.setIdTarget("tituloSuperiorDireito");
			this.appendJs(() => $('#tituloSuperiorDireito').attr('style', 'float: none;'));

			const htmlBotoes = this.gerarHtmlBotoesProvas(exibicaoFiltrosListaProvasTO.possuiAIsAtivas);

			// this.append(`
			// 	<div class='btn-group-vertical col-xs-12 visible-xs' style='padding-right: 0px; padding-left: 0px;'>
			// 		${botao}
			// 	</div>
			// `);

			this.append(`
				<div class='btn-group hidden-xs pull-right'>
					${htmlBotoes}
				</div>
			`);

			this.setIdTarget(null);
		}

		await this.addFiltrosProvas({
			isExibirAvaliacoes: exibicaoFiltrosListaProvasTO.isExibirAvaliacoes, 
			onFiltrosAlterados: () => {
				atualizarListaProvas();
			}
		});

		this.append("<div class='row' id='exibicao_lista_provas'></div>");

		this.exibir();

		atualizarListaProvas();
	}

	montarBotoesListagemProva(to: ListagemProvaTO) {

		if (to.numUsuariosAvaliados > 0) {

			const h = ["<div class='btn-group pull-right' id='actions-table'>"];

			h.push(this.addBotao({
				label: "<i class='fa fa-lg fa-check-square-o'></i>",
				dica: this.getMsg("FP_FRONT_CadastroProvaVH_022"),
				classe: "btn-sm fp-small-business-hidden",
				href: UtilHash.getHash(this.exibirAbasProva, 5, to.codProva),
				retornarHtml: true
			}));

			if (to.possuiCorrecaoDeDiscursivas) {
				h.push(this.addBotao({
					label: "<i class='fa fa-lg fa-pencil-square'></i>",
					dica: this.getMsg("FP_FRONT_CadastroProvaVH_020"),
					classe: "btn-sm",
					onClick: () => {
						acompanhamentoCDsVH.exibirDetalhamentoCorrecoesProva(to.codProva, to.titulo);
					},
					retornarHtml: true
				}));
			}

			if (to.possuiRecursos) {
				h.push(this.addBotao({
					label: "<i class='fa fa-lg fa-legal'></i>",
					dica: this.getMsg("FP_FRONT_CadastroProvaVH_021"),
					classe: "btn-sm",
					href: UtilHash.getHash(recursoVH.listarRecursosDaProva, null, to.codProva),
					retornarHtml: true
				}));
			}

			h.push("</div>");

			return h.join("");
		}
	}

	montarTituloListagem(listagemProvaTO) {

		let titulo = "<a href='" + UtilHash.getHash(this.exibirAbasProva, 1, listagemProvaTO.codProva) + "'>" + listagemProvaTO.titulo + "</a>";

		if (listagemProvaTO.isArquivada) {
			titulo += `<br><span class='label label-default'>${this.getMsg("FP_FRONT_CadastroProvaVH_127")}</span>`;
		}

		return titulo + "<div class='detalhesProva' codProva='" + listagemProvaTO.codProva + "'></div>";
	}

	async exibirInformacoesBasicas(codProva?: number, isExibicaoEmPopup?: boolean, onProvaSalva?: Function) {

		if (this.isEmpty(isExibicaoEmPopup)) {
			this.isExibicaoEmPopup = false;

		} else if (isExibicaoEmPopup) {
			this.isExibicaoEmPopup = true;
		}

		const recuperacaoProvaTO = await this.call("CadastroProvaFCD/recuperarProva", codProva);

		this.recuperacaoProvaTO = recuperacaoProvaTO;

		if (!this.isExibicaoEmPopup) {
			this.limpar(true);

		} else {
			this.addPopup({
				id: "edicao_prova",
				titulo: recuperacaoProvaTO.titulo,
				width: "800px",
				// height: "600px"
			})
		}

		this.addFormulario();
		let html = recuperacaoProvaTO.codProva ? " cod-prova='" + recuperacaoProvaTO.codProva + "' " : "";
		
		this.append(`
			<div edicao-prova class='col-md-12' ${html}>
				<div class='row'>
		`);

		if (recuperacaoProvaTO.exibirAlertaRanking) {
			this.exibirMsgAlertaRanking(recuperacaoProvaTO.isRankingDesatualizado, recuperacaoProvaTO.isRankingEmProcessamento, recuperacaoProvaTO.codProva);
		}

		this.addSubtitulo(this.getMsg("FP_FRONT_CadastroProvaVH_035"));

		this.addCampoTexto({
			id: "prova_nome",
			label: this.getMsg("FP_FRONT_CadastroProvaVH_029"),
			valor: recuperacaoProvaTO.titulo,
			maxLength: "255",
			classe: "col-md-6",
			obrigatorio: true
		});

		this.addCampoTexto({
			id: "prova_nome_publico",
			label: this.getMsg("FP_FRONT_CadastroProvaVH_030"),
			ajuda: this.getMsg("FP_FRONT_CadastroProvaVH_036"),
			valor: recuperacaoProvaTO.tituloPublico,
			maxLength: "255",
			classe: "col-md-6 fp-small-business-hidden",
			obrigatorio: false
		});

		this.addCampoTexto({
			id: "numeracaoInicial",
			label: this.getMsg("FP_FRONT_CadastroProvaVH_031"),
			valor: recuperacaoProvaTO.numeracaoInicial,
			mascara: "9?99",
			classe: "col-md-6 fp-small-business-hidden",
			obrigatorio: true
		});

		this.addEspacamentoHorizontal();

		this.addEditorHTML({
			id: "descricaoInstrucoes",
			label: this.getMsg("FP_FRONT_CadastroProvaVH_032"),
			valor: recuperacaoProvaTO.descricaoInstrucoes,
			ajuda: this.getMsg("FP_FRONT_CadastroProvaVH_037"),
			classe: "col-md-6 descricaoInstrucoesContainer fp-small-business-hidden"
		});

		this.addEditorHTML({
			id: "textoAnexo",
			label: this.getMsg("FP_FRONT_CadastroProvaVH_033"),
			valor: recuperacaoProvaTO.textoAnexo,
			ajuda: this.getMsg("FP_FRONT_CadastroProvaVH_038"),
			classe: "col-md-6 textoAnexoContainer fp-small-business-hidden"
		});

		if (this.isCfgHabilitada("GERA_CERTIFICADOS")) {
			this.addEditorHTML({ id: "textoCertificado", label: this.getMsg("FP_FRONT_CadastroProvaVH_034"), valor: recuperacaoProvaTO.textoCertificado, classe: "col-md-6 fp-small-business-hidden" });
		}

		const isSegmentosVisiveis = this.possuiSegmentos(recuperacaoProvaTO.collectionSegmentosTO);

		await this.addSelect({
			collection: recuperacaoProvaTO.collectionSegmentosTO,
			id: "codEmpresa",
			classe: "col-md-6 fp-small-business-hidden",
			label: this.getMsg("FP_FRONT_CadastroProvaVH_040"),
			valor: recuperacaoProvaTO.codEmpresa,
			obrigatorio: true,
			visivel: isSegmentosVisiveis,
			onChange: async () => {
				this.setValor("codsAvaliacoes", null);
			}
		});

		if (this.recuperacaoProvaTO.isExibirAvaliacoes) {
			await this.addSelect({
				id: "codsAvaliacoes",
				classe: "col-md-6 fp-small-business-hidden",
				label: this.getCfg("LABEL_AVALIACAO"),
				valor: this.recuperacaoProvaTO.codsAvaliacoes,
				multiplo: true,
				loadOpcoesBack: {
					endpoint: "ListagemSelecaoFCD/buscarAvaliacoes",
					payload: {
						codEmpresa: () => this.getValor("codEmpresa"), 
						codsAvaliacoesIgnorados: () => this.getValor("codsAvaliacoes"),
					},
					numMinimoCaracteres: this.numMinimoCaracteresSelectAvaliacoes,
				}
			});
		}

		this.addEspacamentoHorizontal();

		if (recuperacaoProvaTO.dataCadastro) {
			this.addCampoExibicao({
				id: "label_prova_cadastrada_em",
				label: this.getMsg("FP_FRONT_CadastroProvaVH_236"),
				valor: UtilData.toDDMMYYYYHHMMSS(recuperacaoProvaTO.dataCadastro)
			});
		}

		if (this.hasValue(recuperacaoProvaTO.dataAtualizacao)) {
			this.addCampoExibicao({
				label: this.getMsg("FP_FRONT_CadastroProvaVH_237"),
				valor: UtilData.toDDMMYYYYHHMMSS(recuperacaoProvaTO.dataAtualizacao)
			});
		}

		this.append("<div id='container_criterios_de_avaliacao'>");
		this.addSubtitulo({
			texto: this.getMsg("FP_FRONT_CadastroProvaVH_039"),
			id: "subtitulo_criterios_de_avaliacao"
		});

		this.addCampoTexto({
			id: "qtdErrosAnulamCerta",
			label: this.getMsg("FP_FRONT_CadastroProvaVH_043"),
			valor: this.recuperacaoProvaTO.qtdErrosAnulamCerta,
			mascara: "9?99",
			classe: "col-md-6 fp-small-business-hidden"
		});

		this.addCampoTexto({
			id: "porcentagemPontuacaoMinima",
			label: this.getMsg("FP_FRONT_CadastroProvaVH_044"),
			valor: this.recuperacaoProvaTO.porcentagemPontuacaoMinima,
			maxLength: "3",
			sufixo: "%",
			mascara: "9?9",
			classe: "col-md-6"
		})

		if (this.isCfgHabilitada("PONTUACAO_PROPORCIONAL") && !this.recuperacaoProvaTO.notaMaxima) {
			this.recuperacaoProvaTO.notaMaxima = 10;
		}

		this.addCampoTexto({
			id: "notaMaxima",
			obrigatorio: this.isCfgHabilitada("PONTUACAO_PROPORCIONAL"),
			label: this.getMsg("FP_FRONT_CadastroProvaVH_045"),
			ajuda: "base 10, base 50, etc",
			valor: this.recuperacaoProvaTO.notaMaxima,
			tipo: "NUMERO",
			maxLength: "6",
			classe: "col-md-6 fp-small-business-hidden"
		});

		this.addEspacamentoHorizontal("1px");

		this.addCheckbox({
			id: "IS_RESULTADO_POR_MENCAO",
			valor: this.recuperacaoProvaTO.cfgs["IS_RESULTADO_POR_MENCAO"] != null,
			label: this.recuperacaoProvaTO.mapaCfgs["IS_RESULTADO_POR_MENCAO"],
			classe: "col-md-4 col-sm-6 fp-small-business-hidden"
		});

		this.append("</div>");

		this.addSubtitulo({
			id: "container-grau-dificuldade",
			classeContainer: 'fp-small-business-hidden',
			texto: this.getMsg("FP_FRONT_CadastroProvaVH_188")
		});

		this.append(`
			<div id='grau-dificuldade-body' class='col-md-12 fp-small-business-hidden'>
				<div class='row'>
		`);

		let alertaGrauDificuldade = null;
		const temSecoes = this.hasValue(this.recuperacaoProvaTO.secoes);
		const temQuestoes = this.recuperacaoProvaTO.numQuestoes > 0;
		const codsQuestoesSemDificuldade = this.recuperacaoProvaTO.codsQuestoesSemDificuldade;
		const todasQuestoesTemDificuldade = codsQuestoesSemDificuldade.length == 0;
		const isDificuldadesConfiguradas = this.recuperacaoProvaTO.isDificuldadesConfiguradas;
		let temSorteio = null;

		if(temSecoes && temQuestoes) {
			temSorteio = this.recuperacaoProvaTO.secoes.filter((s: any) => {
				return s.isSorteado === true;
			}).length > 0;
		}

		this.addCheckbox({
			label: this.getMsg("FP_FRONT_CadastroProvaVH_046"),
			id: "isDificuldadesConfiguradas",
			valor: isDificuldadesConfiguradas,
			onChange: async () => await this.exibirEsconderDificuldades(),
			classe: "col-md-4 col-sm-6",
			visivel: temSecoes && temQuestoes && temSorteio
		});
		
		this.append(`
				</div>
		`);

		if (!temSecoes || !temQuestoes) {
			alertaGrauDificuldade = this.getMsg("FP_FRONT_CadastroProvaVH_189");
		} else if (!temSorteio) {
			alertaGrauDificuldade = this.getMsg("FP_FRONT_CadastroProvaVH_190");
		}

		if (alertaGrauDificuldade != null) {
			this.append(`
				<div class="alert alert-warning col-md-12">
					<i class="fa fa-exclamation-triangle"></i>
					${alertaGrauDificuldade}
				</div>
			`);
		}

		if (!todasQuestoesTemDificuldade) {
			const listaLinksQuestoes = codsQuestoesSemDificuldade.map((codQuestao: any) => {
				let hash = UtilHash.getHash(cadastroQuestaoVH.exibirAbasQuestao, 1, codQuestao);
				return `<a href='${hash}' target='_blank'> #${codQuestao} </a>`;
			});

			this.append(`
				<div class="alert alert-danger col-md-12">
					<i class="fa fa-exclamation-triangle"></i>
					${this.getMsg("FP_FRONT_CadastroProvaVH_191")}
					${listaLinksQuestoes.join(" ")}
				</div>
			`);
		}

		if (this.hasValue(this.recuperacaoProvaTO.collectionExibicaoDificuldadeProvaTO) && temSecoes && temQuestoes && temSorteio && todasQuestoesTemDificuldade) {

			this.append(`
				<div class="row" id="tabelaDificuldades" style="${isDificuldadesConfiguradas ? "" : "display: none"}">
			`);
			
			for (const exibicaoDificuldadeProvaTO of recuperacaoProvaTO.collectionExibicaoDificuldadeProvaTO) {
				this.addCampoTexto({
					id: `porcentagemDificuldade${exibicaoDificuldadeProvaTO.grauDificuldade}`,
					label: exibicaoDificuldadeProvaTO.nomeDificuldade,
					valor: exibicaoDificuldadeProvaTO.porcentagemConfigurada,
					mascara: "9?9",
					sufixo: "%",
					classe: "porcentagemDificuldade",
					css: "width: 470px"
				});
			}

			if (isDificuldadesConfiguradas) {

				const colunas: ColunaAddTabela[] = [];
				colunas.push({titulo: this.getMsg("FP_FRONT_CadastroProvaVH_192"), prop: "nome"});
				colunas.push({titulo: this.getMsg("FP_FRONT_CadastroProvaVH_193"), prop: "numQuestoes", classe: "cadastro-prova-dificuldade-associadas"});

				const mapSecoesDificuldades = new Map<number, SorteioSecaoPorGrau[]>;

				for (const recuperacaoSecaoTO of this.recuperacaoProvaTO.secoes) {
					
					if (!this.hasValue(recuperacaoSecaoTO.listDificuldadeQuestoes)) continue;

					const numQuestoesAssociadasPorDificuldade = new Map<number, number>;

					for (const dq of recuperacaoSecaoTO.listDificuldadeQuestoes) {
						numQuestoesAssociadasPorDificuldade.set(dq.grau, dq.quantidade);
					}

					const listaSorteioSecaoPorGrau = this.getNumQuestoesSortedasPorDificuldadeDaSecao(
						recuperacaoProvaTO.collectionExibicaoDificuldadeProvaTO, 
						numQuestoesAssociadasPorDificuldade, 
						recuperacaoSecaoTO.numQuestoesSorteadas
					);

					if (!listaSorteioSecaoPorGrau?.length) continue;

					mapSecoesDificuldades.set(recuperacaoSecaoTO.codSecao, listaSorteioSecaoPorGrau);
				}

				for (const exibicaoDificuldadeProvaTO of recuperacaoProvaTO.collectionExibicaoDificuldadeProvaTO) {
					colunas.push({
						titulo: exibicaoDificuldadeProvaTO.nomeDificuldade, 
						prop: (s: any) => {
							if (s && this.hasValue(s.listDificuldadeQuestoes)) {
								const countDificuldadeSecao = s.listDificuldadeQuestoes.find((sd: any) => sd.grau === exibicaoDificuldadeProvaTO.grau);
								if (countDificuldadeSecao) return countDificuldadeSecao.quantidade;
							}
							return 0;
						}, 
						classe: "cadastro-prova-dificuldade-associadas"
					});
				}

				colunas.push({titulo: this.getMsg("FP_FRONT_CadastroProvaVH_194"), prop: "numQuestoesSorteadas", classe: "cadastro-prova-dificuldade-sorteadas" });

				for (const exibicaoDificuldadeProvaTO of recuperacaoProvaTO.collectionExibicaoDificuldadeProvaTO) {

					colunas.push({
						titulo: `${exibicaoDificuldadeProvaTO.nomeDificuldade} ${exibicaoDificuldadeProvaTO.porcentagemConfigurada ? exibicaoDificuldadeProvaTO.porcentagemConfigurada : 0}%`,
						prop: (s: any) => {
							const listaSorteioSecaoPorGrau = mapSecoesDificuldades.get(s.codSecao);
							const sorteioSecaoPorGrau = listaSorteioSecaoPorGrau?.find(s => s.grau === exibicaoDificuldadeProvaTO.grau);
							return sorteioSecaoPorGrau?.numSorteadas || 0;
						},
						classe: "cadastro-prova-dificuldade-sorteadas"
					});
				}

				colunas.push({titulo: this.getMsg("FP_FRONT_CadastroProvaVH_224"), prop: (recuperacaoSecaoTO: any) => {
						return this.getObservacoesSecaoDificuldade(recuperacaoSecaoTO, recuperacaoProvaTO.collectionExibicaoDificuldadeProvaTO)
					}
				});

				const collectionRecuperacaoSecaoTO = this.recuperacaoProvaTO.secoes.filter((s: any) => s.isSorteado === true && s.numQuestoes > 0);

				collectionRecuperacaoSecaoTO.sort((a: any, b: any) => {
					if (a.nome < b.nome)
						return -1;
					if (a.nome > b.nome)
						return 1;
					return 0;
				});

				this.append(`
					<div class="row">
						<div class="col-md-12" style="padding-top: 20px">
				`);
				
				const numDificuldades = recuperacaoProvaTO.collectionExibicaoDificuldadeProvaTO.length + 1;

				await this.addTabela({
					id: "tabelaInfoDificuldades",
					colunasPrincipais: [
						[""],
						[this.getMsg("FP_FRONT_CadastroProvaVH_225"), `colspan='${numDificuldades}'`],
						[this.getMsg("FP_FRONT_CadastroProvaVH_226"), `colspan='${numDificuldades}'`],
						[""]
					],
					colunas: colunas,
					bordered: true,
					collection: collectionRecuperacaoSecaoTO,
					desabilitarDownload: true,
					ordenar: false,
					ocultarOrdernacao: true
				});
				this.append(`
						</div>
					</div>
				`);
			}

			this.append(`
				</div>
			`);
		}

		this.append("</div>");

		this.addEspacamentoHorizontal("1px");

		if (this.recuperacaoProvaTO.codProva && this.recuperacaoProvaTO.podeAlterar) {
			this.append(`
				<div class="row">
					<div id="criterios-classificacao-ranking-prova" class="col-md-12">
					</div>
				</div>
				<div class="row">
					<div responsaveis class="col-md-12">
					</div>
				</div>
			`);
		}

		this.append(`
			<div class='col-md-12'>
				<div class='btn-group' style='float: right'>
		`);

		if (this.recuperacaoProvaTO.podeAlterar) {
			if (this.recuperacaoProvaTO.codProva != null) {

				const excluir = async (arquivarSomenteProva) => {
					await this.call("CadastroProvaFCD/excluirProva", recuperacaoProvaTO.codProva, arquivarSomenteProva);
				}

				if (this.recuperacaoProvaTO.isArquivada) {
					this.addBotao({
						label: this.getMsg("FP_FRONT_CadastroProvaVH_050"), 
						onClick: async () => {
							await excluir(null);
							document.location.reload();
						}
					});
				} else {
					this.append("<div class='btn-group dropup'>");
					this.append(`<button class='btn dropdown-toggle btn-danger' data-toggle='dropdown'> ${this.getMsg("FP_FRONT_CadastroProvaVH_051")}  <span class='caret'></span></button>`);
					this.append(`<ul class='dropdown-menu pull-right'> <li> `);
					this.addBotao({
						label: this.getMsg("FP_FRONT_CadastroProvaVH_052"), 
						onClick: () => {
							this.exibirAlerta({
								msg: this.getMsg("FP_FRONT_CadastroProvaVH_024"),
								botoes: [{
									label: this.getMsg("FP_FRONT_CadastroProvaVH_027"), 
									classe: "btn-danger", 
									onClick: async () => {
										await excluir(true);
										await this.exibirProvasPorSituacao();
									}
								}]
							});
						}
					});
					this.append(`</li> <li>`);
					this.addBotao({
						label: this.getMsg("FP_FRONT_CadastroProvaVH_053"), 
						onClick: () => {
							this.exibirAlerta({
								msg: this.getMsg("FP_FRONT_CadastroProvaVH_025", recuperacaoProvaTO.numQuestoes),
								botoes: [{
									label: this.getMsg("FP_FRONT_CadastroProvaVH_026"), 
									classe: "btn-danger", 
									onClick: async () => {
										await excluir(false);
										this.exibirProvasPorSituacao();
									}
								}]
							});
						}
					});
					this.append(`</li></ul>`);
					this.append("</div>");
				}
			}
		}

		if (this.recuperacaoProvaTO.codProva != null) {

			if (this.recuperacaoProvaTO.podeAlterar) {
				this.addBotao({ 
					label: `<i class='fa fa-eye'></i> ${this.getMsg("FP_FRONT_CadastroProvaVH_054")}`, 
					onClick: async () => await loginVH.exibirAcessosDaProva(this.recuperacaoProvaTO.codProva, true)
				});
			}

			this.addBotao({
				label: this.getMsg("FP_FRONT_CadastroProvaVH_055"), 
				dica: this.getMsg("FP_FRONT_CadastroProvaVH_056"), 
				onClick: async () => {

					const listaOpcaoListaTOAplicacaoOnline = await this.call("ListagemSelecaoFCD/listarAplicacoes", {
						codProva: this.recuperacaoProvaTO.codProva,
						tipoAplicacao: TipoAplicacao.P,
					});
					
					const listaAplicacoesOnline = listaOpcaoListaTOAplicacaoOnline.map(opcaoListaTOAplicacaoOnline => {
						return {
							id: opcaoListaTOAplicacaoOnline.codAgendamento,
							nome: opcaoListaTOAplicacaoOnline.descricao,
							descricao: "#" + opcaoListaTOAplicacaoOnline.codAgendamento
						};
					});

					this.addPopup({
						titulo: this.getMsg("FP_FRONT_CadastroProvaVH_057"),
						id: "prova_copia",
						botoes: [{
							label: this.getMsg("FP_FRONT_CadastroProvaVH_058"), classe: "btn-primary", onClick: async (event) => {
								this.verificarObrigatorios(event.target);

								const copiaProvaTO: CopiaProvaTO = {
									codProva: this.recuperacaoProvaTO.codProva,
									codEmpresaDaCopia: this.getValor("codEmpresaDaCopia"),
									isCopiarSecoes: this.getValor("isCopiarSecoes"),
									isCopiarQuestoes: this.getValor("isCopiarQuestoes"),
									isCriarDuplicataDasQuestoes: this.getValor("utilizarMesmasQuestoesOuCriarDuplicata") === "CRIAR_DUPLICATA",
									isCopiarAOs: this.getValor("isCopiarAOs"),
									codsAOsParaCopiar: this.getValor("codsAOsParaCopiar"),
									isCopiarInscritosDasAOs: this.getValor("isCopiarInscritosDasAOs"),
								}

								const codProvaNova = await this.call("CadastroProvaFCD/copiarProva", copiaProvaTO);

								this.exibirAbasProva(0, codProvaNova);
							}
						}]
					});

					await this.addSelect({
						collection: this.recuperacaoProvaTO.collectionSegmentosTO,
						id: "codEmpresaDaCopia",
						classe: "col-md-12",
						label: this.getMsg("FP_FRONT_CadastroProvaVH_059"),
						valor: this.recuperacaoProvaTO.codEmpresa,
						obrigatorio: true,
						visivel: isSegmentosVisiveis,
						onChange: () => {
							this.removerTodosSelectsAvaliacao();
							this.carregarSelectAvaliacoes(null, false, false, false, null);
						}
					});

					const updateInputs = async () => {
						const isCopiarSecoes = this.getValor("isCopiarSecoes");
						if (isCopiarSecoes) {
							this.enable("isCopiarQuestoes");
						} else {
							this.setValor("isCopiarQuestoes", false);
							this.disable("isCopiarQuestoes");
						}
						const isCopiarQuestoes = this.getValor("isCopiarQuestoes");
						if (isCopiarQuestoes) {
							this.show("utilizarMesmasQuestoesOuCriarDuplicata");
							this.enable("isCopiarAOs")
						} else {
							this.hide("utilizarMesmasQuestoesOuCriarDuplicata");
							this.setValor("isCopiarAOs", false);
							this.disable("isCopiarAOs");
						}
						const isCopiarAOs = this.getValor("isCopiarAOs");
						if (isCopiarAOs) {
							await this.show("codsAOsParaCopiar", "isCopiarInscritosDasAOs");
						} else {
							await this.hide("codsAOsParaCopiar", "isCopiarInscritosDasAOs");
						}
					}

					this.addCheckbox({
						id: "isCopiarSecoes",
						label: "Copiar as seções da prova",
						classe: "col-md-12",
						valor: true,
						onChange: updateInputs
					});

					this.addCheckbox({
						id: "isCopiarQuestoes",
						label: "Copiar as questões da prova",
						classe: "col-md-12",
						valor: true,
						onChange: updateInputs
					});

					this.addRadioGroup({
						id: "utilizarMesmasQuestoesOuCriarDuplicata",
						label: this.getMsg("FP_FRONT_CadastroProvaVH_060"),
						collection: [{ id: "UTILIZAR_MESMAS_QUESTOES", descricao: this.getMsg("FP_FRONT_CadastroProvaVH_061") }, { id: "CRIAR_DUPLICATA", descricao: this.getMsg("FP_FRONT_CadastroProvaVH_062") }],
						classe: "col-md-12",
						valor: "UTILIZAR_MESMAS_QUESTOES"
					});

					if (this.hasValue(listaAplicacoesOnline)) {

						this.addCheckbox({ 
							label: this.getMsg("FP_FRONT_CadastroProvaVH_196"), 
							id: "isCopiarAOs", 
							valor: false, 
							classe: "col-md-12",
							onChange: updateInputs
						});
	
						await this.addSelect({
							collection: listaAplicacoesOnline,
							id: "codsAOsParaCopiar",
							label: this.getMsg("FP_FRONT_CadastroProvaVH_195"),
							multiplo: true,
							visivel: false,
							classe: "col-md-12",
						});
	
						this.addCheckbox({ 
							label: this.getMsg("FP_FRONT_CadastroProvaVH_197"), 
							id: "isCopiarInscritosDasAOs", 
							valor: false, 
							visivel: false,
							classe: "col-md-12",
						});
					}


					this.exibirPopups();
				}
			});
		}

		if (this.recuperacaoProvaTO.podeAlterar) {
			if (!this.recuperacaoProvaTO.isArquivada) {
				this.addBotao({
					label: this.getMsg("FP_FRONT_CadastroProvaVH_063"),
					id: "btnSalvarProva",
					onClick: () => {
						if (this.recuperacaoProvaTO.codProva) {
							this.salvarProva(this.recuperacaoProvaTO.codProva, onProvaSalva || ((recuperacaoProvaTO) => {
								this.mostrarMsgAjax(this.getMsg("FP_FRONT_CadastroProvaVH_092"), 2);
								this.exibirAbasProva(0, recuperacaoProvaTO.codProva);
							}));
						} else {
							this.salvarProva(this.recuperacaoProvaTO.codProva, (recuperacaoProvaTO) => {
								this.exibirAbasProva(1, recuperacaoProvaTO.codProva);
							});
						}
					},
					classe: "btn-primary"
				});
			}
		}

		this.append(`
						</div>
					</div>
				</div>
			</div>
		`);

		let disparouContainerAlterado = false;

		if (!this.isExibicaoEmPopup) {
			this.exibir({ isAffixIdentificacaoConteudo: true, isDispararContainerAlterado: false });
		} else {
			this.exibirPopups();
			disparouContainerAlterado = true;
		}

		if (this.recuperacaoProvaTO.codProva && this.recuperacaoProvaTO.podeAlterar && !this.recuperacaoProvaTO.isArquivada) {
			await this.listarCriteriosProva(this.recuperacaoProvaTO.collectionCriteriosClassificacaoRanking);
			await this.listarResponsaveis(this.recuperacaoProvaTO.edicaoEquipeTrabalhoTO);
			disparouContainerAlterado = true;
		}

		if (!this.recuperacaoProvaTO.podeAlterar || this.recuperacaoProvaTO.isArquivada) {
			$("input, textarea, [tipo='EDITOR_HTML']").attr("disabled", "disabled").off("click").attr("onclick", "");
		}

		if (!disparouContainerAlterado) {
			this.exibir();
		}
	}

	async editarCriterioClassificacaoRanking(codCriterio?) {
		const codProva = $("[edicao-prova]").attr("cod-prova");
		let criterio = {codCriterioClassificacaoRanking: null, tipoCriterio: null, codSecao: null};
		if (codCriterio) {
			criterio = await this.call("RankingProvaFCD/recuperarCriterioClassificaoRanking", codCriterio);
		}

		this.addPopup({
			titulo: this.getMsg("FP_FRONT_CadastroProvaVH_213"),
			id: "ranking_popup",
			botoes: [
				{
					label: codCriterio ? this.getMsg("FP_FRONT_CadastroProvaVH_058") : this.getMsg("FP_FRONT_CadastroProvaVH_212"),
					classe: "btn-primary",
					onClick: async (event) => {
						this.verificarObrigatorios(event.target);

						const criterioClassificaoRankingTO = {
							codCriterioClassificacaoRanking: codCriterio,
							tipoCriterio: this.getValor("criterio_tipoCriterio"),
							codProva: codProva,
							codSecao: this.getValor("criterio_codSecao"),
						}

						if (criterioClassificaoRankingTO.tipoCriterio == 'PONTUACAO_SECAO' && !criterioClassificaoRankingTO.codSecao) {
							await this.exibirAlerta({
								msg: this.getMsg("FP_FRONT_CadastroProvaVH_214"),
								botoes: [{
									label: "OK",
									classe: "btn-primary"
								}]
							});
							return false;
						}

						const criteriosProva = await this.call("RankingProvaFCD/salvarCriterioClassificaoRanking", criterioClassificaoRankingTO);
						this.listarCriteriosProva(criteriosProva);
						await this.hide("alerta-ranking-em-processamento");
						await this.show("alerta-ranking-desatualizado");
						this.fecharPopup("ranking_popup");

						return false;
					}
				}
			]
		});

		const secoes = await this.call("ListagemSelecaoFCD/listarSecoes", codProva);

		await this.addSelect({
			id: "criterio_tipoCriterio",
			label: this.getMsg("FP_FRONT_CadastroProvaVH_208"),
			obrigatorio: true,
			collection: await this.call("ListagemSelecaoFCD/listarCriteriosRanking"),
			classe: "col-md-12",
			onChange: async () => {
				const tipoCriterio = this.getValor("criterio_tipoCriterio");
				const htmlSecao = await this.getHtmlSecoesCriterio(secoes, tipoCriterio, criterio.codSecao);
				$('#criterio-listagem-secoes').html(htmlSecao);
				this.exibir();
			},
			valor: criterio.tipoCriterio
		});

		const html = await this.getHtmlSecoesCriterio(secoes, criterio.tipoCriterio, criterio.codSecao);

		this.append(`
			<div id='criterio-listagem-secoes'>
				${html} 
			</div>
		`);
		this.exibirPopups();
	}

	async getHtmlSecoesCriterio(secoes, tipoCriterio, codSecao) {
        const exibir = tipoCriterio == 'PONTUACAO_SECAO';

        if (exibir) {
			const htmlSecao: string[] = [];
			htmlSecao.push( this.addEspacamentoHorizontal("10px", true))

            if (!secoes || !secoes.length) {
				htmlSecao.push(this.addTexto( {
					retornarHtml: true,
					texto: this.getMsg("FP_FRONT_CadastroProvaVH_215")
				}));
				return htmlSecao.join("");
            }

			htmlSecao.push(
				await this.addSelect({
					id: "criterio_codSecao",
					label: this.getMsg("FP_FRONT_CadastroProvaVH_217"),
					obrigatorio: true,
					collection: secoes,
					classe: "col-md-12",
					valor: codSecao,
					retornarHtml: true
            	})
			);
			htmlSecao.push(
				this.addTexto({
					retornarHtml: true,
					texto: this.getMsg("FP_FRONT_CadastroProvaVH_216")
				})
			);

            return htmlSecao.join("");
        }

		return "";
    }

	async alterarPosicaoCriterioRanking(codCriterio, isSubir) {
		const criterios = await this.call("RankingProvaFCD/alterarOrdemCriterioClassificacaoRanking", codCriterio, isSubir);
		await this.listarCriteriosProva(criterios);
		await this.hide("alerta-ranking-em-processamento");
		await this.show("alerta-ranking-desatualizado");
	}

	async listarCriteriosProva(criteriosProva) {
		$("#criterios-classificacao-ranking-prova").empty();
		this.setIdTarget("criterios-classificacao-ranking-prova");

		const colunas: ColunaAddTabela[] = [
			{ titulo: this.getMsg("FP_FRONT_CadastroProvaVH_207"), prop: (criterio: any) => {
				if (criterio.ordem != 1) {
					return `
						<a
						 onclick='cadastroProvaVH.editarCriterioClassificacaoRanking(${criterio.codCriterioClassificacaoRanking})'
						 title='Editar'>
							${criterio.ordem}
						</a>
					`;
					}
					return criterio.ordem;
				}
			},
			{ titulo: this.getMsg("FP_FRONT_CadastroProvaVH_208"), prop: "descricao" },
		];

		if (criteriosProva.length > 2) {
			colunas.push(
				{ titulo: this.getMsg("FP_FRONT_CadastroProvaVH_209"), prop: (criterio: any) => {
						const btns: string[] = [];
						const isUltimo = criterio.ordem === criteriosProva.length;
						const isPrimeiro = criterio.ordem === 1;
						if (criterio.ordem > 2 && (isUltimo || !isPrimeiro)) {
							btns.push(`
								<a class='btn btn-white btn-table'
								 onclick='cadastroProvaVH.alterarPosicaoCriterioRanking(${criterio.codCriterioClassificacaoRanking}, ${false})'
								 title='Alterar ordem para ${criterio.ordem - 1}'>
									<i class='fa fa-chevron-up'></i>
								</a>
							`);
						}
						if (!isUltimo && !isPrimeiro) {
							btns.push(`
								<a class='btn btn-white btn-table'
								 onclick='cadastroProvaVH.alterarPosicaoCriterioRanking(${criterio.codCriterioClassificacaoRanking}, ${true})'
								 title='Alterar ordem para ${criterio.ordem + 1}'>
									<i class='fa fa-chevron-down'></i>
								</a>
							`);
						}
						return btns.join("");
					}
				}
			);
		}
		colunas.push({
			titulo: "", 
			prop: (criterio: any, c) => {
				if (criterio.ordem == 1 && criteriosProva.length > 1) return '';

				const excluirBtn = `
						<a class='btn btn-white btn-table hover-tr pull-right'
						 title='${this.getMsg("FP_FRONT_AmaisVH_032")}'
						 onclick='cadastroProvaVH.excluirCriterioRanking(${criterio.codCriterioClassificacaoRanking})'>
							<i class='fa fa-trash'></i>
						</a>
				`;
				if (criteriosProva.length == 1) return excluirBtn;

				const editarBtn = `
					<a class='btn btn-white btn-table hover-tr pull-right'
					 title='Editar'
					 onclick='cadastroProvaVH.editarCriterioClassificacaoRanking(${criterio.codCriterioClassificacaoRanking})'>
						<i class='fa fa-pencil'></i>
					</a>
				`;
				return `${excluirBtn} ${editarBtn}`;
			}
		})

		await this.addTabela({
			colunas: colunas,
			titulo: this.getMsg("FP_FRONT_CadastroProvaVH_210"),
			id: "tabela-criterio-ranking",
			propId: "codCriterioClassificacaoRanking",
			collection: criteriosProva,
			msgListaVazia: this.getMsg("FP_FRONT_CadastroProvaVH_211"),
			labelNovo: this.getMsg("FP_FRONT_CadastroProvaVH_212"),
			onNovo: async () => {
				await this.editarCriterioClassificacaoRanking();
			}
		})

		if (this.recuperacaoProvaTO.exibirAlertaRanking) {
			setTimeout(() => {
				this.setIdTarget("tabela-criterio-ranking_actions_buttons");
				this.addBotao({
					label: this.getMsg("FP_FRONT_CadastroProvaVH_221"),
					classe: "btn btn-sm btn-default",
					onClick: async () => {
						await cadastroProvaVH.solicitarAtualizaoRankingProva(this.recuperacaoProvaTO.codProva)
					}
				})
				this.exibir();
			}, 300);
		}

		if (criteriosProva.length && !this.recuperacaoProvaTO.exibirAlertaRanking) {
			const msg: string[] = [];
			if (!this.recuperacaoProvaTO.possuiAgendamentos) {
				msg.push(this.getMsg("FP_FRONT_CadastroProvaVH_232"));
			} else {
				if (this.recuperacaoProvaTO.possuiAgendamentoNaoEncerrado) {
					msg.push(this.getMsg("FP_FRONT_CadastroProvaVH_228"));
				}
				if (this.recuperacaoProvaTO.possuiDiscursivaNaoCorrigida) {
					msg.push(this.getMsg("FP_FRONT_CadastroProvaVH_229"));
				}
			}

			const msgAlerta = this.getMsg("FP_FRONT_CadastroProvaVH_227", msg.join(this.getMsg("FP_FRONT_CadastroProvaVH_230")));

			this.append(`
				<div class="alert alert-warning col-md-12" id="alerta-ranking-desatualizado">
					<i class="fa fa-exclamation-triangle"></i>
					${msgAlerta}
				</div>		
			`);
		}

		this.exibir();
	}

	excluirCriterioRanking(codCriterio) {
		this.exibirAlerta({
			msg: this.getMsg("FP_FRONT_CadastroProvaVH_077"),
			botoes: [
				{
					label: this.getMsg("MSG_VH_004"),
					classe: "btn-primary",
					onClick: async () => {
						const criteriosProva = await this.call("RankingProvaFCD/excluirCriterioClassificacaoRanking", codCriterio);
						await this.listarCriteriosProva(criteriosProva);
						await this.hide("alerta-ranking-em-processamento");
						await this.show("alerta-ranking-desatualizado");
					}
				},
				{label: this.getMsg("MSG_VH_005"),}
			],
		});
	}

	getObservacoesSecaoDificuldade(recuperacaoSecaoTO: any, dificuldades: any) {

		if (recuperacaoSecaoTO.numQuestoes <= recuperacaoSecaoTO.numQuestoesSorteadas) {
			return this.getMsg("FP_FRONT_CadastroProvaVH_194");
		}

		const alertas = [];
		const listDificuldadeQuestoes = recuperacaoSecaoTO.listDificuldadeQuestoes;

		for (const d of dificuldades) {
			if (d.porcentagemConfigurada > 0) {
				const alerta = `
					${this.getMsg("FP_FRONT_CadastroProvaVH_198")} <strong>${d.nomeDificuldade.toUpperCase()}</strong>
				`;
				const countDificuldadeSecao = listDificuldadeQuestoes.find((sd: any) => sd.grau === d.grau);

				if (!countDificuldadeSecao || (!countDificuldadeSecao.quantidade || countDificuldadeSecao.quantidade === 0)) {
					alertas.push(alerta);

				} else if (countDificuldadeSecao && countDificuldadeSecao.quantidade) {
					const numQuestoesDificuldade = recuperacaoSecaoTO.numQuestoesSorteadas * (d.porcentagemConfigurada / 100);

					if (countDificuldadeSecao.quantidade < numQuestoesDificuldade) {
						alertas.push(alerta);
					}
				}
			}
		}

		if (!this.hasValue(alertas)) {
			alertas.push(this.getMsg("FP_FRONT_CadastroProvaVH_199"));
		}

		return alertas.join("</br>");
	}

	getNumQuestoesSortedasPorDificuldadeDaSecao(collectionExibicaoDificuldadeProvaTO: any, numQuestoesAssociadasPorDificuldade: any, numQuestoesCfgSorteio: number): SorteioSecaoPorGrau[] {

		let somaQuestoesValidas = 0;
		
		for (const exibicaoDificuldadeProvaTO of collectionExibicaoDificuldadeProvaTO) {
			let porcentagemConfigurada = exibicaoDificuldadeProvaTO.porcentagemConfigurada;
			
			if (porcentagemConfigurada && porcentagemConfigurada > 0) {
				const numQuestoesAssociadas = numQuestoesAssociadasPorDificuldade.get(exibicaoDificuldadeProvaTO.grau);
				if (numQuestoesAssociadas) {
					somaQuestoesValidas += numQuestoesAssociadas;
				}
			}
		}
		
		if (somaQuestoesValidas < numQuestoesCfgSorteio) {
			return null;
		}
		
		let somaQuestoesSorteadas = 0;

		const listaSorteioSecaoPorGrau: SorteioSecaoPorGrau[] = [];

		for (const exibicaoDificuldadeProvaTO of collectionExibicaoDificuldadeProvaTO) {
			let porcentagemConfigurada = exibicaoDificuldadeProvaTO.porcentagemConfigurada;
			const grau = exibicaoDificuldadeProvaTO.grau;

			if (!porcentagemConfigurada || porcentagemConfigurada === 0) {

				if (somaQuestoesValidas < numQuestoesCfgSorteio) {
					porcentagemConfigurada = 1;
				} else {
					continue;
				}
			}

			const numQuestoesAssociadas = numQuestoesAssociadasPorDificuldade.get(grau);
			
			if (!numQuestoesAssociadas || numQuestoesAssociadas === 0) continue;

			const numQuestoesFracionario = numQuestoesCfgSorteio * (porcentagemConfigurada / 100);
			const numQuestoesFloor = Math.floor(numQuestoesFracionario);

			let numQuestoesDificuldadeInt = Math.min(
				numQuestoesAssociadas,
				numQuestoesFloor
			);

			somaQuestoesSorteadas += numQuestoesDificuldadeInt;
			
			const questaoSorteio: SorteioSecaoPorGrau = {
				grau: grau, 
				numSorteadas: numQuestoesDificuldadeInt,
				numQuestoesSobrando: 0,
				decimalDesconsiderado: 0
			};

			if (numQuestoesAssociadas > numQuestoesDificuldadeInt) {
				questaoSorteio.numQuestoesSobrando = numQuestoesAssociadas - numQuestoesDificuldadeInt;
				questaoSorteio.decimalDesconsiderado = numQuestoesFracionario - numQuestoesFloor;
			}

			listaSorteioSecaoPorGrau.push(questaoSorteio);
		}

		if (listaSorteioSecaoPorGrau.length < 2 || (somaQuestoesSorteadas > numQuestoesCfgSorteio)) {
			return null; // não atendeu
		}

		if (somaQuestoesSorteadas === numQuestoesCfgSorteio) {
			return listaSorteioSecaoPorGrau; // já resolveu
		}


		// DISTRIBUI NAS SEÇÕES COMEÇANDO PELO MAIOR DECIMAL DESCONSIDERADO NO FLOOR

		// ordena pelo maior decimal, se for igual pelo menor numSorteadas
		listaSorteioSecaoPorGrau.sort((a, b) => b.decimalDesconsiderado - a.decimalDesconsiderado || a.numSorteadas - b.numSorteadas);
		
		while (somaQuestoesSorteadas < numQuestoesCfgSorteio) {
			const sorteioSecaoPorGrau = listaSorteioSecaoPorGrau.find(s => s.decimalDesconsiderado > 0 && s.numQuestoesSobrando > 0);

			if (!sorteioSecaoPorGrau) break;

			somaQuestoesSorteadas++;
			sorteioSecaoPorGrau.numSorteadas++;
			sorteioSecaoPorGrau.numQuestoesSobrando--;
			sorteioSecaoPorGrau.decimalDesconsiderado = 0;
		}

		if (somaQuestoesSorteadas === numQuestoesCfgSorteio) {
			return listaSorteioSecaoPorGrau; // já resolveu
		}


		// DISTRIBUI NAS SEÇÕES NA ORDEM DE QUEM TEM MAIOR PORCENTAGEM

		let adicionouQuestao = true;
		let collectionExibicaoDificuldadeProvaTOOrdenada = JSON.parse(JSON.stringify(collectionExibicaoDificuldadeProvaTO));
		collectionExibicaoDificuldadeProvaTOOrdenada.sort((a, b) => b.porcentagemConfigurada - a.porcentagemConfigurada);
		
		while (somaQuestoesSorteadas < numQuestoesCfgSorteio && adicionouQuestao) {

			adicionouQuestao = false;
			
			for (const exibicaoDificuldadeProvaTO of collectionExibicaoDificuldadeProvaTOOrdenada) {

				if (somaQuestoesSorteadas >= numQuestoesCfgSorteio) break;

				const grau = exibicaoDificuldadeProvaTO.grau;
				let sobrando = listaSorteioSecaoPorGrau.find(s => s.grau === grau);

				if (sobrando?.numQuestoesSobrando > 0) {
					sobrando.numSorteadas++;
					sobrando.numQuestoesSobrando--;
					somaQuestoesSorteadas++;
					adicionouQuestao = true;
				}

			}
		}

		return listaSorteioSecaoPorGrau;
	}

	numeroSelectAvaliacoes = 1;

	async carregarSelectAvaliacoes(codsAvaliacoesPais, isExibirLabelComoDica, isMultiplo, isObrigatorio, onChange) {

		const collectionOpcaoListaAvaliacaoTO = await this.call("ListagemSelecaoFCD/listarAvaliacoes", this.getValor("codEmpresa"), codsAvaliacoesPais, null);

		const tiposAvaliacao = [];

		for (const opcaoListaAvaliacaoTO of collectionOpcaoListaAvaliacaoTO) {
			if (opcaoListaAvaliacaoTO.avaliacao && !tiposAvaliacao.includes(opcaoListaAvaliacaoTO.tipoAvaliacao)) {
				tiposAvaliacao.push(opcaoListaAvaliacaoTO.tipoAvaliacao);
			}
		}

		const isPrimeiroSelect = $("#divSelectsAvaliacoes select").length == 0;
		let id = "codsAvaliacoes";
		let label = null;

		if (isPrimeiroSelect) {
			label = this.getCfg("LABEL_AVALIACAO");
		} else {
			id = id + this.numeroSelectAvaliacoes++;
		}

		this.setIdTarget("divSelectsAvaliacoes");

		await this.addSelect({
			collection: collectionOpcaoListaAvaliacaoTO,
			id: id,
			classe: "col-md-12",
			label: isExibirLabelComoDica ? null : label,
			dica: isExibirLabelComoDica ? label : tiposAvaliacao.join(" ou "),
			multiplo: isMultiplo,
			obrigatorio: isObrigatorio,
			// valor: selecionados
		});

		this.setIdTarget(null);
		this.exibir();

		$("#" + id).on("change", ({ target }) => {
			this.onChangeSelectAvaliacao(target, isExibirLabelComoDica, isMultiplo, isObrigatorio, onChange);

			if (onChange != null) {
				onChange();
			}
		})
	}

	onChangeSelectAvaliacao(select, isExibirLabelComoDica, isMultiplo, isObrigatorio, onChange) {

		var tos = this.getTOSelectSelecionado(select.id);
		var ids = [];
		var possuiFilhas = false;

		if (tos && tos.length > 0) {
			for (var i = 0; i < tos.length; i++) {
				ids.push(tos[i].id);
				if (tos[i].possuiFilhas) {
					possuiFilhas = true;
				}
			}
		}

		this.removerSelectsAvaliacoesFilhos(select.id);

		if (ids.length > 0 && possuiFilhas) {
			this.carregarSelectAvaliacoes(ids, isExibirLabelComoDica, isMultiplo, isObrigatorio, onChange);
		}
	}

	removerSelectsAvaliacoesFilhos(idPai) {
		var jaAchouPai = false;

		$("#divSelectsAvaliacoes").find("select").each(function() {
			if (jaAchouPai) {
				cadastroProvaVH.removerSelectAvaliacao(this);

			} else if ($(this).attr("id") == idPai) {
				jaAchouPai = true;
			}
		});
	}

	removerSelectAvaliacao(select) {
		$(select).closest("form").remove();
	}

	removerTodosSelectsAvaliacao() {
		$("#divSelectsAvaliacoes").find("select").each(function() {
			cadastroProvaVH.removerSelectAvaliacao(this);
		});
	}

	getValorAvaliacoes() {

		var codsAvaliacoes = [];

		$($("#divSelectsAvaliacoes select").get().reverse()).each(function() {

			if (codsAvaliacoes.length > 0) {
				return;
			}

			var codsAvaliacoesUltimoSelect = cadastroProvaVH.getValor($(this).attr("id"));

			if (codsAvaliacoesUltimoSelect != null) {
				if (codsAvaliacoesUltimoSelect.push) {
					if (codsAvaliacoesUltimoSelect.length > 0) {
						codsAvaliacoes = codsAvaliacoes.concat(codsAvaliacoesUltimoSelect);
					}
				} else {
					codsAvaliacoes.push(codsAvaliacoesUltimoSelect);
				}
			}


		});

		return codsAvaliacoes;
	}

	async listarResponsaveis(edicaoEquipeTrabalhoTO) {
		$("[responsaveis]").empty();
		this.setIdTarget("[responsaveis]");

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: "", prop: (to) => {
				var hash = UtilHash.getHash(cadastroUsuarioVH.editarUsuario, to.codUsuario);
				return "<a href='" + hash + "' target='_blank'><i class='fa fa-user'></i></a>";
			}
		});
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroProvaVH_065"), prop: "nomeUsuario", classe: "descricao"});
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroProvaVH_066"), prop: "nomePerfil"});
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroProvaVH_067"), prop: (to) => to.tipoTrabalho + (to.numQuestoes != null ? `<br>${to.numQuestoes} questões` : "")});
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroProvaVH_068"), prop: "obs", classe: "descricao"});
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroProvaVH_069"), prop: (to) => {
				if (to.inicioVigencia && to.fimVigencia) {
					return this.getMsg("FP_FRONT_CadastroProvaVH_074", UtilData.toDDMMYYYYHHMM(to.inicioVigencia), UtilData.toDDMMYYYYHHMM(to.fimVigencia));
				} else if (to.inicioVigencia) {
					return this.getMsg("FP_FRONT_CadastroProvaVH_075", UtilData.toDDMMYYYYHHMM(to.inicioVigencia));
				} else if (to.fimVigencia) {
					return this.getMsg("FP_FRONT_CadastroProvaVH_076", UtilData.toDDMMYYYYHHMM(to.fimVigencia));
				} else {
					return "";
				}
			}
		});
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroProvaVH_070"), prop: "nomeUsuarioDelegou"});
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroProvaVH_071"), prop: "dataLiberacao", formato: "DD/MM/YY HH:mm"});

		await this.addTabela({
			id: "listagem_equipe_revisao",
			titulo: this.getMsg("FP_FRONT_CadastroProvaVH_064"),
			collection: edicaoEquipeTrabalhoTO.collectionListagemControleAcessoTO,
			colunas: colunas,
			propId: "idProvaControleAcesso",
			ordenacao: false,
			onEdicao: [
				null, 
				async (id) => await this.editarControleAcessoProva($("[edicao-prova]").attr("cod-prova"), id, edicaoEquipeTrabalhoTO)
			],
			msgListaVazia: this.getMsg("FP_FRONT_CadastroProvaVH_072"),
			labelNovo: this.recuperacaoProvaTO.podeAlterar ? this.getMsg("FP_FRONT_CadastroProvaVH_073") : null,
			onNovo: this.recuperacaoProvaTO.podeAlterar ? async () => {
				await this.editarControleAcessoProva($("[edicao-prova]").attr("cod-prova"), null, edicaoEquipeTrabalhoTO);
			} : null,
			onExclusao: async (botao, id) => {
				this.exibirAlerta({
					msg: this.getMsg("FP_FRONT_CadastroProvaVH_077"),
					botoes: [
						{
							label: this.getMsg("MSG_VH_004"),
							classe: "btn-primary",
							onClick: async () => {
								const edicaoEquipeTrabalhoTO = await this.call("CadastroProvaResponsavelFCD/excluirControleAcessoProva", id);
								await this.listarResponsaveis(edicaoEquipeTrabalhoTO);
							}
						},
						{label: this.getMsg("MSG_VH_005"),}
					],
				});
			}
		});

		this.exibir();
	}

	async editarControleAcessoProva(idProva, id, edicaoEquipeTrabalhoTO) {
		const edicaoControleAcessoTO = await this.call("CadastroProvaResponsavelFCD/recuperarControleAcessoProva", idProva, id);

		this.addPopup({
			titulo: this.getMsg("FP_FRONT_CadastroProvaVH_078"), id: "responsavel_popup",
			botoes: [{ label: this.getMsg("FP_FRONT_CadastroProvaVH_079"), classe: "btn-primary", onClick: async (event) => {
				this.verificarObrigatorios(event.target);

				var salvamentoControleAcessoTO = {
					idProvaControleAcesso: edicaoControleAcessoTO.idProvaControleAcesso,
					codUsuario: this.getValor("responsavel_codUsuario"),
					inicioVigencia: this.getValor("responsavel_inicio"),
					fimVigencia: this.getValor("responsavel_fim"),
					tipoTrabalho: this.getValor("responsavel_tipoTrabalho"),
					numQuestoes: this.getValor("responsavel_numQuestoes"),
					obs: this.getValor("responsavel_observacoes"),
					codProva: idProva
				}

				salvamentoControleAcessoTO.obs = await UtilHtml.enviarImagensParaArmazenamento(salvamentoControleAcessoTO.obs);

				const edicaoEquipeTrabalhoTO = await this.call("CadastroProvaResponsavelFCD/salvarControleAcessoProva", salvamentoControleAcessoTO);
				this.fecharPopup("responsavel_popup");
				this.listarResponsaveis(edicaoEquipeTrabalhoTO);

				return false;
			}}]
		});

		this.addTexto(this.getMsg("FP_FRONT_CadastroProvaVH_080"));

		await this.addSelect({
			collection: edicaoControleAcessoTO.collectionUsuarios,
			id: "responsavel_codUsuario",
			label: this.getMsg("FP_FRONT_CadastroProvaVH_081"),
			obrigatorio: true
		});

		this.addEspacamentoHorizontal("1px");

		await this.addSelect({
			collection: edicaoEquipeTrabalhoTO.listaOpcaoListaTOTiposTrabalho,
			id: "responsavel_tipoTrabalho",
			label: this.getMsg("FP_FRONT_CadastroProvaVH_082"),
			obrigatorio: true,
			valor: edicaoControleAcessoTO.tipoTrabalho,
		});

		this.addCampoTexto({
			id: "responsavel_numQuestoes",
			label: this.getMsg("FP_FRONT_CadastroProvaVH_083"),
			valor: edicaoControleAcessoTO.numQuestoes,
			maxLength: "3",
			mascara: "9?99",
			sufixo: this.getMsg("FP_FRONT_CadastroProvaVH_084")
		})

		this.addEditorHTML({
			id: "responsavel_observacoes",
			label: this.getMsg("FP_FRONT_CadastroProvaVH_085"),
			classe: "col-md-12",
			valor: edicaoControleAcessoTO.obs
		});

		this.addTexto(this.getMsg("FP_FRONT_CadastroProvaVH_086"));

		this.addEspacamentoHorizontal("2px");

		this.append(`<div class='form-group' style='width: 100%'><label>${this.getMsg("FP_FRONT_CadastroProvaVH_089")}:</label><span style='line-height: 31px; float: left'>${this.getMsg("FP_FRONT_CadastroProvaVH_090")}</span>`);

		this.addCampoTexto({
			id: "responsavel_inicio",
			dica: this.getMsg("FP_FRONT_CadastroProvaVH_087"),
			valor: edicaoControleAcessoTO.inicioVigencia,
			tipo: "DATAHORA",
			classe: "col-md-4",
			css: "margin-left: 0px",
			obrigatorio: true
		});

		this.append("<span style='line-height: 31px; float: left;'> e </span>");

		this.addCampoTexto({
			id: "responsavel_fim",
			dica: this.getMsg("FP_FRONT_CadastroProvaVH_088"),
			valor: edicaoControleAcessoTO.fimVigencia,
			tipo: "DATAHORA",
			classe: "col-md-4",
		})

		this.append("</div>");

		this.addEspacamentoHorizontal("1px");

		this.addTexto(this.getMsg("FP_FRONT_CadastroProvaVH_091"));

		this.exibirPopups();
	}

	async exibirEsconderDificuldades() {
		if (this.getValor("isDificuldadesConfiguradas")) {
			await this.show("tabelaDificuldades");
		} else {
			await this.hide("tabelaDificuldades");
		}
	}

	async salvarProva(codProva, onProvaSalva) {

		this.verificarObrigatorios();

		const salvamentoProvaTO: any = {
			codProva: codProva,
			codEmpresa: this.getValor("codEmpresa"),
			titulo: this.getValor("prova_nome"),
			tituloPublico: this.getValor("prova_nome_publico"),
			isDescartarJaRespondidas: this.getValor("isDescartarJaRespondidas"),
			textoCertificado: this.getValor("textoCertificado"),
			numeracaoInicial: this.getValor("numeracaoInicial"),
			codsAvaliacoes: this.getValor("codsAvaliacoes"),
			descricaoInstrucoes: this.getValor("descricaoInstrucoes"),
			textoAnexo: this.getValor("textoAnexo"),
			cfgs: {}
		}

		salvamentoProvaTO.cfgs["IS_RESULTADO_POR_MENCAO"] = this.getValor("IS_RESULTADO_POR_MENCAO");

		if (this.getValor("qtdErrosAnulamCerta") != 0 || this.getValor("qtdErrosAnulamCerta") != "0") {
			salvamentoProvaTO.qtdErrosAnulamCerta = this.getValor("qtdErrosAnulamCerta")
		}

		if (UtilNumero.stringToFloat(this.getValor("porcentagemPontuacaoMinima")) > 0) {
			salvamentoProvaTO.porcentagemPontuacaoMinima = UtilNumero.stringToFloat(this.getValor("porcentagemPontuacaoMinima"))
		}
		if (UtilNumero.stringToFloat(this.getValor("notaMaxima")) > 0) {
			salvamentoProvaTO.notaMaxima = UtilNumero.stringToFloat(this.getValor("notaMaxima"))
		}

		var isDificuldadesConfiguradas = this.getValor("isDificuldadesConfiguradas")

		if (isDificuldadesConfiguradas) {

			salvamentoProvaTO.listSalvamentoDificuldadesProvaTO = []
			var somaPorcentagens = 0

			for (var i = 0; i < this.recuperacaoProvaTO.collectionExibicaoDificuldadeProvaTO.length; i++) {
				const exibicaoDificuldadeProvaTO = this.recuperacaoProvaTO.collectionExibicaoDificuldadeProvaTO[i];
				const grauDificuldade = exibicaoDificuldadeProvaTO.grauDificuldade;

				if (!this.validarCamposObrigatorios("porcentagemDificuldade" + grauDificuldade)) {
					return
				}

				const salvamentoDificuldadesProvaTO = {
					grauDificuldade: grauDificuldade,
					porcentagemConfigurada: Number(this.getValor("porcentagemDificuldade" + grauDificuldade))
				}

				somaPorcentagens = somaPorcentagens + salvamentoDificuldadesProvaTO.porcentagemConfigurada

				salvamentoProvaTO.listSalvamentoDificuldadesProvaTO.push(salvamentoDificuldadesProvaTO)
			}

			if (somaPorcentagens != 100.0) {
				this.exibirAlerta({ msg: this.getMsg("FP_FRONT_CadastroProvaVH_093", somaPorcentagens) });
				return
			}
		}

		await this.enviarImagensParaArmazenamento(salvamentoProvaTO);

		const recuperacaoProvaTO = await this.call("CadastroProvaFCD/salvarProva", salvamentoProvaTO);

		if (onProvaSalva) {
			onProvaSalva(recuperacaoProvaTO);

		} else {

			if (recuperacaoProvaTO.secoes == null || recuperacaoProvaTO.secoes.length == 0) {

				this.limpar(true);

				this.addSubtitulo(this.getMsg("FP_FRONT_CadastroProvaVH_094"));

				this.addTexto(this.getMsg("FP_FRONT_CadastroProvaVH_095"));

				this.addEspacamentoHorizontal("20px");
				this.addBotao({ label: this.getMsg("FP_FRONT_CadastroProvaVH_096"), onClick: () => this.encaminharParaCadastroQuestoes() });
				this.addBotao({ label: this.getMsg("FP_FRONT_CadastroProvaVH_097"), css: "float: right", onClick: () => cadastroSecaoVH.exibirSecoes(recuperacaoProvaTO.codProva) });

				this.exibir();

			} else {
				cadastroSecaoVH.exibirSecoes(recuperacaoProvaTO.codProva);
			}
		}
	}

	async enviarImagensParaArmazenamento(salvamentoProvaTO) {
		const div = AmaisVH.criarDivMsgAjax(this.getMsg("FP_FRONT_CadastroProvaVH_098"));

		salvamentoProvaTO.descricaoInstrucoes = await UtilHtml.enviarImagensParaArmazenamento(salvamentoProvaTO.descricaoInstrucoes);
		salvamentoProvaTO.textoAnexo = await UtilHtml.enviarImagensParaArmazenamento(salvamentoProvaTO.textoAnexo);
		salvamentoProvaTO.textoCertificado = await UtilHtml.enviarImagensParaArmazenamento(salvamentoProvaTO.textoCertificado);

		div.remove();
		
		return salvamentoProvaTO;
	}

	encaminharParaCadastroQuestoes() {

		this.limpar()

		this.addSubtitulo("Cadastro de questões")

		var html = `
			<p>&nbsp;</p>
			<label for='passo3'>
				${this.getMsg("FP_FRONT_CadastroProvaVH_099")}
			</label>
		`;

		this.addBotaoDireito("OK", () => cadastroQuestaoVH.exibirAbasQuestao(1, null));

		this.append(html)

		this.exibir();
	}

	async exibirAbasProva(numAbaESubaba: string | number, codProva: number, codQuestao?: number, idProvaResponsavel?: number, irParaBuscaQuestoes?: boolean, codAgendamento?: number, isVoltarAoSalvar?: boolean, isExibirAplicacoesArquivadas?: boolean) {

		if (numAbaESubaba != null && codProva == null) {
			codProva = Number(numAbaESubaba);
			this.exibirAbasProva(0, codProva, codQuestao, idProvaResponsavel, irParaBuscaQuestoes, codAgendamento, isVoltarAoSalvar, isExibirAplicacoesArquivadas);
			return;
		}

		if (codProva == null) {
			this.logger.warn("Chamada sem codProva.");
			return;
		}

		if (numAbaESubaba == null) {
			this.exibirAbasProva(2, codProva, codQuestao, idProvaResponsavel, irParaBuscaQuestoes, codAgendamento, isVoltarAoSalvar, isExibirAplicacoesArquivadas);
			return;
		}

		codQuestao = codQuestao || null;
		idProvaResponsavel = idProvaResponsavel || null;
		irParaBuscaQuestoes = irParaBuscaQuestoes || null;
		codAgendamento = codAgendamento || null;

		UtilHash.registrarHistorico(this.exibirAbasProva, numAbaESubaba, codProva, codQuestao, idProvaResponsavel, irParaBuscaQuestoes, codAgendamento, isVoltarAoSalvar, isExibirAplicacoesArquivadas);
		
		const abasHabilitadasEdicaoProvaTO = await this.call("CadastroProvaFCD/recuperarAbasHabilitadas", codProva, idProvaResponsavel);

		if (abasHabilitadasEdicaoProvaTO == null) {
			return;
		}

		this.limpar();
		this.setTitulo(abasHabilitadasEdicaoProvaTO.tituloProva);

		if (abasHabilitadasEdicaoProvaTO.listaItemListaTOHierarquiaAvaliacao) {
			const listaAvHierarquia = abasHabilitadasEdicaoProvaTO.listaItemListaTOHierarquiaAvaliacao;
			let linksPastas;
			if(listaAvHierarquia.length > 1){
				linksPastas = listagemProvasPorPastasVH.getLinksHierarquiaAvaliacaoMultipla(listaAvHierarquia);
			}
			else if(listaAvHierarquia.length > 0){
				linksPastas = listagemProvasPorPastasVH.getLinksHierarquiaAvaliacao(listaAvHierarquia[0]);
			}

			this.setSubtitulo(linksPastas);
		}

		const abas = [];
		let onProvaSalva = null;

		if (isVoltarAoSalvar) {
			onProvaSalva = (recuperacaoProvaTO) => {
				UtilHash.voltar();
			}
		}

		abas.push({
			id: "provaAbaConfiguracoes",
			label: `
				<span class="wiz wiz-ok">
					<i class="fa fa-gear"></i>
				</span>
				${this.getMsg("FP_FRONT_CadastroProvaVH_104")}
			`,
			onClick: () => this.exibirInformacoesBasicas(abasHabilitadasEdicaoProvaTO.codProva, false, onProvaSalva),
			habilitada: abasHabilitadasEdicaoProvaTO.isAbaConfiguracoesVisivel
		});
		abas.push({
			id: "provaAbaQuestoes",
			label: `
				<span class="wiz ${abasHabilitadasEdicaoProvaTO.possuiQuestoes ? "wiz-ok" : "wiz-obrigatorio"}">
					<i class="fa fa-list-ul"></i>
				</span>
				${this.getMsg("FP_FRONT_CadastroProvaVH_103")}
			`,
			onClick: async () => {
				const edicaoQuestoesProvaTO: EdicaoQuestoesProvaTO = await this.call("CadastroProvaFCD/listarSecoesPorProva", codProva);

				const pills = [{
					label: this.getMsg("FP_FRONT_CadastroProvaVH_233"),
					onClick: async () => cadastroSecaoVH.exibirSecoesProva(edicaoQuestoesProvaTO, irParaBuscaQuestoes)
				}, {
					label: this.getMsg("FP_FRONT_CadastroProvaVH_234"),
					onClick: async () => cadastroSecaoVH.exibirBuscaDeQuestoes(edicaoQuestoesProvaTO.codProva)
				}, {
					label: this.getMsg("FP_FRONT_CadastroProvaVH_235"),
					onClick: async () => cadastroSecaoVH.exibirCriacaoQuestao(edicaoQuestoesProvaTO.codProva, edicaoQuestoesProvaTO.idProvaResponsavel)
				}];
				
				this.addPills({
					id: "prova_correcoes",
					subAbaAtiva: numAbaESubaba,
					pills: pills,
				});

				this.exibir();
			},
			habilitada: abasHabilitadasEdicaoProvaTO.isAbaQuestoesVisivel
		});
		abas.push({
			id: "provaAbaRevisao",
			label: `
				<span class="wiz ${abasHabilitadasEdicaoProvaTO.possuiRevisoes ? "wiz-ok" : "wiz-obrigatorio"}">
					<i class="fa fa-list-ol"></i>
				</span>
				${abasHabilitadasEdicaoProvaTO.tituloAbaRevisao}
			`,
			onClick: () => revisaoProvaVH.editar(abasHabilitadasEdicaoProvaTO.codProva, codQuestao),
			habilitada: abasHabilitadasEdicaoProvaTO.isAbaRevisaoVisivel
		});
		abas.push({
			id: "provaAbaOnline",
			label: `
				<span class="wiz ${abasHabilitadasEdicaoProvaTO.possuiAOs ? "wiz-ok" : "wiz-obrigatorio"}">
					<i class="fa fa-laptop"></i>
				</span>
				${this.getCfg("LABEL_MENU_AGENDAMENTOS", "Online")}
			`,
			onClick: () => agendamentoProvaVH.listarAOsProva(abasHabilitadasEdicaoProvaTO.codProva, codAgendamento, isExibirAplicacoesArquivadas),
			habilitada: abasHabilitadasEdicaoProvaTO.isAbaOnlineVisivel
		});
		abas.push({
			id: "provaAbaImpressa",
			label: `
				<span class="wiz ${abasHabilitadasEdicaoProvaTO.possuiAIs ? "wiz-ok" : "wiz-obrigatorio"}">
					<i class="fa fa-print"></i>
				</span>
				${this.getMsg("FP_FRONT_CadastroProvaVH_102")}
			`,
			onClick: () => aplicacaoImpressaVH.listarAplicacoesImpressas(abasHabilitadasEdicaoProvaTO.codProva, codAgendamento, isExibirAplicacoesArquivadas),
			habilitada: abasHabilitadasEdicaoProvaTO.isAbaImpressaVisivel
		});
		abas.push({
			id: "provaAbaCorrecoes",
			label: `
				<span class="wiz ${abasHabilitadasEdicaoProvaTO.possuiCorrecoes ? "wiz-ok" : "wiz-obrigatorio"}">
					<i class="fa fa-check-square-o"></i>
				</span>
				${this.getMsg("FP_FRONT_CadastroProvaVH_101")}
			`,
			onClick: () => {
				const pills = [{
					label: this.getMsg("FP_FRONT_CadastroProvaVH_105"),
					onClick: async () => await relNotasEPontuacoesVH.exibirRelNotasEPontuacoes(abasHabilitadasEdicaoProvaTO.codProva, abasHabilitadasEdicaoProvaTO.possuiTentativasOuReaplicacao, codAgendamento)
				}, {
					label: this.getMsg("FP_FRONT_CadastroProvaVH_106"),
					onClick: async () => await relatorioProvasVH.exibirRelatorioMarcacaoAvaliados(abasHabilitadasEdicaoProvaTO.codProva, abasHabilitadasEdicaoProvaTO.possuiTentativasOuReaplicacao)
				}, {
					label: this.getMsg("FP_FRONT_CadastroProvaVH_107"),
					onClick: async () => await relatorioProvasVH.exibirRelatorioDeDesempenhoDetalhado(abasHabilitadasEdicaoProvaTO.codProva, abasHabilitadasEdicaoProvaTO.possuiTentativasOuReaplicacao)
				}];
				if (abasHabilitadasEdicaoProvaTO.possuiQuestionarios) {
					pills.push({
						label: this.getMsg("FP_FRONT_CadastroProvaVH_187"),
						onClick: async () => await questVH.exibirQuestionarios(abasHabilitadasEdicaoProvaTO.codProva)
					})
				}

				this.addPills({
					id: "prova_correcoes",
					subAbaAtiva: numAbaESubaba,
					pills: pills
				});

				this.exibir();
			},
			habilitada: abasHabilitadasEdicaoProvaTO.isAbaCorrecoesVisivel
		});

		this.addAbas({ abas, numAbaAtiva: numAbaESubaba, id: "abas_provas" });

		this.exibir();
		this.exibirWizs();

		if (abasHabilitadasEdicaoProvaTO.isArquivada || abasHabilitadasEdicaoProvaTO.isQuestoesBloqueadas) {
			setTimeout(() => {
				const labelExcluido = abasHabilitadasEdicaoProvaTO.isArquivada ? `<span class='label label-default'><i class='fa fa-trash-o'></i> ${this.getMsg("FP_FRONT_CadastroProvaVH_108")}</span>` : "";
				const labelBloqueada = abasHabilitadasEdicaoProvaTO.isQuestoesBloqueadas ? `<span class='label label-default'><i class='fa fa-lock'></i> ${this.getMsg("FP_FRONT_CadastroProvaVH_109")}</span>` : "";
				$("#corpo").prepend(`
					<div class='row'>
						<div class='col-md-12'>
							${labelExcluido} 
							${labelBloqueada} 
						</div>
					</div>
				`);
			}, 500);
		}
	}

	exibirWizs() {
		// exibirLinha
		const wizs = Array.from(document.querySelectorAll(".wiz")).filter(wiz => $(wiz).is(":visible"));

		if (wizs.length < 2) return;
		
		const wizInicial: any = wizs[0];
		const wizFinal = wizs[wizs.length - 1];

		let linha = {
			width: wizFinal.getBoundingClientRect().x - wizInicial.getBoundingClientRect().x,
			left: Math.round(wizInicial.offsetLeft + (wizInicial.offsetWidth / 2)),
		}

		$(`
			<div class="wiz-linha" style="width: ${linha.width}px; margin-left: ${linha.left}px;">
			</div>
		`).prependTo("nav#abas_provas");
		
		// marcar botoes
		$(".wiz").each((i, wiz) => $(wiz).closest("a").addClass("wiz-botao"));
		$(".wiz-ok").each((i, wiz) => $(wiz).closest("a").addClass("wiz-botao-ok"));
	}

	exibirAbasPorSituacao(numAba, buscaTextual) {

		UtilHash.registrarHistorico(this.exibirAbasPorSituacao, numAba, buscaTextual);

		numAba = numAba || 0;
		this.filtrosProvasTO.buscaTextual = buscaTextual;

		this.limpar();

		this.setTitulo(this.getMsg("FP_FRONT_CadastroProvaVH_110"));

		const abas = [{ 
			label: `<i class='fa fa-wrench'></i> ${this.getMsg("FP_FRONT_CadastroProvaVH_112")}`, 
			onClick: async () => {

				const exibicaoProvasEmConstrucaoTO = await this.call(
					"CadastroProvaFCD/recuperarProvasEmConstrucao", 
					this.getFiltrosProvasTO(true)
				);

				this.limpar(true);

				this.append("<div class='d-flex justify-end' style='margin-bottom: 15px'>");
				this.addBotaoBusca("filtrosProvas");
				this.append("</div>");

				await this.addFiltrosProvas({
					isExibirAvaliacoes: exibicaoProvasEmConstrucaoTO.isExibirAvaliacoes, 
					onFiltrosAlterados: () => {
						this.exibirAbasPorSituacao(0, this.filtrosProvasTO.buscaTextual);
					}
				});

				await this.exibirListagemProvasEmConstrucao(exibicaoProvasEmConstrucaoTO);

				this.addEspacamentoHorizontal("1px");

				this.addBotao({ 
					label: this.getMsg("FP_FRONT_CadastroProvaVH_111"), 
					onClick: () => UtilHash.voltar() 
				});

				this.exibir();
			}
		}];

		if (this.isAnalista()) {

			abas.push({ 
				label: `<i class='fa fa-check'></i> ${this.getMsg("FP_FRONT_CadastroProvaVH_113")}`, 
				onClick: async () => {
					const exibicaoProvasRevisadasTO: ExibicaoProvasRevisadasTO = await this.call(
						"CadastroProvaFCD/recuperarProvasRevisadas", 
						this.getFiltrosProvasTO(true)
					);
					this.limpar(true);
					this.append("<div class='d-flex justify-end' style='margin-bottom: 15px'>");
					this.addBotaoBusca("filtrosProvas");
					this.append("</div>");

					await this.addFiltrosProvas({
						isExibirAvaliacoes: exibicaoProvasRevisadasTO.isExibirAvaliacoes, 
						onFiltrosAlterados: () => {
							this.exibirAbasPorSituacao(1, this.filtrosProvasTO.buscaTextual);
						}
					});
					await this.exibirListagemProvasRevisadas(exibicaoProvasRevisadasTO);
					this.addEspacamentoHorizontal("1px");
					this.addBotao({ label: this.getMsg("FP_FRONT_CadastroProvaVH_114"), onClick: () => UtilHash.voltar() });
					this.exibir();
				}
			}, { 
				label: `<i class='fa fa-clock-o'></i> ${this.getMsg("FP_FRONT_CadastroProvaVH_116")}`, 
				onClick: async () => {

					const exibicaoProvasEmAplicacaoTO: ExibicaoProvasEmAplicacaoTO = await this.call(
						"CadastroProvaFCD/recuperarProvasEmAplicacao", 
						this.getFiltrosProvasTO(true)
					);

					this.limpar(true);

					this.append("<div class='d-flex justify-end' style='margin-bottom: 15px'>");
					this.addBotaoBusca("filtrosProvas");
					this.append("</div>");

					await this.addFiltrosProvas({
						isExibirAvaliacoes: exibicaoProvasEmAplicacaoTO.isExibirAvaliacoes, 
						onFiltrosAlterados: () => {
							this.exibirAbasPorSituacao(2, this.filtrosProvasTO.buscaTextual);
						}
					});

					await this.exibirListagemProvasEmAplicacao(exibicaoProvasEmAplicacaoTO);

					this.addEspacamentoHorizontal("1px");
					this.addBotao({ label: this.getMsg("FP_FRONT_CadastroProvaVH_115"), onClick: () => UtilHash.voltar() });
					this.exibir();
				}
			}, { 
				label: `<i class='fa fa-globe'></i> ${this.getMsg("FP_FRONT_CadastroProvaVH_117")}`, 
				onClick: async () => {

					const exibicaoProvasAplicadasTO: ExibicaoProvasAplicadasTO = await this.call(
						"CadastroProvaFCD/recuperarProvasAplicadas", 
						this.getFiltrosProvasTO(true)
					);

					this.limpar(true);

					this.append("<div class='d-flex justify-end' style='margin-bottom: 15px'>");
					this.addBotaoBusca("filtrosProvas");
					this.append("</div>");
					
					await this.addFiltrosProvas({
						isExibirAvaliacoes: exibicaoProvasAplicadasTO.isExibirAvaliacoes, 
						onFiltrosAlterados: () => {
							this.exibirAbasPorSituacao(3, this.filtrosProvasTO.buscaTextual);
						}
					});

					await this.exibirListagemProvasAplicadas(exibicaoProvasAplicadasTO);

					this.addEspacamentoHorizontal("1px");
					this.addBotao({ 
						label: this.getMsg("FP_FRONT_CadastroProvaVH_118"), 
						onClick: () => UtilHash.voltar() 
					});

					this.exibir();
				}
			});
		}

		this.addAbas({ 
			abas, 
			numAbaAtiva: numAba 
		});

		this.exibir();
	}

	async exibirListagemProvasEmConstrucao(exibicaoProvasEmConstrucaoTO: ExibicaoProvasEmConstrucaoTO) {

		const colunas: ColunaAddTabela[] = [];

		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroProvaVH_119"), prop: (listagemProvasEmAplicacaoTO) => {
				var href = UtilHash.getHash(this.exibirAbasProva, 2, listagemProvasEmAplicacaoTO.codProva);
				return "<a href='" + href + "'>" + listagemProvasEmAplicacaoTO.titulo + "</a>" + (listagemProvasEmAplicacaoTO.isArquivada ? `<br><span class='label label-default'>${this.getMsg("FP_FRONT_CadastroProvaVH_127")}</span>` : "");
			}, classe: "descricao"
		});
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroProvaVH_120"), prop: (listagemProvasEmConstrucaoTO) => {
				if (!listagemProvasEmConstrucaoTO.numPessoasNaEquipe) return;

				if (listagemProvasEmConstrucaoTO.numPessoasNaEquipe > 2) {
					return "<span title='" + UtilString.escaparAspas(listagemProvasEmConstrucaoTO.nomesNaEquipe) + "'>"
						+ listagemProvasEmConstrucaoTO.numPessoasNaEquipe
						+ this.getMsg("FP_FRONT_CadastroProvaVH_126") + "</span>";
				} else {
					return listagemProvasEmConstrucaoTO.nomesNaEquipe;
				}
			}
		});
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroProvaVH_121"), prop: (listagemProvasEmConstrucaoTO) => {
				var h = [];
				if (listagemProvasEmConstrucaoTO.numQuestoesAprovadas) {
					h.push(this.getMsg("FP_FRONT_CadastroProvaVH_122") + listagemProvasEmConstrucaoTO.numQuestoesAprovadas);
				}
				if (listagemProvasEmConstrucaoTO.numQuestoesReprovadas) {
					h.push(this.getMsg("FP_FRONT_CadastroProvaVH_123") + listagemProvasEmConstrucaoTO.numQuestoesReprovadas);
				}
				if (listagemProvasEmConstrucaoTO.numQuestoesRemovidas) {
					h.push(this.getMsg("FP_FRONT_CadastroProvaVH_124") + listagemProvasEmConstrucaoTO.numQuestoesRemovidas);
				}
				if (listagemProvasEmConstrucaoTO.numQuestoesPendentes) {
					h.push(this.getMsg("FP_FRONT_CadastroProvaVH_125") + listagemProvasEmConstrucaoTO.numQuestoesPendentes);
				}
				if (h.length) {
					return "<span style='white-space:nowrap;'>" + h.join("<br>") + "</span>";
				} else {
					return "-";
				}
			}
		});
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroProvaVH_128"), prop:(listagemProvasEmConstrucaoTO) => {
				var possuiAcesso = UtilAuth.possuiAcesso(TipoFuncionalidade.AUDITORIA_PROVA);
				var h = [];
				if (listagemProvasEmConstrucaoTO.dataParaConclusao) h.push(this.getMsg("FP_FRONT_CadastroProvaVH_129") + UtilData.toDDMMYYYY(listagemProvasEmConstrucaoTO.dataParaConclusao));
				if (listagemProvasEmConstrucaoTO.diasRestantes) h.push(this.getMsg("FP_FRONT_CadastroProvaVH_130") + listagemProvasEmConstrucaoTO.diasRestantes);
				var atividade = this.getMsg("FP_FRONT_CadastroProvaVH_131") + (listagemProvasEmConstrucaoTO.numAcoes || 0);
				if (possuiAcesso) {
					h.push("<a onclick='loginVH.exibirAcessosDaProva(" + listagemProvasEmConstrucaoTO.codProva + ", true)'>" + atividade + "</a>");
				} else {
					h.push(atividade);
				}
				return "<span style='white-space: nowrap;'>" + h.join("<br>") + "</span>";
			}
		});

		await this.addTabela({
			collection: exibicaoProvasEmConstrucaoTO.listaListagemProvasEmConstrucaoTO,
			propId: "codProva",
			colunas: colunas,
			ordenar: false,
			paginacao: {
				paginacaoTO: exibicaoProvasEmConstrucaoTO.paginacaoTO,
				onCarregarPagina: !exibicaoProvasEmConstrucaoTO ? null : async (paginacaoTO) => {
			
					this.filtrosProvasTO.paginacaoTO = paginacaoTO;
			
					const exibicaoProvasEmConstrucaoTO = await this.call("CadastroProvaFCD/recuperarProvasEmConstrucao", this.filtrosProvasTO);

					return exibicaoProvasEmConstrucaoTO.listaListagemProvasEmConstrucaoTO;
				}
			}
		});
	}

	async exibirListagemProvasRevisadas(exibicaoProvasRevisadasTO: ExibicaoProvasRevisadasTO) {

		const colunas: ColunaAddTabela[] = [];
		colunas.push({
			titulo: this.getMsg("FP_FRONT_CadastroProvaVH_132"), 
			prop:(listagemProvasEmAplicacaoTO) => {
				const href = UtilHash.getHash(this.exibirAbasProva, 3, listagemProvasEmAplicacaoTO.codProva);
				return "<a href='" + href + "'>" + listagemProvasEmAplicacaoTO.titulo + "</a>" + (listagemProvasEmAplicacaoTO.isArquivada ? `<br><span class='label label-default'>${this.getMsg("FP_FRONT_CadastroProvaVH_135")}</span>` : "");
			}, 
			classe: "descricao"
		});
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroProvaVH_133"), prop: "dataEncerramentoRevisao", formato: "DD/MM/YY"});
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroProvaVH_134"), prop: "numQuestoes"});

		await this.addTabela({
			collection: exibicaoProvasRevisadasTO.listaListagemProvasRevisadasTO,
			propId: "codProva",
			colunas: colunas,
			ordenar: false,
			paginacao: {
				paginacaoTO: exibicaoProvasRevisadasTO.paginacaoTO,
				onCarregarPagina: !exibicaoProvasRevisadasTO ? null : async (paginacaoTO) => {
					this.filtrosProvasTO.paginacaoTO = paginacaoTO;
			
					const exibicaoProvasRevisadasTO: ExibicaoProvasRevisadasTO = await this.call(
						"CadastroProvaFCD/recuperarProvasRevisadas", 
						this.filtrosProvasTO
					);

					return exibicaoProvasRevisadasTO.listaListagemProvasRevisadasTO;
				}
			}
		});
	}

	async exibirListagemProvasEmAplicacao(exibicaoProvasEmAplicacaoTO: ExibicaoProvasEmAplicacaoTO) {

		const colunas: ColunaAddTabela[] = [{
			titulo: this.getMsg("FP_FRONT_CadastroProvaVH_136"), 
			prop: (listagemProvasEmAplicacaoTO) => {
				let link;
				
				if (listagemProvasEmAplicacaoTO.soVisualizaCorrecao) {
					link = listagemProvasEmAplicacaoTO.titulo;
				} else {
					if (listagemProvasEmAplicacaoTO.tipoAplicacao == TipoAplicacao.F) {
						const href = UtilHash.getHash(this.exibirAbasProva, 4, listagemProvasEmAplicacaoTO.codProva);
						link = "<a href='" + href + "'>" + listagemProvasEmAplicacaoTO.titulo + "</a>";
					} else {
						const href = UtilHash.getHash(this.exibirAbasProva, 3, listagemProvasEmAplicacaoTO.codProva);
						link = "<a href='" + href + "'>" + listagemProvasEmAplicacaoTO.titulo + "</a>";
					}
				}
				if (listagemProvasEmAplicacaoTO.isArquivada) {
					link = `${link}<br><span class='label label-default'>${this.getMsg("FP_FRONT_CadastroProvaVH_138")}</span>`
				}

				let tipoAplicacao = this.getMsg("FP_FRONT_CadastroProvaVH_143");

				if (listagemProvasEmAplicacaoTO.tipoAplicacao === TipoAplicacao.E) {
					tipoAplicacao = this.getMsg("FP_FRONT_CadastroProvaVH_139");
				} else if (listagemProvasEmAplicacaoTO.tipoAplicacao === TipoAplicacao.P) {
					tipoAplicacao = this.getMsg("FP_FRONT_CadastroProvaVH_140");
				} else if (listagemProvasEmAplicacaoTO.tipoAplicacao === TipoAplicacao.I) {
					tipoAplicacao = this.getMsg("FP_FRONT_CadastroProvaVH_142");
				} else if (listagemProvasEmAplicacaoTO.tipoAplicacao === TipoAplicacao.F) {
					tipoAplicacao = this.getMsg("FP_FRONT_CadastroProvaVH_141");
				}

				return `${link}<br>${tipoAplicacao}`;
			}, classe: "descricao"
		}, {
			titulo: this.getCfg("LABEL_TURMA"), 
			prop: "nomesTurmas"
		}, {
			titulo: this.getMsg("FP_FRONT_CadastroProvaVH_137"), 
			prop: (listagemProvasEmAplicacaoTO) => {
				const h = [];

				if (listagemProvasEmAplicacaoTO.numAvaliados != null) h.push(this.getCfg("LABEL_MENU_CADASTRO_ALUNO") + ": " + listagemProvasEmAplicacaoTO.numAvaliados);
				if (listagemProvasEmAplicacaoTO.numAguardando != null) h.push(this.getMsg("FP_FRONT_CadastroProvaVH_144") + listagemProvasEmAplicacaoTO.numAguardando);
				if (listagemProvasEmAplicacaoTO.dataTermino) h.push(this.getMsg("FP_FRONT_CadastroProvaVH_145") + UtilData.toDDMMYYYYHHMM(listagemProvasEmAplicacaoTO.dataTermino));

				return "<span style='white-space: nowrap;'>" + h.join("<br>") + "</span>";
			}
		}, {
			titulo: "", 
			prop: (listagemProvasEmAplicacaoTO) => {
				const b = [];

				if (listagemProvasEmAplicacaoTO.codAgendamentoParaEncerramento && !listagemProvasEmAplicacaoTO.soVisualizaCorrecao) {
					b.push(this.addBotao({
						label: "<i class='fa fa-stop'></i>",
						dica: this.getMsg("FP_FRONT_CadastroProvaVH_146"),
						classe: "btn-sm",
						onClick: () => agendamentoProvaVH.finalizarAgendamento(listagemProvasEmAplicacaoTO.codAgendamentoParaEncerramento),
						retornarHtml: true
					}));
				}

				if (listagemProvasEmAplicacaoTO.numRecursos) {
					b.push(this.addBotao({
						label: "<i class='fa fa-legal'></i>",
						dica: this.getMsg("FP_FRONT_CadastroProvaVH_147"),
						classe: "btn-sm",
						onClick: () => recursoVH.listarRecursosDaProva(null, listagemProvasEmAplicacaoTO.codProva),
						retornarHtml: true
					}));
				}

				if (listagemProvasEmAplicacaoTO.possuiDiscursiva) {
					b.push(this.addBotao({
						label: "<i class='fa fa-pencil-square'></i>",
						dica: this.getMsg("FP_FRONT_CadastroProvaVH_148"),
						classe: "btn-sm",
						onClick: () => acompanhamentoCDsVH.exibirDetalhamentoCorrecoesProva(listagemProvasEmAplicacaoTO.codProva, listagemProvasEmAplicacaoTO.titulo),
						retornarHtml: true
					}));
				}

				if (listagemProvasEmAplicacaoTO.numFeedbacks) {
					b.push(this.addBotao({
						label: "<i class='fa fa-comment'></i>",
						dica: this.getMsg("FP_FRONT_CadastroProvaVH_149"),
						classe: "btn-sm",
						onClick: () => feedbacksVH.listarFeedbacks(listagemProvasEmAplicacaoTO.codAgendamentoParaEncerramento),
						retornarHtml: true
					}));
				}

				if ([TipoAplicacao.E, TipoAplicacao.P].includes(listagemProvasEmAplicacaoTO.tipoAplicacao) && listagemProvasEmAplicacaoTO.numAvaliados > 0) {
					b.push(this.addBotao({
						label: "<i class='fa fa-tachometer-alt'></i>",
						dica: this.getMsg("FP_FRONT_CadastroProvaVH_150"),
						classe: "btn-sm",
						href: UtilHash.getHash(dashboardProvaVH.exibirPorProva, listagemProvasEmAplicacaoTO.codProva),
						retornarHtml: true
					}));
				}

				if (b.length) {
					return `<div class='btn-group-vertical'>${b.join("")}</div>`;

				} else {
					return "";
				}
			}
		}];

		await this.addTabela({
			collection: exibicaoProvasEmAplicacaoTO.listaListagemProvasEmAplicacaoTO,
			propId: "codProva",
			colunas: colunas,
			ordenar: false,
			paginacao: {
				paginacaoTO: exibicaoProvasEmAplicacaoTO.paginacaoTO,
				onCarregarPagina: !exibicaoProvasEmAplicacaoTO ? null : async (paginacaoTO: any) => {
					this.filtrosProvasTO.paginacaoTO = paginacaoTO;
			
					const exibicaoProvasEmAplicacaoTO: ExibicaoProvasEmAplicacaoTO = await this.call(
						"CadastroProvaFCD/recuperarProvasEmAplicacao", 
						this.filtrosProvasTO
					);

					return exibicaoProvasEmAplicacaoTO.listaListagemProvasEmAplicacaoTO;
				},
			}
		});
	}

	async exibirListagemProvasAplicadas(exibicaoProvasAplicadasTO: ExibicaoProvasAplicadasTO) {

		const colunas: ColunaAddTabela[] = [{
			titulo: this.getMsg("FP_FRONT_CadastroProvaVH_151"), 
			prop: (listagemProvasAplicadasTO) => {
				const href = UtilHash.getHash(this.exibirAbasProva, "5A", listagemProvasAplicadasTO.codProva);
				return "<a href='" + href + "'>" + listagemProvasAplicadasTO.titulo + "</a>" + (listagemProvasAplicadasTO.isArquivada ? `<br><span class='label label-default'>${this.getMsg("FP_FRONT_CadastroProvaVH_155")}</span>` : "");
			}, 
			classe: "descricao"
		}, {
			titulo: this.getMsg("FP_FRONT_CadastroProvaVH_152"), 
			prop: "numRespostas"
		}, {
			titulo: this.getMsg("FP_FRONT_CadastroProvaVH_153"), 
			prop: "mediaGeral", 
			formato: "porcentagem"
		}, {
			titulo: this.getMsg("FP_FRONT_CadastroProvaVH_154"), 
			prop: "dataUltimaAplicacao", 
			formato: "DD/MM/YY HH:mm"
		}, {
			titulo: "", 
			prop: (listagemProvasAplicadasTO) => {
				var b = [];

				b.push(this.addBotao({
					label: "<i class='fa fa-table'></i>",
					dica: this.getMsg("FP_FRONT_CadastroProvaVH_156"),
					classe: "btn-sm",
					onClick: () => cadastroProvaVH.exibirAbasProva("5C", listagemProvasAplicadasTO.codProva),
					retornarHtml: true
				}));

				if (listagemProvasAplicadasTO.numRecursos) {
					b.push(this.addBotao({
						label: "<i class='fa fa-legal'></i>",
						dica: this.getMsg("FP_FRONT_CadastroProvaVH_157"),
						classe: "btn-sm",
						onClick: () => recursoVH.listarRecursosDaProva(null, listagemProvasAplicadasTO.codProva),
						retornarHtml: true
					}));
				}

				if (listagemProvasAplicadasTO.possuiDiscursiva) {
					b.push(this.addBotao({
						label: "<i class='fa fa-pencil-square'></i>",
						dica: this.getMsg("FP_FRONT_CadastroProvaVH_158"),
						classe: "btn-sm",
						onClick: () => acompanhamentoCDsVH.exibirDetalhamentoCorrecoesProva(listagemProvasAplicadasTO.codProva, listagemProvasAplicadasTO.titulo),
						retornarHtml: true
					}));
				}

				if (listagemProvasAplicadasTO.numFeedbacks) {
					b.push(this.addBotao({
						label: "<i class='fa fa-comment'></i>",
						dica: this.getMsg("FP_FRONT_CadastroProvaVH_159"),
						classe: "btn-sm",
						onClick: () => feedbacksVH.listarFeedbacks(null, listagemProvasAplicadasTO.codProva),
						retornarHtml: true
					}));
				}

				if (b.length) {
					return `<div class='btn-group-vertical'>${b.join("")}</div>`;

				} else {
					return "";
				}
			}
		}];

		await this.addTabela({
			collection: exibicaoProvasAplicadasTO.listaListagemProvasAplicadasTO,
			propId: "codProva",
			colunas: colunas,
			ordenar: false,
			paginacao: {
				paginacaoTO: exibicaoProvasAplicadasTO.paginacaoTO,
				onCarregarPagina: async (paginacaoTO) => {
					this.filtrosProvasTO.paginacaoTO = paginacaoTO;
					const exibicaoProvasAplicadasTO: ExibicaoProvasAplicadasTO = await this.call(
						"CadastroProvaFCD/recuperarProvasAplicadas", 
						this.filtrosProvasTO
					);
					return exibicaoProvasAplicadasTO.listaListagemProvasAplicadasTO;
				},
			},
		});
	}

	async addFiltrosProvas({isExibirAvaliacoes, onFiltrosAlterados}) {
		
		let style = "display: none";

		if (this.filtrosProvasTO.possuiFiltroInformado()) {
			style = "";
		}

		this.append(`
			<div id="filtrosProvas" style="${style}">
		`);

		this.abrirAbaAccordion({ 
			titulo: this.getMsg("FP_FRONT_CadastroProvaVH_160"), 
			aberta: true
		});

		this.addFormulario({ classe: "col-md-12" });
		this.append("<div class='row'>");

		if (this.getIsAmbienteSegmentado()) {
			await this.addSelect({
				id: "codsEmpresasBusca",
				dica: this.getMsg("FP_FRONT_CadastroProvaVH_161"),
				multiplo: true,
				valor: this.filtrosProvasTO.codsEmpresasBusca,
				classe: "col-xs-12 col-md-3 col-lg-3",
				loadOpcoesBack: {
					endpoint: "ListagemSelecaoFCD/listarSegmentos",
					numMinimoCaracteres: 0,
				},
				onChange: () => {
					this.filtrosProvasTO.codsEmpresasBusca = this.getValor("codsEmpresasBusca");
					onFiltrosAlterados();
				}
			});
		}

		if (isExibirAvaliacoes) {
			await this.addSelect({
				id: "codsAvaliacoes",
				dica: this.getCfg("LABEL_AVALIACAO"),
				multiplo: true,
				valor: this.filtrosProvasTO.codsAvaliacoes,
				classe: "col-xs-12 col-md-3 col-lg-3 fp-small-business-hidden",
				loadOpcoesBack: {
					endpoint: "ListagemSelecaoFCD/buscarAvaliacoes",
					numMinimoCaracteres: 0,
				},
				onChange: () => {
					this.filtrosProvasTO.codsAvaliacoes = this.getValor("codsAvaliacoes");
					onFiltrosAlterados();
				}
			});
		}

		if (this.isAdministrador()) {

			await this.addSelect({
				id: "codsUsuariosCriadores",
				dica: this.getMsg("FP_FRONT_CadastroProvaVH_183"),
				multiplo: true,
				classe: "col-xs-12 col-md-3 col-lg-3",
				valor: this.filtrosProvasTO.codsUsuariosCriadores,
				loadOpcoesBack: {
					endpoint: "ListagemSelecaoFCD/listarUsuariosComPermissao",
					payload: {
						tipoFuncionalidade: "PROVA_CADASTRO",
					},
					numMinimoCaracteres: 3,
				},
				onChange: () => {
					this.filtrosProvasTO.codsUsuariosCriadores = this.getValor("codsUsuariosCriadores");
					onFiltrosAlterados();
				}
			});
		}
		
		this.addCampoTexto({ 
			dica: this.getMsg("FP_FRONT_CadastroProvaVH_162"), 
			classe: "col-xs-12 col-md-3 col-lg-3", 
			id: "busca", 
			valor: this.filtrosProvasTO.buscaTextual, 
			prefixo: "<i class='fa fa-sm fa-search'></i> ", 
			onEnter: () => {
				this.filtrosProvasTO.buscaTextual = this.getValor("busca");
				onFiltrosAlterados();
			}
		});

		this.addCamposPeriodo({
			idInicio: "inicioPeriodoCadastroBusca",
			idFim: "fimPeriodoCadastroBusca",
			classe: 'col-md-4',
			valorInicio: this.filtrosProvasTO.periodoCadastro[0],
			valorFim: this.filtrosProvasTO.periodoCadastro[1],
			dica: this.getMsg("FP_FRONT_CadastroProvaVH_204"),
			onChange: () => {
				this.filtrosProvasTO.periodoCadastro[0] = this.getValor("inicioPeriodoCadastroBusca");
				this.filtrosProvasTO.periodoCadastro[1] = this.getValor("fimPeriodoCadastroBusca");
				onFiltrosAlterados();
			}
		});

		this.addCamposPeriodo({
			idInicio: "inicioPeriodoAtualizacaoBusca",
			idFim: "fimPeriodoAtualizacaoBusca",
			classe: 'col-md-4',
			valorInicio: this.filtrosProvasTO.periodoAtualizacao[0],
			valorFim: this.filtrosProvasTO.periodoAtualizacao[1],
			dica: this.getMsg("FP_FRONT_CadastroProvaVH_205"),
			onChange: () => {
				this.filtrosProvasTO.periodoAtualizacao[0] = this.getValor("inicioPeriodoAtualizacaoBusca");
				this.filtrosProvasTO.periodoAtualizacao[1] = this.getValor("fimPeriodoAtualizacaoBusca");
				onFiltrosAlterados();
			}
		});

		this.addCheckbox({ 
			label: this.getMsg("FP_FRONT_CadastroProvaVH_164"), 
			id: "isExibirArquivados", 
			valor: this.filtrosProvasTO.isExibirArquivados, 
			classe: "col-xs-12 col-md-3 col-lg-3", 
			onChange: () => {
				this.filtrosProvasTO.isExibirArquivados = this.getValor("isExibirArquivados");
				onFiltrosAlterados();
			}
		});
		
		if (this.getIsAmbienteImportacaoBancoQuestoes()) {
			this.addBotao({ label: this.getMsg("FP_FRONT_CadastroProvaVH_165"), onClick: () => relatoriosImportacaoVH.listarProvas() });
		}

		this.append("</div>");
		this.fecharFormulario();
		this.fecharAbaAccordion();
		this.fecharGrupoAccordion();
		
		this.append("</div>");
	}

	exibirCadastroNovaProva() {

		UtilHash.registrarHistorico(this.exibirCadastroNovaProva);

		this.limpar();
		this.setTitulo(this.getMsg("FP_FRONT_CadastroProvaVH_166"));
		this.exibirInformacoesBasicas();
	}

	gerarHtmlBotoesProvas(possuiAIsAtivas: boolean): string {
		
		let botoesCriarProva = []

		if (UtilAuth.possuiAcesso(TipoFuncionalidade.PROVA_CADASTRO)) {
			botoesCriarProva.push(`
				<li>
					<a id="btnCadastrarNovaProva" href='/#/cpnp' title='${this.getMsg("FP_FRONT_CadastroProvaVH_168")}'>
						<i class='fa fa-plus-circle'></i> 
						${this.getMsg("FP_FRONT_CadastroProvaVH_169")}
					</a>
				</li>
			`);
		}

		if (UtilAuth.possuiAcesso(TipoFuncionalidade.PROVA_IMPORTACAO)) {
			botoesCriarProva.push(`<li class="btn-importacao-prova-inicio"><a href='/#/ipiq' title='${this.getMsg("FP_FRONT_CadastroProvaVH_170")}'><i class='fa fa-upload'></i> ${this.getMsg("FP_FRONT_CadastroProvaVH_171")}</a></li>`);
		}

		if (this.isCfgHabilitada("HABILITAR_IMPORTACAO_CARTAO_RESPOSTA") && UtilAuth.possuiAcesso(TipoFuncionalidade.PROVA_CADASTRO_VAZIA)) {
			botoesCriarProva.push(`<li><a href='/#/iccp' title='${this.getMsg("FP_FRONT_CadastroProvaVH_172")}'><i class='fa fa-barcode'></i> ${this.getMsg("FP_FRONT_CadastroProvaVH_173")}</a></li>`);
		}

		
		const h = [];

		if (possuiAIsAtivas && (this.isAnalista() || this.isSecretaria()) && (UtilAuth.possuiAcesso(TipoFuncionalidade.CORRECAO_FR_ENVIO) || UtilAuth.possuiAcesso(TipoFuncionalidade.CORRECAO_FR_REL))) {
			h.push(this.addBotao({
				label: `<i class='fa fa-check-square-o'></i> ${this.getMsg("FP_FRONT_CadastroProvaVH_167")} `, classe: "btn-primary", retornarHtml: true, onClick: () => {
					importacaoCartaoRespostaVH.exibirEnvioFRs(0);
				}
			}));
		}

		if (botoesCriarProva.length > 0) {
			h.push(`
				<div class='btn-group' role='group'>
					<button id="btnDropdownOpcoesNovaProva" type='button' class='btn btn-default btn-success dropdown-toggle' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
						<i class='fa fa-plus-circle'></i> 
						${this.getMsg("FP_FRONT_CadastroProvaVH_174")} 
						<span class='caret'></span>
					</button>
					<ul class='dropdown-menu dropdown-menu-right'>
						${botoesCriarProva.join("")}
						<li></li>
					</ul>
				</div>
			`);
		}

		if (this.isAdministrador()) {
			h.push(this.addBotao({ 
				label: this.getCfg("LABEL_AVALIACAO_PLURAL"), 
				retornarHtml: true, 
				onClick: async () => await cadastroAvaliacaoVH.exibirListaAvaliacoes(),
				classe: "btn-default fp-small-business-hidden",
			}));
		}

		h.push(this.addBotaoBusca("filtrosProvas", {retornarHtml: true}));

		return h.join("");
	}

	async solicitarAtualizaoRankingProva(codProva) {
		await this.call("RankingProvaFCD/atualizarRankingProva", codProva);

		await this.hide("alerta-ranking-desatualizado");
		await this.show("alerta-ranking-em-processamento");

		await this.exibirAlerta({
			titulo: `<i class='fa fa-exclamation-triangle'></i> ${this.getMsg("FP_FRONT_CadastroProvaVH_222")}`,
			msg: this.getMsg("FP_FRONT_CadastroProvaVH_223"),
			botoes: [
				{
					label: "Ok",
					classe: "btn-primary",
				}
			]
		})
	}

	exibirMsgAlertaRanking(isRankingDesatualizado: boolean, isRankingEmProcessamento: boolean, codProva: number) {
		this.append(`
				<div class="alert alert-warning col-md-12" id="alerta-ranking-desatualizado" style="display: ${isRankingDesatualizado ? '' : 'none'}">
					<i class="fa fa-exclamation-triangle"></i> 
					${this.getMsg("FP_FRONT_CadastroProvaVH_218")}
					<br/> <br/>
					<a class="btn btn-warning" onclick="cadastroProvaVH.solicitarAtualizaoRankingProva(${codProva})">${this.getMsg("FP_FRONT_CadastroProvaVH_220")}</a>
				</div>
				
				<div class="alert alert-info col-md-12" id="alerta-ranking-em-processamento" style="display: ${isRankingEmProcessamento ? '' : 'none'}">
					<i class="fa fa-info-circle"></i>
					${this.getMsg("FP_FRONT_CadastroProvaVH_219")}
				</div>
		`);
		this.isAdministrador()
	}

	async inserirNomesSecoesNoTituloDaProva() {

		const codsProvas = $("div.detalhesProva")
			.map((_, div) => Number(div.getAttribute("codProva")))
			.get();

		const mapaExibicaoDosDetalhesDaProvaNaListagemProvasTO = await this.call("CadastroProvaFCD/carregarDetalhesDasProvas", codsProvas);
	
		$("div.detalhesProva").each((_, div) => {

			const codProva = Number(div.getAttribute("codProva"));
			const listaDescricaoSecao = mapaExibicaoDosDetalhesDaProvaNaListagemProvasTO[codProva];
			const html = [];

			html.push("<table>");

			for (let descricaoSecao of listaDescricaoSecao) {
				html.push(`<tr><td>${descricaoSecao}</td></tr>`);
			}

			html.push("</table>");

			$(div).html(html.join(""));
		})
	}
}

class FiltrosProvasTO {

	buscaTextual: string;
	codsAvaliacoes: number[];
	codsEmpresasBusca: number[];
	codsUsuariosCriadores: number[];
	isExibirArquivados: boolean;
	periodoCadastro: string[] = [];
	periodoAtualizacao: string[] = [];
	paginacaoTO: PaginacaoTO;

	constructor() { }
	
	possuiFiltroInformado(): boolean {
		return UtilString.hasValue(this.buscaTextual)
			|| this.isExibirArquivados === true
			|| UtilString.hasValue(this.codsEmpresasBusca)
			|| UtilString.hasValue(this.codsAvaliacoes)
			|| UtilString.hasValue(this.codsUsuariosCriadores)
			|| UtilString.hasValue(this.periodoCadastro[0]) 
			|| UtilString.hasValue(this.periodoCadastro[1])
			|| UtilString.hasValue(this.periodoAtualizacao[0])
			|| UtilString.hasValue(this.periodoAtualizacao[1])
			;
	}

	setBuscaTextual(busca: string) {
		if (UtilString.isEmpty(busca)) return;
		this.buscaTextual = busca;
	}
}

const cadastroProvaVH = new CadastroProvaVH();

type CopiaProvaTO = {
	codProva: number;
	codEmpresaDaCopia: number;
	isCopiarSecoes: boolean;
	isCopiarQuestoes: boolean;
	isCriarDuplicataDasQuestoes: boolean;
	isCopiarAOs: boolean;
	codsAOsParaCopiar: number[];
	isCopiarInscritosDasAOs: boolean;
}
type SorteioSecaoPorGrau = {
	grau: number; 
	numSorteadas: number;
	numQuestoesSobrando: number;
	decimalDesconsiderado: number;
}
type ExibicaoListaProvasTO = {
	paginacaoTO: PaginacaoTO;
	collectionListagemProvaTO: ListagemProvaTO[];
}
type ListagemProvaTO = {		
	codProva: number;
	titulo: string;
	dataCadastro: string;
	numQuestoes: number;
	codEmpresaDaProva: number;
	numUsuariosAvaliados: number;
	numUsuariosAguardando: number;
	isArquivada: boolean;
	possuiCorrecaoDeDiscursivas: boolean;
	possuiRecursos: boolean;
	segmento: string;
	nomeUsuarioCriador: string;
}
type ExibicaoProvasPorSituacaoTO = {
	exibicaoProvasEmConstrucaoTO: ExibicaoProvasEmConstrucaoTO;
	exibicaoProvasRevisadasTO: ExibicaoProvasRevisadasTO;
	exibicaoProvasEmAplicacaoTO: ExibicaoProvasEmAplicacaoTO;
	exibicaoProvasAplicadasTO: ExibicaoProvasAplicadasTO;
	isExibirAvaliacoes: boolean;
	possuiAIsAtivas: boolean;
	numTotalProvasEmConstrucao: number;
	numTotalProvasRevisadas: number;
	numTotalProvasEmAplicacao: number;
	numTotalProvasAplicadas: number;
}
type ExibicaoProvasEmConstrucaoTO = {
	listaListagemProvasEmConstrucaoTO: any[]; // ListagemProvasEmConstrucaoTO[];
	isExibirAvaliacoes: boolean;
	paginacaoTO: PaginacaoTO;
}
type ExibicaoProvasRevisadasTO = {
	listaListagemProvasRevisadasTO: any[]; // ListagemProvasRevisadasTO[];
	isExibirAvaliacoes: boolean;
	paginacaoTO: PaginacaoTO;
}
type ExibicaoProvasEmAplicacaoTO = {
	listaListagemProvasEmAplicacaoTO: any[]; // ListagemProvasEmAplicacaoTO[];
	isExibirAvaliacoes: boolean;
	paginacaoTO: PaginacaoTO;
}
type ExibicaoProvasAplicadasTO = {
	listaListagemProvasAplicadasTO: any[]; // ListagemProvasAplicadasTO[];
	isExibirAvaliacoes: boolean;
	paginacaoTO: PaginacaoTO;
}
type ExibicaoFiltrosListaProvasTO = {
	possuiBancoQuestoes: boolean;
	isExibirAvaliacoes: boolean;
	possuiAIsAtivas: boolean;
	collectionSegmentosTO: any[];
}