class CronoFaceMatchDuranteProvaVH {
    worker: Worker;
    callback: Function;
    milisegundosRestantes: number;

    cronoListener: Function = (msDecorridos) => {
        this.handleCronoTick(msDecorridos);
    }

    iniciarCronometro(milisegundosRestantes, callback: Function) {
        this.pararCronometro();

        this.milisegundosRestantes = milisegundosRestantes;
        this.callback = callback;

        UtilCrono.adicionarListener(this.cronoListener);
    }

    async handleCronoTick(msDecorridos: number) {

        if (msDecorridos == null || msDecorridos < 1 || !this.callback) return;

		if (this.milisegundosRestantes > 0) {
			this.milisegundosRestantes -= msDecorridos;
		} else {
            this.pararCronometro();
			const c = this.callback;
			this.callback = null;
            await c();
        }        
    }

    pararCronometro() {
        UtilCrono.removerListener(this.cronoListener);
    }
}
