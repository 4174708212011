class CadastroEmpresaVH extends AmaisVH {
	
	codEmpresaCopiaConfiguracao: any;
	exibicaoCadastroEmpresaTO: any;
	edicaoGrupoConfigTO: any;
	codEmpresa: any;
	codEmpresaAtual: number;
	idsPerfisBusca: number[];
	buscaTextual: string;
	listagemPerfis: any
	listagemSegmentos: any[] = []
	codsEmpresasBusca: any

	constructor() {
		super(CadastroEmpresaVH.name);
		this.addOperacaoParaHash("cele", this.listarEmpresas);
		this.addOperacaoParaHash("cecg", this.exibirGruposConfiguracao);
		this.addOperacaoParaHash("cefc", this.exibirFiltroCobranca);	
	}

	edicaoConfiguracaoEmpresaTO = null;

	async listarEmpresas(codEmpresaPai?: number, buscaTextual?: string, inicioPeriodoCadastro?: string, fimPeriodoCadastro?: string, isSomenteRaizes?: boolean, isExibirExcluidos?: boolean, isExibirDesabilitados?: boolean) {

		if (this.isEmpty(codEmpresaPai) && this.isEmpty(isSomenteRaizes)) {
			isSomenteRaizes = true;
		}

		const listagemEmpresasTO = await this.call("CadastroEmpresaFCD/recuperarEmpresas", {
			codEmpresaPaiParaListarFilhos: codEmpresaPai,
			buscaTextual,
			inicioPeriodoCadastro,
			fimPeriodoCadastro,
			isSomenteRaizes,
			isExibirExcluidos,
			isExibirDesabilitados
		});

		UtilHash.registrarHistorico(this.listarEmpresas, codEmpresaPai, buscaTextual, inicioPeriodoCadastro, fimPeriodoCadastro);

		this.limpar();

		this.setIdTarget("tituloSuperiorDireito");
		this.append("<div class='btn-group' style='float: right'>");

		this.addBotao({
			texto: `<i class='fa fa-plus-circle'></i> ${this.getMsg("FP_FRONT_CadastroEmpresaVH_001")}`, classe: "btn-success", onClick: () => {
				this.exibirGruposConfiguracao(0, null);
			}
		});

		if (this.isAdministradorSistema()) {
			this.addBotao({ 
				texto: this.getMsg("FP_FRONT_CadastroEmpresaVH_002"), 
				onClick: () => this.exibirFiltroCobranca() 
			});
			this.addBotao({ 
				texto: this.getMsg("FP_FRONT_CadastroEmpresaVH_003"), 
				onClick: () => monitoramentoVH.exibirInfosGerais() 
			});
			this.addBotao({ 
				texto: "<i class='fa fa-user-circle'></i>", 
				dica: this.getMsg("FP_FRONT_DashboardUsuarioVH_001"), 
				onClick: () => dashboardUsuarioVH.exibirUsuariosOnline() 
			});
			this.addBotao({
				texto: "<i class='fa fa-list'></i>",
				dica: this.getMsg("FP_FRONT_ControleFilasProcessamentoVH_001"),
				onClick: () => controleFilasProcessamentoVH.exibirFilas()
			});
		}

		this.addBotaoBusca("filtrosEmpresas");

		this.append("</div>");
		this.setIdTarget(null);

		const isVisivel = buscaTextual || inicioPeriodoCadastro || fimPeriodoCadastro || isExibirExcluidos;

		this.append(`<div id='filtrosEmpresas' ${isVisivel ? "" : "style='display: none'"}>`);
		this.abrirAbaAccordion({
			titulo: this.getMsg("MSG_VH_001"), 
			aberta: true
		});

		this.addFormulario();

		this.addCamposPeriodo({
			idInicio: "inicioPeriodoCadastroBusca",
			idFim: "fimPeriodoCadastroBusca",
			dica: this.getMsg("FP_FRONT_CadastroEmpresaVH_137"),
			valorInicio: inicioPeriodoCadastro,
			valorFim: fimPeriodoCadastro,
			onChange: async () => await this.listarEmpresas(codEmpresaPai, buscaTextual, this.getValor("inicioPeriodoCadastroBusca"), this.getValor("fimPeriodoCadastroBusca"), isSomenteRaizes)
		});

		this.addCampoTexto({
			id: "buscaTextualListagem",
			sufixo: "<i class='fa fa-sm fa-search'></i> ",
			dica: this.getMsg("MSG_VH_002"),
			valor: buscaTextual,
			onEnter: async () => await this.listarEmpresas(codEmpresaPai, this.getValor("buscaTextualListagem"), inicioPeriodoCadastro, fimPeriodoCadastro, isSomenteRaizes),
		});

		this.addCheckbox({
			id: "isSomenteRaizes",
			label: this.getMsg("FP_FRONT_CadastroEmpresaVH_138"),
			valor: isSomenteRaizes,
			classe: "col-md-2",
			onChange: async () => await this.listarEmpresas(null, this.getValor("buscaTextualListagem"), inicioPeriodoCadastro, fimPeriodoCadastro, this.getValor("isSomenteRaizes"), isExibirExcluidos, isExibirDesabilitados),
		});

		this.addCheckbox({
			id: "isExibirExcluidos",
			label: this.getMsg("FP_FRONT_CadastroEmpresaVH_128"),
			valor: isExibirExcluidos,
			classe: "col-md-2",
			onChange: async () => await this.listarEmpresas(null, this.getValor("buscaTextualListagem"), inicioPeriodoCadastro, fimPeriodoCadastro, isSomenteRaizes, this.getValor("isExibirExcluidos"), isExibirDesabilitados),
		});

		this.addCheckbox({
			id: "isExibirDesabilitados",
			label: this.getMsg("FP_FRONT_CadastroEmpresaVH_132"),
			valor: isExibirDesabilitados,
			classe: "col-md-2",
			onChange: async () => await this.listarEmpresas(null, this.getValor("buscaTextualListagem"), inicioPeriodoCadastro, fimPeriodoCadastro, isSomenteRaizes, isExibirExcluidos, this.getValor("isExibirDesabilitados")),
		})

		this.fecharFormulario();
		this.fecharAbaAccordion();
		this.fecharGrupoAccordion();
		this.append("</div>");

		this.setTitulo(listagemEmpresasTO.tituloDaTela);

		const colunas: ColunaAddTabela[] = [{
			titulo: this.getMsg("FP_FRONT_CadastroEmpresaVH_134"), 
			prop: (itemListaTOEmpresas) => {
				if (itemListaTOEmpresas.isExcluido) {
					return itemListaTOEmpresas.nome + "<br><span class='label label-default'>" + this.getMsg("FP_FRONT_CadastroEmpresaVH_135") + "</span>";
				} else {
					return itemListaTOEmpresas.nome;
				}
			}, 
			classe: "descricao"
		},{
			titulo: this.getMsg("FP_FRONT_CadastroEmpresaVH_133"), 
			prop: (itemListaTOEmpresas: any) => {
				if (!itemListaTOEmpresas.descricao) return "";
				return `<a href="${itemListaTOEmpresas.descricao}" target="_blank">${itemListaTOEmpresas.descricao}</a>`;
			}
		},{
			titulo: this.getMsg("FP_FRONT_CadastroEmpresaVH_136"), 
			prop: (itemListaTOEmpresas) => {
				if (!itemListaTOEmpresas.numFilhos) return "";
				return `
					<a class="badge" onclick="cadastroEmpresaVH.listarEmpresas(${itemListaTOEmpresas.id})">
						${itemListaTOEmpresas.numFilhos} 
						<i class="fa fa-angle-right"></i>
					</a>
				`;
			}
		}];

		await this.addTabela({
			collection: listagemEmpresasTO.collectionItemListaTOEmpresas,
			colunas: colunas,
			hashEdicao: UtilHash.getHash(this.exibirGruposConfiguracao, 0),
			// metodoMostrarFilhos: async (link, itemListaTOEmpresa, cfgs) => {
			// 	return (await this.call("CadastroEmpresaFCD/recuperarEmpresas", itemListaTOEmpresa.id)).collectionItemListaTOEmpresas;
			// },
			paginacao: {
				paginacaoTO: listagemEmpresasTO.paginacaoTO,
				onCarregarPagina: async (paginacaoTO) => {
					const listagemEmpresasTO = await this.call("CadastroEmpresaFCD/recuperarEmpresas", { 
						paginacaoTO,
						codEmpresaPaiParaListarFilhos: codEmpresaPai,
						buscaTextual,
						inicioPeriodoCadastro,
						fimPeriodoCadastro,
						isSomenteRaizes,
						isExibirExcluidos
					});
					return listagemEmpresasTO.collectionItemListaTOEmpresas;
				}
			}
		});

		this.exibir();
	}

	async alterarEmpresa(codEmpresa) {

		const exibicaoCadastroEmpresaTO = await this.call("CadastroEmpresaFCD/recuperarEmpresa", codEmpresa);

		this.codEmpresaAtual = exibicaoCadastroEmpresaTO.codEmpresa;

		this.limpar(true);

		this.setTitulo(exibicaoCadastroEmpresaTO.nomeFantasia || this.getMsg("FP_FRONT_CadastroEmpresaVH_004"));
		this.setSubtitulo(this.getMsg("FP_FRONT_CadastroEmpresaVH_005"));

		if (exibicaoCadastroEmpresaTO.isExcluido) {
			this.append(`
				<div class="col-md-12">
					<span class="label label-default">
						CADASTRO EXCLUÍDO
					</span>
					<br>
					<br>
				</div>
			`);
		}
		await this.addSelect({
			id: "codEmpresaSuperior",
			label: this.getMsg("FP_FRONT_CadastroEmpresaVH_006"),
			valor: exibicaoCadastroEmpresaTO.codEmpresaSuperior,
			loadOpcoesBack: {
				endpoint: "ListagemSelecaoFCD/listarSegmentosPermitemFiliais",
				numMinimoCaracteres: 3,
			},
		});

		this.addCampoTexto({
			label: this.getMsg("FP_FRONT_CadastroEmpresaVH_007"),
			id: "nomeFantasia",
			valor: exibicaoCadastroEmpresaTO.nomeFantasia
		});

		this.addCampoTexto({
			label: this.getMsg("FP_FRONT_CadastroEmpresaVH_008"),
			id: "sigla",
			maxLength: 45,
			valor: exibicaoCadastroEmpresaTO.sigla,
			habilitado: exibicaoCadastroEmpresaTO.codEmpresa == null,
			onChange: () => {
				const valor = this.getValor("sigla");
				if (valor) {
					this.setValor("sigla", valor.replace(/\W/g, ""));
				}
			}
		});

		this.addCampoTexto({
			label: this.getMsg("FP_FRONT_CadastroEmpresaVH_009"),
			id: "hostDominio",
			valor: exibicaoCadastroEmpresaTO.hostDominio,
			onChange: () => {
				const valor = this.getValor("hostDominio");
				if (valor) {
					this.setValor("hostDominio", valor.toLowerCase());
				}
			}
		});

		this.addCampoTexto({
			label: this.getMsg("FP_FRONT_CadastroEmpresaVH_010"),
			id: "codigoSistemaOrigem",
			valor: exibicaoCadastroEmpresaTO.codigoSistemaOrigem
		});

		this.addCampoExibicao({ label: this.getMsg("FP_FRONT_CadastroEmpresaVH_014"), valor: UtilData.toDDMMYYYY(exibicaoCadastroEmpresaTO.dataCadastro)});

		if (exibicaoCadastroEmpresaTO.caminhoArquivoLogomarca != null) {
			this.append("<div style='cursor: auto; padding: 5px; background-color: white; border: 1px solid #EEEEEE; float: left'><img class='logoEmpresa' src='");
			this.append(exibicaoCadastroEmpresaTO.caminhoArquivoLogomarca);
			this.append("'></div>");

			this.addLink({
				label: "<i class='fa fa-times' ></i>",
				onClick: () => this.excluirLogomarca(exibicaoCadastroEmpresaTO.codEmpresa),
				css: "float:left; width: 15px; margin: -3px 0px 0px 3px"
			});
			
		} else if (exibicaoCadastroEmpresaTO.codEmpresa != null) {
			this.addZonaUpload({ 
				id: "uploadLogomarca", 
				label: this.getMsg("FP_FRONT_CadastroEmpresaVH_015"), 
				classe: "col-md-3", 
				// maxArquivos: 1,
				privado: false
			});
		}

		this.addCheckbox({ id: "permiteFiliais", label: this.getMsg("FP_FRONT_CadastroEmpresaVH_011"), valor: exibicaoCadastroEmpresaTO.permiteFiliais })

		this.addEspacamentoHorizontal("10px");

		this.append("<div class='btn-group' style='float: right'>");

		this.addBotao({
			label: this.getMsg("FP_FRONT_CadastroEmpresaVH_016"), 
			onClick: async () => {
				this.addPopup({
					id: "exibicaoListagemEmpresa",
					titulo: this.getMsg("FP_FRONT_CadastroEmpresaVH_013"),
					botoes: [{
						label: this.getMsg("FP_FRONT_CadastroEmpresaVH_012"), 
						onClick: async () => {

							this.codEmpresaCopiaConfiguracao = this.getValor("codEmpresaCopiaConfiguracao");

							if (this.codEmpresaCopiaConfiguracao == null) {
								this.exibirAlerta({ msg: this.getMsg("FP_FRONT_CadastroEmpresaVH_017") });
								return false;
							}

							await this.call("CadastroEmpresaFCD/copiarConfiguracaoEmpresa", this.codEmpresaCopiaConfiguracao, exibicaoCadastroEmpresaTO.codEmpresa);
						}
					}],
				});

				await this.addSelect({
					id: "codEmpresaCopiaConfiguracao",
					label: this.getMsg("FP_FRONT_CadastroEmpresaVH_018"),
					valor: exibicaoCadastroEmpresaTO.codEmpresaSuperior,
					loadOpcoesBack: {
						endpoint: "ListagemSelecaoFCD/listarSegmentosPermitemFiliais",
						numMinimoCaracteres: 3,
					},
				});

				this.exibirPopups();
			}
		});

		this.addBotao({
			label: this.getMsg("FP_FRONT_CadastroEmpresaVH_019"), onClick: () => {
				loginVH.exibirAuditoriaEmpresa(exibicaoCadastroEmpresaTO.codEmpresa);
			}
		});

		if (exibicaoCadastroEmpresaTO.isExcluido) {
			this.addBotao({
				label: "<i class='fa fa-undo'></i> Restaurar cadastro",
				onClick: async () => {
					this.exibirAlerta({
						msg: this.getMsg("FP_FRONT_CadastroEmpresaVH_126"),
						botoes: [{
							label: this.getMsg("FP_FRONT_CadastroEmpresaVH_127"),
							classe: "btn-warning",
							onClick: async () => {
								await this.call("CadastroEmpresaFCD/restaurarCadastroEmpresa", exibicaoCadastroEmpresaTO.codEmpresa);
								document.location.reload();
							}
						}],
					});
				}
			});
		} else {
			this.addBotao({
				label: `<span class='text-danger'><i class='fas fa-trash'></i> ${this.getMsg("MSG_VH_006")}</span>`,
				onClick: async () => {
					this.exibirAlerta({
						msg: this.getMsg("FP_FRONT_CadastroEmpresaVH_021"),
						botoes: [{
							label: this.getMsg("MSG_VH_007"),
							classe: "btn-danger",
							onClick: async () => {
								await this.call("CadastroEmpresaFCD/deletarEmpresa", exibicaoCadastroEmpresaTO.codEmpresa);
								this.listarEmpresas();
							}
						}],
					});
				}
			});
		}


		this.addBotao({
			label: this.getMsg("FP_FRONT_CadastroEmpresaVH_022"), 
			classe: "btn-default", 
			onClick: async () => {

				if (!this.validarCamposObrigatorios("nomeFantasia", "sigla")) {
					return;
				}

				const salvamentoEmpresaTO = {
					codEmpresa: this.exibicaoCadastroEmpresaTO.codEmpresa,
					nomeFantasia: this.getValor("nomeFantasia"),
					sigla: this.getValor("sigla"),
					permiteFiliais: this.getValor("permiteFiliais"),
					codEmpresaSuperior: this.getValor("codEmpresaSuperior"),
					hostDominio: this.getValor("hostDominio") ? this.getValor("hostDominio").toLowerCase() : null,
					caminhoArquivoLogomarca: this.getValor("uploadLogomarca"),
					codigoSistemaOrigem: this.getValor("codigoSistemaOrigem")
				}

				const codEmpresa = await this.call("CadastroEmpresaFCD/salvarEmpresa", salvamentoEmpresaTO);
				this.exibirGruposConfiguracao(0, codEmpresa);
			}
		});

		this.append("</div>");

		this.addEspacamentoHorizontal("60px");

		const botoes: CfgsAddBotao[] = [];

		if (exibicaoCadastroEmpresaTO.numAlunos) {
			botoes.push({
				label: this.getMsg("FP_FRONT_CadastroEmpresaVH_023") + this.getCfg("LABEL_MENU_CADASTRO_ALUNO").toLowerCase(),
				classe: "btn-danger btn-sm",
				onClick: async () => {
					await this.exibirAlerta({
						msg: this.getMsg("FP_FRONT_CadastroEmpresaVH_024"),
						botoes: [{
							label: "REMOVER TODOS ALUNOS",
							classe: "btn-danger",
							onClick: async () => {
								await this.call(
									"CadastroEmpresaFCD/removerUsuarios", 
									exibicaoCadastroEmpresaTO.codEmpresa
								);
								await this.exibirGruposConfiguracao(0, exibicaoCadastroEmpresaTO.codEmpresa);
							}
						}]
					});
				},
			});

			botoes.push({
				label: this.getMsg("FP_FRONT_CadastroEmpresaVH_104") + " " + this.getCfg("LABEL_MENU_CADASTRO_ALUNO").toLowerCase(),
				classe: "btn-warning btn-sm",
				onClick: () => {
					const labelBotao = this.getMsg("FP_FRONT_CadastroEmpresaVH_104")  + " " +  this.getCfg("LABEL_MENU_CADASTRO_ALUNO");
					this.exibirAlerta({
						msg: this.getMsg("FP_FRONT_CadastroEmpresaVH_105"),
						botoes: [{
							label: labelBotao.toUpperCase(),
							classe: "btn-warning",
							onClick: async () => {
								await this.call("CadastroEmpresaFCD/arquivarAlunos", exibicaoCadastroEmpresaTO.codEmpresa);
								await this.exibirGruposConfiguracao(0, exibicaoCadastroEmpresaTO.codEmpresa);
							}
						}]
					});
				}
			});
		}

		this.addSubtitulo({
			texto: this.getMsg("FP_FRONT_CadastroEmpresaVH_025"),
			htmlDireita: this.addGrupoBotoes({ botoes, retornarHtml: true })
		});

		this.addCampoExibicao({
			label: this.getCfg("LABEL_MENU_CADASTRO_ALUNO") + this.getMsg("FP_FRONT_CadastroEmpresaVH_030"),
			valor: `${exibicaoCadastroEmpresaTO.numAlunos} (${exibicaoCadastroEmpresaTO.numAlunosArquivados} ${this.getMsg("FP_FRONT_CadastroEmpresaVH_106")}, +${exibicaoCadastroEmpresaTO.numAlunosConsultorFP || 0} CS)`
		});
		this.addCampoExibicao({
			label: this.getMsg("FP_FRONT_CadastroEmpresaVH_026"),
			valor: `${exibicaoCadastroEmpresaTO.numUsuariosAdm} (+${exibicaoCadastroEmpresaTO.numAdmsConsultorFP || 0} CS)`
		});
		this.addCampoExibicao({ label: this.getMsg("FP_FRONT_CadastroEmpresaVH_027"), valor: exibicaoCadastroEmpresaTO.numProvas});
		this.addCampoExibicao({ label: this.getMsg("FP_FRONT_CadastroEmpresaVH_028"), valor: exibicaoCadastroEmpresaTO.numProvasRespondidas});

		this.addEspacamentoHorizontal("60px");


		let isWhitelisted = false;
		const codEmpresaSmallBusiness = 5143;

		if (this.getHostname() === "root.fabricadeprovas.com.br") {
			isWhitelisted = [3522805, 2089759, 4256477, 3383049].includes(this.getCodUsuarioLogado());
		} else {
			isWhitelisted = true;
		}

		if (exibicaoCadastroEmpresaTO.codEmpresa !== codEmpresaSmallBusiness || isWhitelisted) {

			const botoes: CfgsAddBotao[] = [{
				id: "btnNovoUsuario",
				label: "<i class='fa fa-plus-circle'></i> " + this.getMsg("MSG_VH_CU_15"),
				onClick: async () => await this.editarUsuario(null),
				classe: "btn-success btn-sm",
			}];
				
			if (this.isRoot() && this.isAdministradorSistema() && isWhitelisted) {
				botoes.push({
					label: "Login as user",
					classe: "btn btn-sm pull-left btn-primary",
					onClick: async () => await this.popupLoginAsUser(codEmpresa),
				});
			}

			this.addSubtitulo({
				texto: "Usuários",
				htmlDireita: this.addGrupoBotoes({ botoes, retornarHtml: true })
			});

			this.append(`
				<div class="col-12">
					<div class="btn-group pull-right" style="padding-bottom: 20px">
					</div>
				</div>
			`);

			this.abrirAbaAccordion({
				titulo: this.getMsg("MSG_VH_001"),
				aberta: true
			});

			const filtrosListagemUsuariosTO = {
				codEmpresa: this.codEmpresaAtual,
				buscaTextual: this.buscaTextual,
				idsPerfis: this.idsPerfisBusca,
				codsEmpresasBusca: this.codsEmpresasBusca,
				isExibirArquivados: true
			}

			const exibicaoListagemAlunosTO = await this.call("CadastroUsuarioFCD/listarUsuarios", filtrosListagemUsuariosTO);

			this.listagemPerfis = exibicaoListagemAlunosTO.collectionPerfis;
			this.listagemSegmentos = exibicaoListagemAlunosTO.collectionSegmentosTO;

			const isExibirSegmentos = this.listagemSegmentos.length > 1 || (this.listagemSegmentos.length == 1 && this.listagemSegmentos[0].filhos != null);

			if (isExibirSegmentos) {
				await this.addSelect({
					collection: this.listagemSegmentos,
					id: "codsEmpresasBusca",
					label: this.getMsg("MSG_VH_CU_18"),
					multiplo: true,
					valor: this.codsEmpresasBusca,
					classe: 'col-md-6',
				});
			}

			await this.addSelect({
				collection: this.listagemPerfis,
				id: "idsPerfis",
				label: this.getMsg("MSG_VH_CU_19"),
				multiplo: true,
				valor: this.idsPerfisBusca,
				classe: 'col-md-6',
				onChange: async () => {}
			});

			this.addCampoTexto({
				label: this.getMsg("MSG_VH_002"),
				id: "busca",
				valor: this.buscaTextual,
				classe: 'col-md-6',
				prefixo: "<i class='fa fa-search'></i> ",
				onEnter: async () => await this.exibirListagemComBusca()
			});

			this.append("<div class='col-md-12'>");
			this.addBotao({
				label: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_025"),
				classe: "btn-primary pull-right",
				onClick: async () => {await this.exibirListagemComBusca()}
			});
			this.append("</div>");

			this.fecharAbaAccordion();
			this.fecharGrupoAccordion();
			this.exibir();

			this.exibicaoCadastroEmpresaTO = exibicaoCadastroEmpresaTO;

			this.append('<div id="lista-usuarios"> </div>')

			await this.listarUsuarios(exibicaoListagemAlunosTO);
		}

		this.addBotao({ label: this.getMsg("FP_FRONT_CadastroEmpresaVH_038"), onClick: () => this.listarEmpresas() });

		this.exibir();
	}


	async exibirListagemComBusca() {
		this.idsPerfisBusca = this.getValor("idsPerfis");
		this.buscaTextual = this.getValor("busca");
		this.codsEmpresasBusca = this.getValor("codsEmpresasBusca");

		const filtrosListagemUsuariosTO = {
			codEmpresa: this.codEmpresaAtual,
			buscaTextual: this.buscaTextual,
			idsPerfis: this.idsPerfisBusca,
			codsEmpresasBusca: this.codsEmpresasBusca,
			isExibirArquivados: true,
		}
		const exibicaoListagemAlunosTO = await this.call("CadastroUsuarioFCD/listarUsuarios", filtrosListagemUsuariosTO);

		await this.listarUsuarios(exibicaoListagemAlunosTO);
	}
	async listarUsuarios(exibicaoListagemAlunosTO) {

		const resultado = $("#resultado_busca_usuarios");
		if (resultado.length) {
			resultado.remove();
		}
		this.setIdTarget('lista-usuarios');
		this.append("<div id='resultado_busca_usuarios'></div>");
		this.setIdTarget('resultado_busca_usuarios');

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroEmpresaVH_031"), prop: "nome"});
		colunas.push({titulo: this.getMsg("MSG_VH_CU_18"), prop: "nomeSegmento"});
		colunas.push({titulo: "Perfil", prop: "perfil"});
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroEmpresaVH_032"), prop: "login"});
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroEmpresaVH_033"), prop: "email"});
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroEmpresaVH_034"), prop: (it) => it.isArquivado ? 'Não' : 'Sim'});
		colunas.push({titulo: this.getMsg("MSG_VH_CU_28"), prop: "dataCadastro", formato: "DD/MM/YY HH:mm"});
		colunas.push({titulo: "Último login", prop: "ultimaDataLogin", formato: "DD/MM/YY HH:mm"});
		colunas.push({titulo: "Consultor FP", prop: (it) => it.isConsultorFP ? 'Sim' : 'Não'});

		await this.addTabela({
			collection: exibicaoListagemAlunosTO.collectionListagemAlunoTO,
			id: 'table_cadastro_adms',
			propId: "codUsuario",
			colunas: colunas,
			onEdicao: async (codUsuario, perfil) => await this.editarUsuario(codUsuario, perfil),
			onExclusao: async (botao: HTMLButtonElement, codUsuario: number) => {
				await this.excluirUsuario(codUsuario);
			},
			paginacao: {
				paginacaoTO: exibicaoListagemAlunosTO.paginacaoTO,
				onCarregarPagina: async (paginacaoTO) => {
					const filtrosListagemUsuariosTO = {
						paginacaoTO: paginacaoTO,
						codEmpresa: this.codEmpresaAtual,
						codsEmpresasBusca: this.codsEmpresasBusca,
						buscaTextual: this.buscaTextual,
						idsPerfis: this.idsPerfisBusca,
						isExibirArquivados: true
					}
					const exibicaoListagemAlunosTO = await this.call("CadastroUsuarioFCD/listarUsuarios", filtrosListagemUsuariosTO);
					return exibicaoListagemAlunosTO.collectionListagemAlunoTO;
				}
			},
			ordenar: false
		});

		this.exibir();
		this.setIdTarget(null)
	}

	async popupLoginAsUser(codEmpresa) {

		this.addPopup({
			id: "popupLoginAsUser",
			titulo: "Login as user",
			width: "500px",
			botoes: [{
				label: "Logar",
				onClick: async () => {

					const userToLogin = this.getValor("userToLogin");

					if (userToLogin) {

						const loginAsUserTO = await this.call("CadastroUsuarioFCD/loginAsUser", userToLogin);

						if(loginAsUserTO && (loginAsUserTO.hostEmpresa || loginAsUserTO.path) && loginAsUserTO.chaveDeAcesso){
							const urlAmbiente = loginAsUserTO.path || loginAsUserTO.hostEmpresa;
							if (urlAmbiente) {
								this.abrirNovaJanela(urlAmbiente+"?chaveDeAcesso="+loginAsUserTO.chaveDeAcesso);
							}
						}
					}
				}
			}],
		});

		await this.addSelect({
			id: "userToLogin",
			label: "Selecione o usuário para logar",
			multiplo: false,
			obrigatorio: true,
			classe: "col-md-12",
			loadOpcoesBack: {
				endpoint: "ListagemSelecaoFCD/listarUsuariosEmpresa",
				payload: {
					codEmpresa: codEmpresa
				},
				numMinimoCaracteres: 3,
			},
		});

		this.exibirPopups();
		this.exibir();
	}

	async editarUsuario(codUsuario, usuario?) {
		let usuarioTO: any = {idPerfil: 1, isAtivo: true, inicioVigencia: new Date(), codEmpresa: this.exibicaoCadastroEmpresaTO.codEmpresa};

		if (codUsuario) {
			if (usuario && [6,1].includes(Number(usuario.idPerfil))) {
				usuarioTO = await this.call("CadastroEmpresaFCD/recuperarAdministrador", codUsuario);
				usuarioTO.idPerfil = 1;
			} else  {
				usuarioTO = await this.call("CadastroUsuarioFCD/recuperar", codUsuario);
				usuarioTO.isAtivo = !usuarioTO.isExcluido;
			}
		}

		this.addPopup({
			id: "exibicaoListagemAdministradores",
			titulo: codUsuario ? this.getMsg("FP_FRONT_CadastroEmpresaVH_121") : this.getMsg("FP_FRONT_CadastroEmpresaVH_122"),
			width: "700px",
			botoes: [{ label: this.getMsg("FP_FRONT_CadastroEmpresaVH_041"), onClick: async() => {
					await this.validarUsuarioParaSalvar();
					return false
				} }],
		});

		this.addInputHidden("codUsuario", usuarioTO.codUsuario);

		this.addCampoTexto({
			id: "nomeUsuario",
			label: this.getMsg("FP_FRONT_CadastroEmpresaVH_043"),
			valor: usuarioTO.nome,
			classe: "col-md-12",
			onChange: async () => await this.atualizarEdicaoAdm(),
			obrigatorio: true
		})

		await this.addSelect({
			id: "idPerfil",
			collection: this.listagemPerfis,
			valor: usuarioTO.idPerfil,
			label: this.getMsg("MSG_VH_CU_35"),
			classe: "col-md-12",
			obrigatorio: true
		})

		await this.addSelect({
			id: "codEmpresa",
			collection: this.listagemSegmentos,
			valor: usuarioTO.codEmpresa,
			label: this.getMsg("MSG_VH_CU_18"),
			classe: "col-md-12",
			obrigatorio: true
		})

		this.addCampoTexto({
			id: "loginUsuario",
			label: this.getMsg("FP_FRONT_CadastroEmpresaVH_044"),
			valor: usuarioTO.login,
			obrigatorio: true
		})

		this.addCampoTexto({
			id: "emailUsuario",
			label: this.getMsg("FP_FRONT_CadastroEmpresaVH_045"),
			valor: usuarioTO.email,
		})

		this.addCampoTexto({
			id: "inicioVigencia",
			label: this.getMsg("FP_FRONT_CadastroEmpresaVH_046"),
			valor: usuarioTO.inicioVigencia,
			tipo: "DATA",
		})

		this.addCampoTexto({
			id: "fimVigencia",
			label: this.getMsg("FP_FRONT_CadastroEmpresaVH_047"),
			valor: usuarioTO.fimVigencia,
			tipo: "DATA",
		})

		this.addCampoTexto({
			id: "senha",
			idComponente: "administrador_componenteSenha",
			label: usuarioTO.codUsuario == null ? this.getMsg("FP_FRONT_CadastroEmpresaVH_048") : this.getMsg("FP_FRONT_CadastroEmpresaVH_049"),
			tipo: "SENHA",
			classe: "col-md-6",
			validacaoSenha: true
		})

		this.addCampoTexto({
			id: "confirmacaoSenha",
			idComponente: "administrador_componenteSenhaConfirmacao",
			label: this.getMsg("FP_FRONT_CadastroEmpresaVH_050"),
			tipo: "SENHA",
			classe: "col-md-6"
		})

		this.addCheckbox({ 
			id: "ativo", 
			label: this.getMsg("FP_FRONT_CadastroEmpresaVH_051"), 
			valor: usuarioTO.isAtivo,
		});
		
		this.addCheckbox({ 
			id: "isIntegrador", 
			label: "Integrador", 
			valor: usuarioTO.isIntegrador,
			onChange: async () => await this.atualizarEdicaoAdm()
		});

		this.addCheckbox({
			id: "isConsultorFP",
			label: "Consultor FP",
			valor: usuarioTO.isConsultorFP,
		});

		if (usuarioTO.jwtIntegracao) {
			this.addCampoExibicao({
				id: "jwtIntegracao",
				label: "Token de integração",
				valor: usuarioTO.jwtIntegracao,
				classe: "col-md-12",
				isExibirLinkCopiaValor: true,
			});
		}

		this.exibirPopups();
		await this.atualizarEdicaoAdm();
	}

	async salvarUsuario() {

		const salvamentoAlunoTO: any = {
			codEmpresa: this.getValor("codEmpresa"),
			codUsuario: this.getValor("codUsuario"),
		}

		salvamentoAlunoTO.login = this.getValor("loginUsuario");
		salvamentoAlunoTO.idPerfil = this.getValor("idPerfil");
		salvamentoAlunoTO.senha = this.getValor("senha");
		salvamentoAlunoTO.inicioVigencia = this.getValor("inicioVigencia");
		salvamentoAlunoTO.fimVigencia = this.getValor("fimVigencia");
		salvamentoAlunoTO.nome = this.getValor("nomeUsuario");
		salvamentoAlunoTO.email = this.getValor("emailUsuario");
		salvamentoAlunoTO.isEditar = this.getValor("codUsuario") != null;
		salvamentoAlunoTO.isConsultorFP = this.getValor("isConsultorFP");
		salvamentoAlunoTO.isDeveIgnorarEmailBoasVindas = true;

		const confirmacaoDeSenha = this.getValor("confirmacaoSenha");

		if (salvamentoAlunoTO.senha) {
			if (!UtilPasswordValidator.isSenhaForte("senha")) {
				await this.exibirAlerta({ msg: UtilMsg.getMsg('FP_FRONT_CadastroUsuarioVH_040') });
				return false;
			}
			if (salvamentoAlunoTO.senha !== confirmacaoDeSenha) {
				await this.exibirAlerta({ msg: this.getMsg("MSG_VH_CU_127") });
				this.focar("senha");
				return false;
			}
		}

		await this.call("CadastroUsuarioFCD/salvar", salvamentoAlunoTO);

		this.fecharPopup("exibicaoListagemAdministradores");
		this.exibirGruposConfiguracao(0, this.codEmpresaAtual);
	}

	async atualizarEdicaoAdm() {
		const isIntegrador = this.getValor("isIntegrador");

		if (isIntegrador) {
			await this.show("jwtIntegracao");
			const nomeUsuario = this.getValor("nomeUsuario");
			if (nomeUsuario) {
				this.setValor("loginUsuario", nomeUsuario.trim().replace(/[\s\-]/g, "_").toLowerCase())
			}
			this.disable("loginUsuario", "senha", "confirmacaoSenha", "idPerfil");
		} else {
			await this.hide("jwtIntegracao")
			this.enable("loginUsuario", "senha", "confirmacaoSenha", "idPerfil");
		}
	}

	async validarUsuarioParaSalvar() {
		if (!this.validarCamposObrigatorios("nomeUsuario", "loginUsuario", "idPerfil", "codEmpresa")) {
			return false;
		}
		const isAdm = this.getValor("idPerfil") == 1;
		if (isAdm) {
			return await this.salvarAdministrador();
		}
		return await this.salvarUsuario();
	}

	async salvarAdministrador() {
		
		const salvamentoAdministradorTO: any = {
			codEmpresa: this.getValor("codEmpresa"),
			codUsuario: this.getValor("codUsuario"),
		}

		salvamentoAdministradorTO.nome = this.getValor("nomeUsuario");
		salvamentoAdministradorTO.login = this.getValor("loginUsuario");
		salvamentoAdministradorTO.email = this.getValor("emailUsuario");
		salvamentoAdministradorTO.senha = this.getValor("senha");
		salvamentoAdministradorTO.inicioVigencia = this.getValor("inicioVigencia");
		salvamentoAdministradorTO.fimVigencia = this.getValor("fimVigencia");
		salvamentoAdministradorTO.isAtivo = this.getValor("ativo");
		salvamentoAdministradorTO.isIntegrador = this.getValor("isIntegrador");
		salvamentoAdministradorTO.isConsultorFP = this.getValor("isConsultorFP");

		const confirmacaoDeSenha = this.getValor("confirmacaoSenha");

		if (salvamentoAdministradorTO.senha) {
			if (!UtilPasswordValidator.isSenhaForte("senha")) {
				await this.exibirAlerta({ msg: UtilMsg.getMsg('FP_FRONT_CadastroUsuarioVH_040') });
				return false;
			}
			if (salvamentoAdministradorTO.senha !== confirmacaoDeSenha) {
				await this.exibirAlerta({ msg : this.getMsg("MSG_VH_CU_127") });
				this.focar("senha");
				return false;
			}
		}
		await this.call("CadastroEmpresaFCD/salvarAdministrador", salvamentoAdministradorTO);
		this.fecharPopup("exibicaoListagemAdministradores");
		this.exibirGruposConfiguracao(0, this.codEmpresaAtual);

		return false;
	}

	async exibirGruposConfiguracao(numAbaESubaba: string | number, codEmpresa: number, codEmpresaPai?: number) {

		UtilHash.registrarHistorico(this.exibirGruposConfiguracao, numAbaESubaba, codEmpresa, codEmpresaPai);

		const edicaoConfiguracaoEmpresaTO = await this.call("CadastroEmpresaFCD/recuperarGruposDeConfigs", codEmpresa);

		this.limpar();

		this.setTitulo(edicaoConfiguracaoEmpresaTO.nomeEmpresa);
		this.setSubtitulo(this.getMsg("FP_FRONT_CadastroEmpresaVH_053"));
		const abas = [];

		abas.push({ 
			label: this.getMsg("FP_FRONT_CadastroEmpresaVH_054"), 
			onClick: () => cadastroEmpresaVH.alterarEmpresa(edicaoConfiguracaoEmpresaTO.codEmpresa)
		});

		const mapAbas = {};

		edicaoConfiguracaoEmpresaTO.collectionGruposItemListaTO.forEach(gruposItemListaTO => {
			const partes = gruposItemListaTO.descricao.split(">");
			const nomeAba = partes[0];
			const nomePill = partes [1];

			let cfgsAba: any = mapAbas[nomeAba];

			if (!cfgsAba) {
				cfgsAba = {
					nomeAba: nomeAba,
					gruposItemListaTO: gruposItemListaTO,
					pills: []
				}
				mapAbas[nomeAba] = cfgsAba;
			}

			if (nomePill) {
				cfgsAba.pills.push({
					gruposItemListaTO: gruposItemListaTO,
					nomePill: nomePill
				})
			}
		});

		Object.values(mapAbas).forEach((cfgsAba: any) => {
			abas.push({
				label: cfgsAba.nomeAba,
				onClick: async () => {
					if (cfgsAba.pills.length) {
						this.addPills({
							subAbaAtiva: numAbaESubaba,
							pills: cfgsAba.pills.map(cfgsPill => {
								return { 
									label: cfgsPill.nomePill, 
									onClick: async () => await cadastroEmpresaVH.exibirEdicaoConfigs(edicaoConfiguracaoEmpresaTO.codEmpresa, cfgsPill.gruposItemListaTO.id)
								}
							})
						});
			
						this.exibir();
					} else {
						cadastroEmpresaVH.exibirEdicaoConfigs(edicaoConfiguracaoEmpresaTO.codEmpresa, cfgsAba.gruposItemListaTO.id);
					}
				}
			});
		});

		this.addAbas({ abas, numAbaAtiva: numAbaESubaba });

		this.exibir();

		this.edicaoConfiguracaoEmpresaTO = edicaoConfiguracaoEmpresaTO;
	}

	async exibirEdicaoConfigs(codEmpresa: number, grupo: string) {

		const edicaoGrupoConfigTO = await this.call("CadastroEmpresaFCD/recuperarConfiguracoes", codEmpresa, grupo);

		this.edicaoGrupoConfigTO = edicaoGrupoConfigTO;
		this.limpar(true);

		this.addTexto("<i>" + edicaoGrupoConfigTO.descricao + "</i>");
		this.addEspacamentoHorizontal("30px");

		this.addFormulario();

		if (edicaoGrupoConfigTO.cfgsDificuldadeAmbienteTO && grupo == "DIFICULDADE") {
			this.exibirCadastroDificuldade(edicaoGrupoConfigTO.cfgsDificuldadeAmbienteTO);
		}

		if (edicaoGrupoConfigTO.cfgsTiposComentariosAmbienteTO && grupo == "TIPO_COMENTARIO") {
			this.exibirCadastroTipoComentario(edicaoGrupoConfigTO.cfgsTiposComentariosAmbienteTO);
		}

		const campoNovoCfgDeTiposMultiplosJaAdicionados = {};
		let idsEditorCodigoFonte = [];

		for (const configuracaoTO of edicaoGrupoConfigTO.collectionConfiguracaoTO) {

			const opcoes = [];

			if (this.hasValue(configuracaoTO.opcoesSelect)) {
				for (let j = 0; j < configuracaoTO.opcoesSelect.length; j++) {
					opcoes.push({ 
						id: configuracaoTO.opcoesSelect[j++], 
						descricao: configuracaoTO.opcoesSelect[j] 
					});
				}
			}

			let id = "id" + (configuracaoTO.codEmpresaConfig || configuracaoTO.tipoConfig);
			const tipoConfig = `tipo-config-${configuracaoTO.tipoConfig}`;
			let label = configuracaoTO.label;
			let variaveisDeTexto = "";

			if (this.hasValue(configuracaoTO.variaveisDeTexto)) {
				variaveisDeTexto = `${this.getMsg("FP_FRONT_CadastroEmpresaVH_055")}: ${configuracaoTO.variaveisDeTexto.join(', ')}`;
			}

			if (configuracaoTO.codEmpresaConfig) {
				label += " <a style='opacity: 0.7' class='botaoRemoverEC' cod-empresa-config='" + configuracaoTO.codEmpresaConfig + "' onclick='cadastroEmpresaVH.removerConfig(this)'><i class='fa fa-trash-o'></i></a> ";
			}

			if ((configuracaoTO.tipoInput == "ARQUIVO_MULTIPLO" || configuracaoTO.tipoInput == "TEXT_MULTIPLO" || configuracaoTO.classeDoTO == "TipoConfigMicrosservicoTO" || configuracaoTO.classeDoTO == "TipoConfigPerfisPermitidosTO"  || configuracaoTO.classeDoTO == "TipoConfigEnvioResultadoTO")
				&& this.hasValue(configuracaoTO.valor)) {
				campoNovoCfgDeTiposMultiplosJaAdicionados[configuracaoTO.tipoConfig] = configuracaoTO;
			}

			if (configuracaoTO.tipoInput == "TEXT" || configuracaoTO.tipoInput == "TEXT_MULTIPLO") {

				this.addCampoTexto({ 
					id: id, 
					label: label, 
					valor: configuracaoTO.valor, 
					classe: `col-md-6 ${tipoConfig}`,
					html: "tipo='" + configuracaoTO.tipoConfig + "'",
					onChange: () => this.atualizarExibicao()
				});

			} else if (configuracaoTO.tipoInput == "NUMERO") {

				this.addCampoTexto({ 
					id: id, 
					label: label, 
					valor: configuracaoTO.valor, 
					tipo: "NUMERO",
					classe: `col-md-6 ${tipoConfig}`,
					onChange: () => this.atualizarExibicao()
				});

			} else if (configuracaoTO.tipoInput == "MOEDA") {

				this.addCampoTexto({ 
					id: id, 
					label: label, 
					valor: configuracaoTO.valor, 
					tipo: "MOEDA",
					classe: `col-md-6 ${tipoConfig}`,
					onChange: () => this.atualizarExibicao()
				});

			} else if (configuracaoTO.tipoInput == "TEXTAREA_PURO") {
				idsEditorCodigoFonte.push({ id: id, css: configuracaoTO.tipoConfig.match("CSS"), valor: configuracaoTO.valor });
				this.append('<div class="form-group col-md-12">');
				this.append('<label class="control-label" for="' + id + '">' + label);
				this.append('</label>');
				this.append(`<div ace id="${id}" name="${id}" style="width: 100%; height: 500px; font-size: 150%">`);
				this.append('</div>');
				this.append('</div>');

			} else if (configuracaoTO.tipoInput == "TEXTAREA") {
				this.addEditorHTML({ 
					id: id, 
					label: label, 
					valor: configuracaoTO.valor,
					classe: `col-md-6 ${tipoConfig}`,
					ajuda: variaveisDeTexto,
					onChange: () => this.atualizarExibicao()
				});

			} else if (configuracaoTO.tipoInput == "CHECKBOX") {
				// this.addCheckbox({ id: id, label: label, checked: configuracaoTO.valorBoolean, classe: "col-md-6" });
				this.addRadioGroup({
					id: id, 
					label: label,
					html: "radio-habilitacao-cfg=true",
					classeRadio: "radio-inline",
					collection: [{ id: "true", descricao: "Sim"}, { id: "false", descricao: "Não"}],
					valor: configuracaoTO.valorBoolean != null ? String(configuracaoTO.valorBoolean) : null,
					classe: `col-md-6 ${tipoConfig}`,
					onChange: () => this.atualizarExibicao()
				})

			} else if (configuracaoTO.tipoInput == "SELECT") {

				const valorPersonalizado = this.getOpcaoEscolhidaDeValorPersonalizado(opcoes, configuracaoTO.valor);
				let html = "";

				if (valorPersonalizado != null) {
					html = " valor-personalizado-inicial='" + configuracaoTO.valor + "' ";
				}

				if (opcoes.length < 4) {
					this.addRadioGroup({ 
						id: id, 
						label: label, 
						valor: valorPersonalizado || configuracaoTO.valor, 
						html: html, 
						collection: opcoes,
						classe: `col-md-6 ${tipoConfig}`,
						classeRadio: "radio-inline",
						onChange: () => this.atualizarExibicao()
					});
				} else {
					await this.addSelect({ 
						id: id, 
						label: label, 
						valor: valorPersonalizado || configuracaoTO.valor, 
						html: html,
						classe: `col-md-6 ${tipoConfig}`,
						collection: opcoes,
						onChange: () => this.atualizarExibicao()
					});
				}

			} else if (configuracaoTO.tipoInput == "SELECT_MULTIPLO") {
				await this.addSelect({ 
					id: id, 
					label: label, 
					valor: configuracaoTO.valores,
					classe: `col-md-6 ${tipoConfig}`,
					collection: opcoes,
					multiplo: true,
					onChange: () => this.atualizarExibicao()
				});

			} else if (configuracaoTO.tipoInput == "ARQUIVO" || configuracaoTO.tipoInput == "ARQUIVO_MULTIPLO") {

				if (this.hasValue(configuracaoTO.valor)) {
					this.addInputHidden(id, configuracaoTO.valor);

					if (UtilArquivo.isImagem(configuracaoTO.valor)) {
						this.addCampoExibicao({
							label: label,
							valor: `<img style="width: 100%" src="${configuracaoTO.valor}">`,
							html: `tipo="${configuracaoTO.tipoConfig}"`,
							classe: `col-md-6 ${tipoConfig}`,
						});
					} else {
						this.addCampoExibicao({
							label: label,
							valor: `<a target="_blank" href="${configuracaoTO.valor}">${UtilArquivo.getNomeArquivo(configuracaoTO.valor)}</a>`,
							html: `tipo="${configuracaoTO.tipoConfig}"`,
							classe: `col-md-6 ${tipoConfig}`,
						});
					}

				} else {
					this.addZonaUpload({ 
						id: id, 
						label: label, 
						// maxArquivos: 1, 
						classe: `col-md-6 ${tipoConfig}`,
						privado: false,
						onDepoisEnvio: () => this.atualizarExibicao(),
					});
				}

			} else if (configuracaoTO.classeDoTO == "TipoConfigMicrosservicoTO") {

				let to = {};

				if (configuracaoTO.codEmpresaConfig) {
					to = JSON.parse(configuracaoTO.valor);
				}

				this.append("<div class='row'>");

				await this.addEdicaoMicrosservico(id, label, to, false, edicaoGrupoConfigTO.collectionOpcaoListaTOTiposServico);

				this.append("</div>");

			} else if (configuracaoTO.classeDoTO == "TipoConfigAlertaTO") {
				let to: any = {};

				if (configuracaoTO.codEmpresaConfig) {
					id = configuracaoTO.codEmpresaConfig;
					to = JSON.parse(configuracaoTO.valor);
				} else {
					id = configuracaoTO.tipoConfig;
				}

				this.addEspacamentoHorizontal("20px");
				this.addSubsubtitulo(label + ` <br><small>${variaveisDeTexto}</small>`);

				this.append("<div class='row'>");
				this.addCampoTexto({ 
					id: id + "_tituloEmail", 
					label: this.getMsg("FP_FRONT_CadastroEmpresaVH_056"), 
					valor: to.tituloEmail, 
					classe: "col-md-12",
					onChange: () => this.atualizarExibicao()
				});
				this.addEditorHTML({ 
					id: id + "_conteudoEmail", 
					label: this.getMsg("FP_FRONT_CadastroEmpresaVH_057"), 
					valor: to.conteudoEmail, 
					classe: "col-md-12",
					onChange: () => this.atualizarExibicao()
				});
				this.addEditorHTML({ 
					id: id + "_conteudoNotificacao", 
					label: this.getMsg("FP_FRONT_CadastroEmpresaVH_058"), 
					valor: to.conteudoNotificacao, 
					classe: "col-md-12",
					onChange: () => this.atualizarExibicao()
				});
				this.addCampoTexto({ 
					id: id + "_link", 
					label: this.getMsg("FP_FRONT_CadastroEmpresaVH_059"), 
					valor: to.link, 
					classe: "col-md-12",
					onChange: () => this.atualizarExibicao()
				});
				this.append("</div>");

			} else if (configuracaoTO.classeDoTO == "TipoConfigIntervaloTempoTO") {

				let to: any = {};
				let html = " input-tipo-to='true' ";

				if (configuracaoTO.codEmpresaConfig) {
					to = JSON.parse(configuracaoTO.valor);

					if (to.valor != null) {
						html += " valor-inicial='" + to.valor + "' ";
					}
				}

				await this.addSelect({
					collection: JSON.parse(JSON.stringify(edicaoGrupoConfigTO.collectionOpcaoListaTOTiposIntervaloTempo)),
					html: html,
					id: id,
					label: label,
					valor: to.tipoIntervaloTempo,
					classe: "col-md-6",
					onChange: async (event: any) => cadastroEmpresaVH.onChangeSelectTipoConfigIntervaloTempoTO(event.target),
				});
			} else if (configuracaoTO.classeDoTO == "TipoConfigPerfisPermitidosTO") {

				let to = {};

				if (configuracaoTO.codEmpresaConfig) {
					to = JSON.parse(configuracaoTO.valor);
				}

				this.append("<div class='row'>");

				await this.addEdicaoPermissao(id, label, to, false, edicaoGrupoConfigTO.collectionOpcaoListaTOTiposServico, edicaoGrupoConfigTO.collectionOpcaoListaTOPerfil);

				this.append("</div>");

			} else if(configuracaoTO.classeDoTO == "TipoConfigEnvioResultadoTO") {
				let to = {};

				if (configuracaoTO.codEmpresaConfig) {
					to = JSON.parse(configuracaoTO.valor);
				}

				this.append("<div class='row'>");

				this.addEdicaoUrlEnvioNota(id, label, to, false);

				this.append("</div>");
			}
		}

		if (grupo == 'BASICO') {
			await this.addTabela({
				collection: edicaoGrupoConfigTO.collectionListagemContratosTO,
				propId: "codEmpresaContrato",
				colunas: [{
					titulo: this.getMsg("FP_FRONT_CadastroEmpresaVH_117"), 
					prop: "codEmpresaContrato"
				},{
					titulo: "Razão social",
					prop: "razaoSocialContratante",
				},{
					titulo: "CNPJ",
					prop: "cnpjContratante"
				},{
					titulo: this.getMsg("FP_FRONT_CadastroEmpresaVH_046"), 
					prop: "inicioVigencia", 
					formato: "DD/MM/YYYY"
				},{
					titulo: this.getMsg("FP_FRONT_CadastroEmpresaVH_047"), 
					prop: "fimVigencia", 
					formato: "DD/MM/YYYY"
				},{
					titulo: this.getMsg("FP_FRONT_CadastroEmpresaVH_116"), 
					prop: (contrato: any) =>  {
						if (contrato.toleranciaFimContrato) {
							return `${this.getMsg('FP_FRONT_CadastroEmpresaVH_118', contrato.toleranciaFimContrato)}${contrato.toleranciaFimContrato > 1 ? 's' : ''}`
						}
					}
				},{
					titulo: "Situação",
					prop: "situacaoContrato"
				},{
					titulo: "Renovação automática",
					prop: (contrato: any) =>  {
						return contrato.isRenovavelAutomaticamente === true? 'Sim': 'Não';
					}
				}],
				ordenacao: [["inicioVigencia", 1]],
				titulo: this.getMsg("FP_FRONT_CadastroEmpresaVH_114"),
				labelNovo: this.getMsg("FP_FRONT_CadastroEmpresaVH_115"),
				msgListaVazia: this.getMsg("FP_FRONT_CadastroEmpresaVH_120"),
				onEdicao: async (codEmpresaContrato: any) => await this.editarContrato(codEmpresa, codEmpresaContrato),
				onExclusao: async (botao: any, codEmpresaContrato: number) => await this.excluirContrato(codEmpresaContrato),
				classeOnNovo: "btn-success",
			});
		}

		this.fecharFormulario();

		this.addEspacamentoHorizontal("20px");

		this.addBotao({ 
			label: `&laquo; ${this.getMsg("FP_FRONT_CadastroEmpresaVH_060")}`, 
			onClick: () => UtilHash.voltar() 
		});
		this.addBotao({
			label: this.getMsg("FP_FRONT_CadastroEmpresaVH_061"), 
			css: "float: right", 
			onClick: () => {
				this.salvarConfiguracoes(this.edicaoConfiguracaoEmpresaTO, this.edicaoGrupoConfigTO, campoNovoCfgDeTiposMultiplosJaAdicionados, grupo, true);
			}
		});

		this.exibir();

		$("[input-tipo-to]").trigger("change");

		for (const tipoConfig in campoNovoCfgDeTiposMultiplosJaAdicionados) {
			const configuracaoTO = campoNovoCfgDeTiposMultiplosJaAdicionados[tipoConfig];
			let h;
			if (configuracaoTO.tipoInput == "ARQUIVO_MULTIPLO") {
				h = this.addZonaUpload({ id: "id" + configuracaoTO.tipoConfig, label: configuracaoTO.label, /*maxArquivos: 1,*/ classe: "col-md-6", retornarHtml: true });
			} else if (configuracaoTO.tipoInput == "TEXT_MULTIPLO") {
				h = this.addCampoTexto({ id: "id" + configuracaoTO.tipoConfig, label: configuracaoTO.label, classe: "col-md-6", retornarHtml: true });
			} else if (configuracaoTO.tipoConfig == "MICROSSERVICO") {
				h = await this.addEdicaoMicrosservico("id" + configuracaoTO.tipoConfig, configuracaoTO.label, {}, true, edicaoGrupoConfigTO.collectionOpcaoListaTOTiposServico);
			} else if (configuracaoTO.tipoConfig == "PERFIL_PERMITIDO_CADASTRAR") {
				h = await this.addEdicaoPermissao("id" + configuracaoTO.tipoConfig, configuracaoTO.label, {}, true, edicaoGrupoConfigTO.collectionOpcaoListaTOTiposServico, edicaoGrupoConfigTO.collectionOpcaoListaTOPerfil);
			} else if (configuracaoTO.tipoConfig == "URL_ENVIO_RESULTADO_PROVAS") {
				h = this.addEdicaoUrlEnvioNota("id" + configuracaoTO.tipoConfig, configuracaoTO.label, {}, true);
			}

			$("[tipo='" + configuracaoTO.tipoConfig + "']:last").closest(".form-group, .container-group").after(h);
		}

		this.exibir();

		for (const configuracaoTO of edicaoGrupoConfigTO.collectionConfiguracaoTO) {
			if (configuracaoTO.tipoInput == "TEXTAREA") {
				const id = "id" + (configuracaoTO.codEmpresaConfig || configuracaoTO.tipoConfig);
				$("#" + id).before(`<input titulo class='form-control' type='text' placeholder='${this.getMsg("FP_FRONT_CadastroEmpresaVH_062")}' value='${configuracaoTO.tituloValor || ""}'>`);
			}
		}

		this.edicaoGrupoConfigTO = edicaoGrupoConfigTO;
		const { ace } = await UtilBoot.carregarAceCodeEditor();

		for (const c of idsEditorCodigoFonte) {
			const editor = ace.edit(c.id);
			editor.on("change", () => {
				this.atualizarExibicao();
			})
			editor.setTheme("ace/theme/monokai");
			editor.session.setMode("ace/mode/" + (c.css ? "css" : "javascript"));
			editor.session.setValue(c.valor || "");
			editor.commands.addCommand({
				name: 'salvar',
				bindKey: { win: 'Ctrl-S', mac: 'Command-S' },
				exec: (editor: any) => {
					this.salvarConfiguracoes(this.edicaoConfiguracaoEmpresaTO, edicaoGrupoConfigTO, campoNovoCfgDeTiposMultiplosJaAdicionados, grupo, false);
				},
				readOnly: true
			});
		}

		await this.atualizarExibicao();

		if(grupo === 'INTEGRACAO_SSO') {
			await this.organizarGrupos('tipo-config-LDAP');
			await this.organizarGrupos('tipo-config-AUTH0');
			await this.organizarGrupos('tipo-config-MICROSOFT_IDENTITY');
			await this.organizarGrupos('tipo-config-OKTA');
		}
	}

	async organizarGrupos(classe: string) {
		const elements = document.querySelectorAll(`[class*='${classe}']`);

		if (elements.length > 0) {
			const newContainer = document.createElement("div");
			newContainer.className = "form-group col-md-12 grouped-config";
			const parent = elements[0].parentNode;
			elements.forEach(el => {
				newContainer.appendChild(el);
			});
			parent.appendChild(newContainer);
		}
	}

	async atualizarExibicao() {

		$(".form-group").css("opacity", "0.5");
		
		const filtrarComValor = (i: any, input: any) => {
			return this.hasValue(this.getValor(input.id));
		}

		const $inputs = $("select, input[type=text], input[type=radio], div[contenteditable=true]").filter(filtrarComValor);
		const $arquivos = $(".form-group > div > a[href], .dropzone .dz-success");
		const { ace } = await UtilBoot.carregarAceCodeEditor();
		const $aces = $("[ace]").filter((i: any, input: any) => {
			try {
				return this.hasValue(ace.edit(input.id).getValue());
			} catch (e) {
				return false;
			}
		});

		$inputs.add($arquivos).add($aces).closest(".form-group").css("opacity", "1");
	}

	async removerConfig(botaoRemover) {
		const codEmpresaConfig = $(botaoRemover).attr("cod-empresa-config");
		await this.call("CadastroEmpresaFCD/excluirConfiguracao", codEmpresaConfig);
		document.location.reload()
	}

	async salvarConfiguracoes(edicaoConfiguracaoEmpresaTO, edicaoGrupoConfigTO, campoNovoCfgDeTiposMultiplosJaAdicionados, grupo, isRecarregarProps) {

		const listaSalvamentoConfiguracoesTO = [];

		for (const configuracaoTO of edicaoGrupoConfigTO.collectionConfiguracaoTO) {

			const salvamentoConfiguracaoTO: SalvamentoConfiguracaoTO = {
				codEmpresaConfig: configuracaoTO.codEmpresaConfig,
				tipoConfig: configuracaoTO.tipoConfig,
				codEmpresaDaConfiguracao: edicaoConfiguracaoEmpresaTO.codEmpresa,
				tituloValor: null,
				valor: null,
				valorBoolean: null,
				valorDate: null,
				valores: null
			}

			let id = "id" + (configuracaoTO.codEmpresaConfig || configuracaoTO.tipoConfig);

			if (configuracaoTO.classeDoTO == "TipoConfigAlertaTO") {
				id = configuracaoTO.codEmpresaConfig || configuracaoTO.tipoConfig;

				const tipoConfigAlertaTO = {
					tituloEmail: this.getValor(id + "_tituloEmail"),
					conteudoEmail: this.getValor(id + "_conteudoEmail"),
					conteudoNotificacao: this.getValor(id + "_conteudoNotificacao"),
					link: this.getValor(id + "_link"),
				}

				if (this.hasValue(tipoConfigAlertaTO.tituloEmail) || this.hasValue(tipoConfigAlertaTO.conteudoEmail) || this.hasValue(tipoConfigAlertaTO.conteudoNotificacao) || this.hasValue(tipoConfigAlertaTO.link)) {
					salvamentoConfiguracaoTO.valor = JSON.stringify(tipoConfigAlertaTO);
					listaSalvamentoConfiguracoesTO.push(salvamentoConfiguracaoTO);
				}

			} else if (configuracaoTO.classeDoTO == "TipoConfigMicrosservicoTO") {
				const valorMicrosservico = this.getValorMicrosservico(id);

				if (valorMicrosservico) {
					salvamentoConfiguracaoTO.valor = valorMicrosservico;
					listaSalvamentoConfiguracoesTO.push(salvamentoConfiguracaoTO);
				}
			} else if (configuracaoTO.classeDoTO == "TipoConfigEnvioResultadoTO") {
				const valorEnvioNota = this.getValorEnvioNota(id);

				if (valorEnvioNota) {
					salvamentoConfiguracaoTO.valor = valorEnvioNota;
					listaSalvamentoConfiguracoesTO.push(salvamentoConfiguracaoTO);
				}
			} else if (configuracaoTO.classeDoTO == "TipoConfigPerfisPermitidosTO") {
				const valorPermissao = this.getValorPermissao(id);

				if (valorPermissao) {
					salvamentoConfiguracaoTO.valor = valorPermissao;
					listaSalvamentoConfiguracoesTO.push(salvamentoConfiguracaoTO);
				}
			} else if (configuracaoTO.classeDoTO == "TipoConfigIntervaloTempoTO") {
				try {
					const tipoConfigIntervaloTempoTO = {
						tipoIntervaloTempo: this.getValor(id),
						valor: this.getValorTempoTipoConfigIntervaloTempoTO(id)
					}
					if (tipoConfigIntervaloTempoTO.tipoIntervaloTempo) {
						salvamentoConfiguracaoTO.valor = JSON.stringify(tipoConfigIntervaloTempoTO);
						listaSalvamentoConfiguracoesTO.push(salvamentoConfiguracaoTO);
					}

				} catch (e) {
					this.logger.error(e);
					console.error(e);
				}

			} else {

				if (configuracaoTO.tipoInput == "CHECKBOX") {
					salvamentoConfiguracaoTO.valorBoolean = this.getValor(id);

				} else if (configuracaoTO.tipoInput == "SELECT_MULTIPLO") {
					salvamentoConfiguracaoTO.valores = this.getValor(id);

				} else if (configuracaoTO.tipoInput == "TEXTAREA_PURO") {
					const { ace } = await UtilBoot.carregarAceCodeEditor();
					salvamentoConfiguracaoTO.valor = ace.edit(id).getValue();

				} else {
					salvamentoConfiguracaoTO.valor = this.getValor(id);
				}

				if (configuracaoTO.tipoInput == "TEXTAREA") {
					const inputTitulo = $("#" + id).parent().find("[titulo]");
					if (inputTitulo.length) {
						salvamentoConfiguracaoTO.tituloValor = $(inputTitulo).val();
					}
				}

				listaSalvamentoConfiguracoesTO.push(salvamentoConfiguracaoTO);
			}
		}

		for (const tipoInput in campoNovoCfgDeTiposMultiplosJaAdicionados) {
			const configuracaoTO = campoNovoCfgDeTiposMultiplosJaAdicionados[tipoInput];
			const id = "id" + configuracaoTO.tipoConfig;

			const salvamentoConfiguracaoTO: any = {
				tipoConfig: configuracaoTO.tipoConfig,
				codEmpresaDaConfiguracao: edicaoConfiguracaoEmpresaTO.codEmpresa,
				valor: this.getValor(id)
			}

			if (configuracaoTO.tipoConfig == "MICROSSERVICO") {
				salvamentoConfiguracaoTO.valor = this.getValorMicrosservico(id);

				if (salvamentoConfiguracaoTO.valor == null) {
					continue;
				}
			}

			if (configuracaoTO.tipoConfig == "PERFIL_PERMITIDO_CADASTRAR") {
				salvamentoConfiguracaoTO.valor = this.getValorPermissao(id);

				if (salvamentoConfiguracaoTO.valor == null) {
					continue;
				}
			}

			if (configuracaoTO.tipoConfig == "URL_ENVIO_RESULTADO_PROVAS") {
				salvamentoConfiguracaoTO.valor = this.getValorEnvioNota(id);

				if (salvamentoConfiguracaoTO.valor == null) {
					continue;
				}
			}

			listaSalvamentoConfiguracoesTO.push(salvamentoConfiguracaoTO);
		}

		const salvamentoCfgsGrupoTO: any = {
			grupo: grupo,
			collectionSalvamentoConfiguracaoTO: listaSalvamentoConfiguracoesTO,
		}

		if (edicaoGrupoConfigTO.cfgsDificuldadeAmbienteTO && grupo == "DIFICULDADE") {
			salvamentoCfgsGrupoTO.salvamentoCadastrosDificuldadesTO = this.getSalvamentoCadastrosDificuldadesTO(edicaoConfiguracaoEmpresaTO.codEmpresa);
		}

		if (edicaoGrupoConfigTO.cfgsTiposComentariosAmbienteTO && grupo == "TIPO_COMENTARIO") {
			salvamentoCfgsGrupoTO.salvamentoTiposComentariosTO = this.getSalvamentoCadastrosTiposComentariosTO(edicaoConfiguracaoEmpresaTO.codEmpresa);
		}

		await this.call("CadastroEmpresaFCD/salvarConfiguracoes", salvamentoCfgsGrupoTO);

		this.mostrarMsgAjax(this.getMsg("FP_FRONT_CadastroEmpresaVH_063"), 3);
		
		if (isRecarregarProps) {
			cadastroEmpresaVH.exibirEdicaoConfigs(edicaoConfiguracaoEmpresaTO.codEmpresa, grupo);
		}
	}

	onChangeSelectTipoConfigIntervaloTempoTO(select) {
		const $select = $(select);
		const id = $select.attr("id");
		const tipoIntervaloTempo = $select.val();
		let regexpValor = null;

		for (const element of this.edicaoGrupoConfigTO.collectionOpcaoListaTOTiposIntervaloTempo) {
			if (element.id == tipoIntervaloTempo) {
				regexpValor = element.regexpValor;
				break;
			}
		}

		const $input = $select.parent().find("[valor-intervalo-tempo]");

		if (regexpValor) {
			if ($input.length == 0) {
				const valorInicial = $select.attr("valor-inicial") || "";
				$select.parent().append("<input type='text' valor-intervalo-tempo='true' class='form-control' value='" + valorInicial + "' onchange='cadastroEmpresaVH.getValorTempoTipoConfigIntervaloTempoTO(\"" + id + "\")' style='width: 50%'></input>");
				$select.parent().find("span.select2").css("width", "50%");
				this.exibir();
				$select.removeAttr("valor-inicial");
			} else {
				$input.val("");
			}
		} else {
			$select.parent().find("span.select2").css("width", "100%");
			$input.remove();
		}

		this.atualizarExibicao();
	}

	getValorTempoTipoConfigIntervaloTempoTO(idSelect) {
		const $select = $("#" + idSelect);
		const $input = $select.parent().find("[valor-intervalo-tempo]");

		if ($input.length == 0) return null;

		const tipoIntervaloTempo = $select.val();

		for (const element of this.edicaoGrupoConfigTO.collectionOpcaoListaTOTiposIntervaloTempo) {

			if (element.id == tipoIntervaloTempo) {
				const valorTempo = $input.val();
				const regexpValor = element.regexpValor;

				if (valorTempo.match(regexpValor)) {
					this.removerMarcacaoDeErroDeInput($input);
					return valorTempo;

				} else {
					this.marcarInputComErro($input);
					throw new Error("Input " + idSelect + " com regexp " + regexpValor + " e valor de tempo inválido: " + valorTempo);
				}
			}
		}
	}

	getOpcaoEscolhidaDeValorPersonalizado(opcoes, valorSalvo) {
		for (const element of opcoes) {
			if (element.id == "INTERVALO_EM_HORAS_MINUTOS_SEGUNDOS" && valorSalvo && AmaisVH.reHoraSegundos.test(valorSalvo)) {
				return "INTERVALO_EM_HORAS_MINUTOS_SEGUNDOS";
			}
		}
		return null;
	}

	async addEdicaoPermissao(id, label, to, retornarHtml, collectionOpcaoListaTOTiposServico, collectionOpcaoListaTOPerfil) {
		const h = [];

		h.push(this.addEspacamentoHorizontal("10px", true));
		h.push(this.addSubsubtitulo({ texto: label, retornarHtml: true }));

		h.push("<div class='form-group col-md-12'>");
		h.push("<span tipo='PERFIL_PERMITIDO_CADASTRAR'>");

		h.push(
			await this.addSelect({
				collection: JSON.parse(JSON.stringify(collectionOpcaoListaTOTiposServico)),
				id: id + "_PERFIS",
				label: "Perfil",
				valor: to.perfil,
				classe: "col-md-5",
				retornarHtml: true,
				onChange: () => this.atualizarExibicao()
			})
		);

		h.push(
			await this.addSelect({
				collection: JSON.parse(JSON.stringify(collectionOpcaoListaTOPerfil)),
				id: id + "_PERFIS_AUTORIZADOS",
				label: this.getMsg("FP_FRONT_CadastroEmpresaVH_064"),
				multiplo: true,
				valor: to.perfisAutorizados,
				classe: "col-md-5",
				retornarHtml: true,
				onChange: () => this.atualizarExibicao()
			})
		);

		h.push("<div class='espacamentoHorizontal' style='height: 1px'></div>");
		h.push("</span>");
		h.push("</div>");

		if (retornarHtml) {
			return h.join("");
		} else {
			this.append(h.join(""));
		}
	}

	getValorPermissao(id) {
		const tipoConfigPerfisPermitidosTO = {
			perfil: this.getValor(id + "_PERFIS"),
			perfisAutorizados: this.getValor(id + "_PERFIS_AUTORIZADOS")
		}

		if (this.hasValue(tipoConfigPerfisPermitidosTO.perfil) || this.hasValue(tipoConfigPerfisPermitidosTO.perfisAutorizados)) {
			return JSON.stringify(tipoConfigPerfisPermitidosTO);
		}

		return null;
	}

	addEdicaoUrlEnvioNota(id, label, to, retornarHtml) {

		const h = [];
		h.push(this.addSubsubtitulo({ texto: label, retornarHtml: true }));

		h.push(`
			<div class='container-group col-md-12'>
				<span tipo='URL_ENVIO_RESULTADO_PROVAS'>
		`);
		h.push(this.addCampoTexto({
			id: id + "_url",
			label: "URL",
			valor: to.url,
			classe: "col-md-6",
			retornarHtml: true,
			onChange: () => this.atualizarExibicao()
		}));
		h.push(this.addCampoTexto({
			id: id + "_token",
			label: "Token",
			valor: to.token,
			classe: "col-md-6",
			retornarHtml: true,
			onChange: () => this.atualizarExibicao()
		}));

		h.push(`
			<div class='espacamentoHorizontal' style='height: 1px'></div>
			</span></div>
		`);

		if (retornarHtml) {
			return h.join("");
		} else {
			this.append(h.join(""));
		}
	}

	async addEdicaoMicrosservico(id, label, to, retornarHtml, collectionOpcaoListaTOTiposServico) {

		const h = [];

		h.push(this.addEspacamentoHorizontal("20px", true));
		h.push(this.addSubsubtitulo({ texto: label, retornarHtml: true }));

		h.push("<div class='form-group col-md-12'>");
		h.push("<span tipo='MICROSSERVICO'>");
		h.push(await this.addSelect({
			collection: JSON.parse(JSON.stringify(collectionOpcaoListaTOTiposServico)), 
			id: id + "_tipo", 
			label: this.getMsg("FP_FRONT_CadastroEmpresaVH_065"), 
			valor: to.tipoServico, 
			classe: "col-md-5", 
			retornarHtml: true, 
			onChange: () => {
				const nome = this.getTextoSelect(id + "_tipo");
				if (this.isEmpty(this.getValor(id + "_nome"))) {
					this.setValor(id + "_nome", nome);
				}
				this.atualizarExibicao();
			}
		}));
		h.push(this.addCampoTexto({ 
			id: id + "_nome", 
			label: this.getMsg("FP_FRONT_CadastroEmpresaVH_066"), 
			valor: to.nome, 
			classe: "col-md-5", 
			retornarHtml: true,
			onChange: () => this.atualizarExibicao()
		}));
		h.push(this.addCampoTexto({ 
			id: id + "_url", 
			label: "URL", 
			valor: to.url, 
			classe: "col-md-12", 
			retornarHtml: true, 
			habilitado: false ,
			onChange: () => this.atualizarExibicao()
		}));

		h.push("<div class='espacamentoHorizontal' style='height: 1px'></div>");

		h.push("</span>");
		h.push("</div>");

		if (retornarHtml) {
			return h.join("");
		} else {
			this.append(h.join(""));
		}
	}

	getValorMicrosservico(id) {
		const tipoConfigMicrosservicoTO = {
			nome: this.getValor(id + "_nome"),
			url: this.getValor(id + "_url"),
			tipoServico: this.getValor(id + "_tipo"),
			isAssincrono: false
		}

		if (this.hasValue(tipoConfigMicrosservicoTO.nome) || this.hasValue(tipoConfigMicrosservicoTO.url)) {
			return JSON.stringify(tipoConfigMicrosservicoTO);
		}

		return null;
	}

	getValorEnvioNota(id) {
		const tipoConfigEnvioResultadoTO = {
			url: this.getValor(id + "_url"),
			token: this.getValor(id + "_token"),
		}

		if (this.hasValue(tipoConfigEnvioResultadoTO.url)) {
			return JSON.stringify(tipoConfigEnvioResultadoTO);
		}

		return null;
	}

	async exibirFiltroCobranca() {

		UtilHash.registrarHistorico(this.exibirFiltroCobranca);

		const collectionItemListaTOEmpresas = await this.call("CadastroEmpresaFCD/listarEmpresaParaCobranca");

		this.limpar()

		this.setTitulo(this.getMsg("FP_FRONT_CadastroEmpresaVH_067"))
		this.setSubtitulo(this.getMsg("FP_FRONT_CadastroEmpresaVH_068"))

		this.addTexto(this.getMsg("FP_FRONT_CadastroEmpresaVH_069"))

		this.setMetodoSubmit(async () => await this.exibirListagemCobranca())

		await this.addSelect({
			collection: collectionItemListaTOEmpresas,
			id: "codEmpresaCliente",
			label: this.getMsg("FP_FRONT_CadastroEmpresaVH_070"),
			onChange: async () => {

				const codEmpresa = this.getValor("codEmpresaCliente");

				if (this.isEmpty(codEmpresa)) {
					return;
				}

				const detalhesCobrancaEmpresaTO = await this.call("CadastroEmpresaFCD/recuperarDetalhesEmpresa", codEmpresa);

				if (this.hasValue(detalhesCobrancaEmpresaTO.valorMensalPorAluno)) {
					this.setValor("cobranca_valor", UtilNumero.floatToString(detalhesCobrancaEmpresaTO.valorMensalPorAluno));
				} else {
					this.setValor("cobranca_valor", this.getMsg("FP_FRONT_CadastroEmpresaVH_071", this.getCfg("LABEL_ALUNO").toLowerCase()));
				}

				if (this.hasValue(detalhesCobrancaEmpresaTO.listItemListaTOPlanos)) {
					this.updateSelect({ id: "idsPlanos", collection: detalhesCobrancaEmpresaTO.listItemListaTOPlanos })
					$("#idsPlanos").prop("disabled", false);
				}
			}
		})

		this.addCampoTexto({ id: "cobranca_valor", label: this.getMsg("FP_FRONT_CadastroEmpresaVH_072", this.getCfg("LABEL_ALUNO").toLowerCase()), habilitado: false, tipo: "MOEDA" })

		this.addCampoTexto({ id: "cobranca_mes", label: this.getMsg("FP_FRONT_CadastroEmpresaVH_073"), valor: UtilData.getMes() })

		this.addCampoTexto({ id: "cobranca_ano", label: this.getMsg("FP_FRONT_CadastroEmpresaVH_074"), valor: UtilData.getAno() })

		await this.addSelect({ 
			collection: [{ descricao: this.getMsg("FP_FRONT_CadastroEmpresaVH_075") }],
			id: "idsPlanos", 
			multiplo: true,
			label: this.getMsg("FP_FRONT_CadastroEmpresaVH_076"), 
			habilitado: false
		});

		this.addEspacamentoHorizontal("1px")
		this.addBotao({ label: this.getMsg("FP_FRONT_CadastroEmpresaVH_077"), onClick: () => this.exibirListagemCobranca(), css: "float:right", classe: "btn-primary" })
		this.addBotao({ label: `&laquo; ${this.getMsg("FP_FRONT_CadastroEmpresaVH_078")}`, onClick: () => this.listarEmpresas() })

		this.append("<div id='listagem_cobranca'></div>")

		this.exibir();
	}

	async exibirListagemCobranca() {

		if (!this.validarCamposObrigatorios("codEmpresaCliente", "cobranca_mes", "cobranca_ano")) {
			return
		}

		const filtroCobrancaAlunoTO = {
			codEmpresaCliente: this.getValor("codEmpresaCliente"),
			mes: this.getValor("cobranca_mes"),
			ano: this.getValor("cobranca_ano"),
			idsPlanos: this.getValor("idsPlanos")
		}

		const relatorioCobrancaEstudoTO = await this.call("CadastroEmpresaFCD/gerarCobrancaMensal", filtroCobrancaAlunoTO);

		this.setHtml("listagem_cobranca", "")

		this.setIdTarget("listagem_cobranca");


		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: this.getCfg("LABEL_MATRICULA"), prop: "matricula" });
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroEmpresaVH_079"), prop: "nome"});
		colunas.push({titulo:  this.getMsg("FP_FRONT_CadastroEmpresaVH_080", this.getCfg("LABEL_ALUNO").toLowerCase()), prop: "codUsuario"});



		if (relatorioCobrancaEstudoTO.exibirPlano) {
			colunas.push({titulo: this.getMsg("FP_FRONT_CadastroEmpresaVH_081"), prop: "plano"});
		}

		if (relatorioCobrancaEstudoTO.exibirVigencia) {
			colunas.push({titulo: this.getMsg("FP_FRONT_CadastroEmpresaVH_082"), prop: "inicioVigencia", formato: "DD/MM/YY"});
			colunas.push({titulo: this.getMsg("FP_FRONT_CadastroEmpresaVH_083"), prop: "fimVigencia", formato: "DD/MM/YY"});
		}

		if (relatorioCobrancaEstudoTO.exibirDiasVigentes) {
			colunas.push({titulo: this.getMsg("FP_FRONT_CadastroEmpresaVH_084"), prop: "diasVigentes"});
		}

		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroEmpresaVH_085"), prop: "valorCobrado", formato: "moeda"});

		await this.addTabela({
			collection: relatorioCobrancaEstudoTO.collectionListagemCobrancaAlunoTO,
			colunas: colunas,
			totalizar: ["valorCobrado"],
			ordenar: false
		})

		this.setIdTarget(null)
		this.exibir();
		UtilWindow.scrollTo("#listagem_cobranca");
	}

	async excluirLogomarca(codEmpresa) {
		await this.exibirAlerta({
			msg: this.getMsg("FP_FRONT_CadastroEmpresaVH_086"),
			botoes: [
				{
					label: this.getMsg("MSG_VH_004"),
					classe: "btn-primary",
					onClick: async () => {
						await this.call("CadastroEmpresaFCD/excluirLogomarca", codEmpresa);
						this.exibirGruposConfiguracao(0, codEmpresa);
					}
				},
				{label: this.getMsg("MSG_VH_005"),}
			],
		});
	}

	exibirCadastroDificuldade(cfgsDificuldadeAmbienteTO) {

		this.append("<div class='col-md-6'>");

		for (const cadastroDificuldadeTO of cfgsDificuldadeAmbienteTO.collectionCadastroDificuldadeTO) {
			this.append(`<div class="col-md-12" data-fp-id="${cadastroDificuldadeTO.id}" data-grau-dificuldade="${cadastroDificuldadeTO.grauDificuldade}">`);
			this.addCampoTexto({
				label: this.getMsg("FP_FRONT_CadastroEmpresaVH_125", cadastroDificuldadeTO.titulo),
				classe: "col-md-8",
				id: `nome_dificuldade_${cadastroDificuldadeTO.grauDificuldade}`,
				valor: cadastroDificuldadeTO.nome
			});

			if (cadastroDificuldadeTO.grauDificuldade) {
				this.addCampoTexto({
					label: this.getMsg("FP_FRONT_CadastroEmpresaVH_088"),
					classe: "col-md-3",
					id: `pontuacao_padrao_dificuldade_${cadastroDificuldadeTO.grauDificuldade}`,
					valor: cadastroDificuldadeTO.pontuacaoPadrao,
					tipo: "NUMERO"
				});
			}

			this.append("</div>");
		}

		this.append("</div>");
	}

	getSalvamentoCadastrosDificuldadesTO(codEmpresa) {

		this.verificarObrigatorios();

		const salvamentoCadastrosDificuldadesTO = {
			codEmpresa: codEmpresa,
			collectionCadastroDificuldadeTO: [],
		};

		$("[data-fp-id]").each((i, div) =>  {
			const $d = $(div);

			if ($d.data("fp-is-excluido")) return;

			const grauDificuldade = $d.data("grau-dificuldade");

			const cadastroDificuldadeTO = {
				id: $d.data("fp-id"),
				grauDificuldade: grauDificuldade,
				nome: cadastroEmpresaVH.getValor($d.find(`#nome_dificuldade_${grauDificuldade}`)),
				pontuacaoPadrao: cadastroEmpresaVH.getValor($d.find(`#pontuacao_padrao_dificuldade_${grauDificuldade}`)),
			}

			if (this.hasValue(cadastroDificuldadeTO.pontuacaoPadrao) && this.isEmpty(cadastroDificuldadeTO.nome)) {
				$d.find(`#nome_dificuldade_${grauDificuldade}`).closest(".form-group").addClass("has-error");
				amaisVH.exibirAlerta({
					msg: amaisVH.getMsg("FP_FRONT_CadastroEmpresaVH_123"),
					titulo: `<i class='fa fa-exclamation-triangle'></i> ${amaisVH.getMsg("FP_FRONT_CadastroEmpresaVH_124")}`,

				});
				throw amaisVH.getMsg("FP_FRONT_CadastroEmpresaVH_123");
			}

			if (cadastroDificuldadeTO.nome) {
				salvamentoCadastrosDificuldadesTO.collectionCadastroDificuldadeTO.push(cadastroDificuldadeTO);
			}

		});

		return salvamentoCadastrosDificuldadesTO;
	}

	marcarDificuldadeComoRemovida(event) {
		const $botao = $(event.target);
		$botao.closest("[data-fp-id]").data("fp-is-excluido", true).slideUp();
	};

	marcarComentarioComoRemovido(id) {
		const $botao = $("#"+id);
		$botao.closest("[data-fp-id]").data("fp-is-excluido", true).slideUp();
	};

	exibirCadastroTipoComentario(cfgsTiposComentariosAmbienteTO) {

		this.append("<div class='col-md-6'>");

		if (!cfgsTiposComentariosAmbienteTO.listaTipoComentarioAmbienteTO.some(to => to.isPublico === false)) {
			this.append(`<div class='col-md-12'>`);
			this.addCampoTexto({ label: this.getMsg("FP_FRONT_CadastroEmpresaVH_094"), classe: "col-md-8", valor: cfgsTiposComentariosAmbienteTO.nomeTipoComentarioPrivadoPadrao, habilitado: false });
			this.addCheckbox({ label: this.getMsg("FP_FRONT_CadastroEmpresaVH_095"), classe: "col-md-3", valor: false, habilitado: false });
			this.append("</div>");
		}

		if (!cfgsTiposComentariosAmbienteTO.listaTipoComentarioAmbienteTO.some(to => to.isPublico === true)) {
			this.append(`<div class='col-md-12'>`);
			this.addCampoTexto({ label: this.getMsg("FP_FRONT_CadastroEmpresaVH_096"), classe: "col-md-8", valor: cfgsTiposComentariosAmbienteTO.nomeTipoComentarioPublicoPadrao, habilitado: false });
			this.addCheckbox({ label: this.getMsg("FP_FRONT_CadastroEmpresaVH_097"), classe: "col-md-3", valor: true, habilitado: false });
			this.append("</div>");
		}

		cfgsTiposComentariosAmbienteTO.listaTipoComentarioAmbienteTO.forEach(tipoComentarioAmbienteTO => {
			this.append(`<div class='col-md-12' data-fp-id='${tipoComentarioAmbienteTO.codTipoComentario || ""}'>`);
			this.addCampoTexto({ label: this.getMsg("FP_FRONT_CadastroEmpresaVH_098"), classe: "col-md-8", valor: tipoComentarioAmbienteTO.nome, obrigatorio: true });
			this.addCheckbox({ label: this.getMsg("FP_FRONT_CadastroEmpresaVH_099"), classe: "col-md-3", valor: tipoComentarioAmbienteTO.isPublico });
			this.addBotao({ label: "X", classe: "col-md-1", css: "margin-top: 24px", dica: this.getMsg("FP_FRONT_CadastroEmpresaVH_100"), onClick: (event: Event) => this.marcarDificuldadeComoRemovida(event) });
			this.append("</div>");
		});

		this.addBotao({
			id: "btn_novo_tipo_comentario",
			label: "<i class='fa fa-plus-circle'></i> Novo", css: "float: right", onClick: (event) => {
				let idBotaoRemover = this.gerarId();
				const h = ["<div class='col-md-12' data-fp-id>"];
				this.addFormulario();
				h.push(this.addCampoTexto({ label: this.getMsg("FP_FRONT_CadastroEmpresaVH_101"), classe: "col-md-8", dicaComoValorInicial: this.getMsg("FP_FRONT_CadastroEmpresaVH_103"), obrigatorio: true, retornarHtml: true }));
				h.push(this.addCheckbox({ label: this.getMsg("FP_FRONT_CadastroEmpresaVH_102"), classe: "col-md-3", valor: false, retornarHtml: true }));
				h.push(this.addBotao({
					id: idBotaoRemover,
					label: "X",
					classe: "col-md-1",
					css: "margin-top: 24px",
					onClick: () => {
						this.marcarComentarioComoRemovido(idBotaoRemover);
					},
					retornarHtml: true
				}));
				h.push("</div>");
				this.fecharFormulario();
				$(h.join("")).insertBefore($("#btn_novo_tipo_comentario").closest("a"));
				this.exibir();
			}
		});

		this.append("</div>");
	}

	getSalvamentoCadastrosTiposComentariosTO(codEmpresa) {

		this.verificarObrigatorios();

		const salvamentoCadastrosTiposComentariosTO = {
			codEmpresa: codEmpresa,
			collectionCadastroTipoComentarioTO: [],
		};

		$("[data-fp-id]").each((i, divTipoComentario) => {
			const $d = $(divTipoComentario);

			if ($d.data("fp-is-excluido") === true) {
				return;
			}

			salvamentoCadastrosTiposComentariosTO.collectionCadastroTipoComentarioTO.push({
				codTipoComentario: $d.data("fp-id"),
				nome: cadastroEmpresaVH.getValor($d.find("input:first")),
				isPublico: cadastroEmpresaVH.getValor($d.find("input:last")),
			});
		});

		return salvamentoCadastrosTiposComentariosTO;
	}

	async editarContrato(codEmpresa: number, codEmpresaContrato?: number) {
		
		this.addPopup({
			id: "exibicaoListagemContratos",
			titulo: codEmpresaContrato ?  this.getMsg('FP_FRONT_CadastroEmpresaVH_107') : this.getMsg('FP_FRONT_CadastroEmpresaVH_108'),
			width: "50%",
			botoes: [{ 
				label: this.getMsg("FP_FRONT_CadastroEmpresaVH_041"), 
				onClick: async () => {
					if (!this.validarCamposObrigatorios("contratoInicioVigencia", "contratoFimVigencia")) {
						return false;
					}
					const inicioVigencia = this.getValor("contratoInicioVigencia");
					const fimVigencia = this.getValor("contratoFimVigencia");

					if (inicioVigencia > fimVigencia) {
						await this.exibirAlerta({msg: this.getMsg("FP_FRONT_CadastroEmpresaVH_119")});
						return false;
					}
					await this.salvarContrato(codEmpresa);
				},
				classe: 'btn-primary'
			}]
		});

		let empresaContratoTO: EmpresaContratoTO = null;

		if (codEmpresaContrato) {
			empresaContratoTO = await this.call("CadastroEmpresaFCD/recuperarContrato", codEmpresaContrato);
			this.addInputHidden("codEmpresaContrato", empresaContratoTO.codEmpresaContrato);
		}
		
		this.addCampoTexto({
			id: "cnpjContratante",
			label: "CNPJ da contratante",
			valor: empresaContratoTO?.cnpjContratante,
			obrigatorio: true,
			tipo: "CNPJ"
		});		
		this.addCampoTexto({
			id: "razaoSocialContratante",
			label: "Razão social da contratante",
			valor: empresaContratoTO?.razaoSocialContratante,
			obrigatorio: true,
		});
		this.addCampoTexto({
			id: "contratoInicioVigencia",
			label: this.getMsg("FP_FRONT_CadastroEmpresaVH_046"),
			valor: empresaContratoTO?.inicioVigencia,
			tipo: "DATA",
			obrigatorio: true,
		});
		this.addCampoTexto({
			id: "contratoFimVigencia",
			label: this.getMsg("FP_FRONT_CadastroEmpresaVH_047"),
			valor: empresaContratoTO?.fimVigencia,
			tipo: "DATA",
			obrigatorio: true,
		});
		this.append("<div class='container-centered'>");

		const updateHabilitados = () => {
			if(this.getValor("contratoRenovavelAutomaticamente") === true) {
				this.disable("contratoToleranciaFimContrato");
				this.setValor("contratoToleranciaFimContrato", null);
			} else {
				this.enable("contratoToleranciaFimContrato");
			}
		}
		
		this.addCheckbox({
			classe: "col-md-6",
			id: "contratoRenovavelAutomaticamente",
			label: this.getMsg("FP_FRONT_CadastroEmpresaVH_131"),
			valor: empresaContratoTO?.isRenovavelAutomaticamente,
			onChange: () => {
				updateHabilitados();
			}
		});
		this.addCampoTexto({
			classe: "col-md-6",
			id: "contratoToleranciaFimContrato",
			maxLength: 2,
			label: this.getMsg('FP_FRONT_CadastroEmpresaVH_110'),
			sufixo: "dias",
			valor: empresaContratoTO?.isRenovavelAutomaticamente === false ? empresaContratoTO?.toleranciaFimContrato: null,
			tipo: "NUMERO"
		});
		this.append("</div>");

		this.exibirPopups();

		updateHabilitados();
	}

	async salvarContrato(codEmpresa: number) {
		const empresaContratoTO: EmpresaContratoTO = {
			toleranciaFimContrato: this.getValor("contratoToleranciaFimContrato"),
			inicioVigencia: this.getValor("contratoInicioVigencia"),
			fimVigencia: this.getValor("contratoFimVigencia"),
			codEmpresaContrato: this.getValor("codEmpresaContrato"),
			cnpjContratante: this.getValor("cnpjContratante"),
			razaoSocialContratante: this.getValor("razaoSocialContratante"),
			codEmpresa: codEmpresa,
			isRenovavelAutomaticamente: this.getValor("contratoRenovavelAutomaticamente")
		}

		await this.call("CadastroEmpresaFCD/salvarContrato", empresaContratoTO);

		await this.exibirGruposConfiguracao(1, codEmpresa);
		await this.fecharPopup("exibicaoListagemAdministradores");
	}

	async excluirContrato(codEmpresaContrato: number) {
		await this.exibirAlerta({
			titulo: `<i class='fa fa-exclamation-circle'></i> ${this.getMsg('MSG_VH_AP_44')}`,
			msg: this.getMsg('FP_FRONT_CadastroEmpresaVH_111'),
			botoes: [
				{
					label: this.getMsg('FP_FRONT_CadastroEmpresaVH_112'),
					classe: "btn-danger",
					onClick: async () => {
						await this.call("CadastroEmpresaFCD/excluirContrato", codEmpresaContrato);
						document.location.reload();
					}
				}, {label: this.getMsg('FP_FRONT_CadastroEmpresaVH_113')}
			]
		});
	}

	async excluirUsuario(codUsuario: number) {
		this.exibirAlerta({
			titulo: `<i class='fa fa-exclamation-circle'></i> ${this.getMsg('MSG_VH_AP_44')}`,
			msg: 'Deseja realmente excluir esse usuário?',
			botoes: [
				{
					label: "Excluir usuário",
					classe: "btn-danger",
					onClick: async () => {
						await this.call("CadastroEmpresaFCD/excluirAdministrador", codUsuario);
						document.location.reload();
					}
				}, {label: this.getMsg('FP_FRONT_CadastroEmpresaVH_113')}
			]
		});
	}
}

const cadastroEmpresaVH = new CadastroEmpresaVH();

type EmpresaContratoTO = {
	codEmpresa: number;
	codEmpresaContrato: number;
	toleranciaFimContrato: number;
	inicioVigencia: string;
	fimVigencia: string;
	razaoSocialContratante: string;
	cnpjContratante: string;
	isRenovavelAutomaticamente: boolean;
	situacaoContrato?: string;
}

type SalvamentoConfiguracaoTO = {
	codEmpresaConfig: number;
	tipoConfig: string;
	valor: any;
	tituloValor: string;
	valores: string[];
	valorBoolean: boolean;
	valorDate: string;
	codEmpresaDaConfiguracao: number;
}