class MonSaidaAbaRecursosVH extends AmaisVH {
	
	static IS_DENTRO_DE_IFRAME: boolean;
	
	idIntervalContagemSaidaNavegador: any;
	isTratandoSaidaNavegador: boolean;
	isExibindoMsgSaidaNavegador: boolean;
	isExecutandoOnVolta: boolean;
	codProvaFeita: number;
	numSaidas: number = 0;
	segundosToleranciaMonitoramento: number = 0;

	constructor() {
		super(MonitoramentoPFVH.name);
	}

	async monitorarSaidaDoNavegador(codProvaFeita: number, segundosToleranciaMonitoramento, numSaidas) {

		if (!codProvaFeita) return;

		this.numSaidas = numSaidas || 0;
		this.codProvaFeita = codProvaFeita;
		this.segundosToleranciaMonitoramento = segundosToleranciaMonitoramento;

	    $("iframe").off("mouseover").on("mouseover", (e) => {
			MonSaidaAbaRecursosVH.IS_DENTRO_DE_IFRAME = true;
	    });

	    $("iframe").off("mouseout").on("mouseout", (e) => {
			MonSaidaAbaRecursosVH.IS_DENTRO_DE_IFRAME = false;
	    });

	    $(window).off("blur").on("blur", async ({target}) => {
			if (this.isJanelaUploadAberta()) return;
			if (MonSaidaAbaRecursosVH.IS_DENTRO_DE_IFRAME) return;
			await this.onSaidaDoNavegador("Saiu da aba no navegador.");
	    });
		
		$(document)
			.off("container-alterado", MonSaidaAbaRecursosVH.registrarNavegacaoParaOutraTela)
			.on("container-alterado", MonSaidaAbaRecursosVH.registrarNavegacaoParaOutraTela)
			.off("fp-pre-logoff", MonSaidaAbaRecursosVH.registrarLogoff)
			.on("fp-pre-logoff", MonSaidaAbaRecursosVH.registrarLogoff)
			;
	}

	static async registrarNavegacaoParaOutraTela() {
		
		if ($('#interpor-recurso').length === 0) {
	
			// if (aberturaRecursosVH.isEncerradaOuEncerrando()) return;
			await aberturaRecursosVH.salvarInterposicoes();
			await monSaidaAbaRecursosVH.onSaidaDoNavegador("Navegou para outra tela.");
			
			try {
				await fiscalizacaoMonitoradaVH.pararMonitoramento();
			} catch (ignored) {}

			$(document).off("container-alterado", MonSaidaAbaRecursosVH.registrarNavegacaoParaOutraTela);
		}
		
	}

	static async registrarLogoff() {
		
		if ($('#interpor-recurso').length === 1) {
	
			// if (aberturaRecursosVH.isEncerradaOuEncerrando()) return;
			await aberturaRecursosVH.salvarInterposicoes();
			await monSaidaAbaRecursosVH.onSaidaDoNavegador("Fez logoff.");
			try {
				await fiscalizacaoMonitoradaVH.pararMonitoramento();
			} catch (ignored) {}
		}

		$(document).off("fp-pre-logoff", MonSaidaAbaRecursosVH.registrarLogoff);
	}

	desativarMonitoramentoSaidaDoNavegador() {
	    $(window).off("blur").off("focus");
		window.onbeforeunload = null;
	}
	
	async onSaidaDoNavegador(detalhes) {
		if (!$('#interpor-recurso').length) {
			this.desativarMonitoramentoSaidaDoNavegador();
			return;
		}
		
		if (this.isTratandoSaidaNavegador) return;
		
		try {
			this.isTratandoSaidaNavegador = true;
			
			if (this.isExibindoMsgSaidaNavegador) return;

	
			if (this.codProvaFeita == null) return;

			const saidaNavegadorTO = {
				codProvaFeita: this.codProvaFeita,
				detalhes: detalhes
			};

			try {
				await this.call({
					endpoint: "MonitoramentoRecursosFCD/registrarSaidaNavegador",
					params: [saidaNavegadorTO],
					desativarMsgCarregando: true,
					onRequestError: async () => BackendRequestError.ERRO_TRATADO
				});
			} catch (ignored) {
			}

			this.numSaidas++;

			if (!this.segundosToleranciaMonitoramento) return;
			
			this.isExibindoMsgSaidaNavegador = true;
			
			if (this.numSaidas >= 3) {
				await this.onRegrasMonitoramentoVioladas(this.getMsg("FP_FRONT_MonitoramentoPFVH_002"));
				return;
			}

			this.exibirAlerta({
				id: "saida_navegador_alerta",
				titulo: "<span class='text-danger'><i class='fa fa-ban'></i> " + this.getMsg("MSG_VH_APR_169") + "</span>",
				msg: "<p>" + this.getMsg("MSG_VH_APR_170") + " <strong><span id='saida-navegador-tempo-restante' class='label label-danger' style='font-size: 110%'>" + this.segundosToleranciaMonitoramento + "</span> " + this.getMsg("FP_FRONT_MonSaidaAbaRecursosVH_003") + "</p>"
					+ (this.numSaidas == 2 ? "<p>" + this.getMsg("FP_FRONT_MonSaidaAbaRecursosVH_004") + "</strong>.</p>" : ""),
				botoes: [{label: this.getMsg("MSG_VH_APR_173")}]
			}).then(async () => {
				await this.onVoltaAoNavegador();
			});
	
	    	clearInterval(this.idIntervalContagemSaidaNavegador);
	    	
	    	this.idIntervalContagemSaidaNavegador = setInterval(async () => {
				let tempoRestante = Number($("#saida-navegador-tempo-restante").text());
				tempoRestante = tempoRestante - 1;
	
				if (tempoRestante <= -1) {
					await this.onRegrasMonitoramentoVioladas(this.getMsg("FP_FRONT_MonitoramentoPFVH_007"));
				} else {
					$("#saida-navegador-tempo-restante").text(tempoRestante);
				}
			}, 1000);
			
		} finally {
			this.isTratandoSaidaNavegador = false;
		}
	}

	async onVoltaAoNavegador(isAnulacao: boolean = false) {

		if (this.isExecutandoOnVolta) return;

		this.isExecutandoOnVolta = true;

		if (this.isExibindoMsgSaidaNavegador) {
			this.isExibindoMsgSaidaNavegador = false;
			$("#saida_navegador_alerta").modal("hide");
			clearInterval(this.idIntervalContagemSaidaNavegador);
		}


		if (!isAnulacao) {

			try {
				const voltaNavegadorTO = {
					codProvaFeita: this.codProvaFeita,
				};
				await this.call({
					endpoint: "MonitoramentoRecursosFCD/registrarVoltaNavegador",
					params: [voltaNavegadorTO],
					desativarMsgCarregando: true,
					onRequestError: async () => BackendRequestError.ERRO_TRATADO
				});
			} catch (ignored) {

			} finally {
				this.isExecutandoOnVolta = false;
			}

		} else {
			this.isExecutandoOnVolta = false;
		}
	}

	async onRegrasMonitoramentoVioladas(regraMonitoramentoViolada) {

		this.desativarMonitoramentoSaidaDoNavegador();
		await this.onVoltaAoNavegador();

		this.logger.info("onRegrasMonitoramentoVioladas");

		await aberturaRecursosVH.salvarInterposicoes();
		this.call("RecursoFCD/encerrarInterposicaoRecursos", this.codProvaFeita, regraMonitoramentoViolada); // não espera para já mostrar a popup
		
		this.fecharTodasPopups();

		await this.exibirAlerta({
			msg: this.getMsg("FP_FRONT_MonSaidaAbaRecursosVH_002"),
			botoes: [{label: "OK"}]
		});		

		UtilHash.carregarTelaInicial();
	}
}

const monSaidaAbaRecursosVH = new MonSaidaAbaRecursosVH();