class ProctoringService extends AmaisVH {

	fiscalizacaoMonitoradaTO: FiscalizacaoMonitoradaTO;

	constructor(name: string) {
		super(name);
	}

	static getInstance(fiscalizacaoMonitoradaTO: FiscalizacaoMonitoradaTO) {
		let instancia: ProctoringService = null;
		if (fiscalizacaoMonitoradaTO.proctoringId == "mettl") {
			instancia = new MettlProctoringService();
		} else if (fiscalizacaoMonitoradaTO.proctoringId == "fp") {
			instancia = new FPProctoringService();
		} else if (fiscalizacaoMonitoradaTO.proctoringId == "neft") {
			instancia = new NeftProctoringService();
		}
		if(instancia){
			instancia.fiscalizacaoMonitoradaTO = fiscalizacaoMonitoradaTO;
		}
		return instancia;
	}

	getMsg(idMsg: string): string {
		return UtilMsg.getMsg(idMsg);
	}
	
	async pedirAutorizacoesUsuario(onAutorizacoesConcedidas: Function) { 
		throw "Implementar."; 
	}
	
	iniciarGravacao() { 
		throw "Implementar."; 
	}
	
	isIniciado(): boolean {
		throw "Implementar.";
	}
	
	async parar() {
		throw "Implementar.";
	}
	
	enviarMsgChat(msg: string) {
		throw "Implementar.";
	}

	onMsgChat(f: (e: EventoMsgChat) => void) {
		$(this).off(TipoEvento.MSG_CHAT).on(TipoEvento.MSG_CHAT, (jqueryEvent: any, eventoMsgChat: EventoMsgChat) => {
			f(eventoMsgChat);
		});
	}

	onInterrupcao(f: () => void) {
		$(this).off(TipoEvento.INTERRUPCAO).on(TipoEvento.INTERRUPCAO, () => {
			f();
		});
	}

	onFinalizado(f: (e: EventoFinalizado) => void) {
		$(this).off(TipoEvento.FINALIZADO).on(TipoEvento.FINALIZADO, (jqueryEvent: any, eventoFinalizado: EventoFinalizado) => {
			f(eventoFinalizado);
		});
	}

	onErro(f: (e: EventoErro) => void) {
		$(this).off(TipoEvento.ERRO).on(TipoEvento.ERRO, (jqueryEvent: any, eventoErro: EventoErro) => {
			f(eventoErro);
		});
	}

	onMsgLog(f: (e: EventoMsgLog) => void) {
		$(this).off(TipoEvento.MSG_LOG).on(TipoEvento.MSG_LOG, (jqueryEvent: any, eventoMsgLog: EventoMsgLog) => {
			f(eventoMsgLog);
		});
	}

	triggerMsgChat(eventoMsgChat: EventoMsgChat) {
		$(this).trigger(TipoEvento.MSG_CHAT, eventoMsgChat);
	}

	triggerInterrupcao() {
		$(this).trigger(TipoEvento.INTERRUPCAO);
	}

	triggerFinalizado(eventoFinalizado: EventoFinalizado) {
		$(this).trigger(TipoEvento.FINALIZADO, eventoFinalizado);
	}

	triggerErro(eventoErro: EventoErro) {
		$(this).trigger(TipoEvento.ERRO, eventoErro);
	}

	triggerMsgLog(eventoMsgLog: EventoMsgLog) {
		$(this).trigger(TipoEvento.MSG_LOG, eventoMsgLog);
	}
}

enum TipoEvento {
	MSG_CHAT = "MSG_CHAT",
	INTERRUPCAO = "INTERRUPCAO",
	ERRO = "ERRO",
	MSG_LOG = "MSG_LOG",
	FINALIZADO = "FINALIZADO"
}

interface EventoMsgChat {
	msg: string
}

interface EventoErro {
	msgUsuario: string;
	msgAuditoria: string;
	msgIntegracaoAuditoria: any;
}

interface EventoFinalizado {
	msgUsuario: string;
	msgAuditoria: string;
	msgIntegracaoAuditoria: any;
}

interface EventoMsgLog {
	msg: string;
	msgIntegracao: any;
}