class AberturaRecursosVH extends AmaisVH {

	recursoAtual = 0;

	constructor() {
		super(AberturaRecursosVH.name);
		this.addOperacaoParaHash("redr", this.verificarPreCondicoesRecursos);
	}

	async verificarPreCondicoesRecursos(codProvaFeita: number, isPosEncerramentoDeProva: boolean = false) {

		UtilHash.registrarHistorico(this.verificarPreCondicoesRecursos, codProvaFeita, isPosEncerramentoDeProva);
		
		this.limpar();
		this.setTitulo(this.getMsg("FP_FRONT_RecursoVH_042"));
		
		const to: CfgsAberturaRecursosTO = await this.call("RecursoFCD/recuperarCfgsAberturaRecursos", codProvaFeita);

		try {

			await this.ativarProctoringRecursos(to);
			await this.apresentarOpcoesFaseRecursos(to, isPosEncerramentoDeProva);

			const jaApresentouInstrucoes = await this.apresentarOpcoesFaseAplicacao(to);

			await this.exibirLiberacaoFiscal(to);

			if (!jaApresentouInstrucoes) {
				await this.exibirInstrucoesRecursos(to);
			}

			await this.interporRecurso(to.codProvaFeita);
			
		} catch (e) {
			this.logger.error("Erro ao verificar pré-condições para recursos", e);
			await this.sairDaTelaDeRecursos(codProvaFeita);
		}
	}

	async ativarProctoringRecursos(cfgsAberturaRecursosTO: CfgsAberturaRecursosTO) {

		if (!cfgsAberturaRecursosTO.isProctoringHabilitado) return;

		if (fiscalizacaoMonitoradaVH.isIniciada()) return;
		
		await new Promise<void>((resolve) => {

			fiscalizacaoMonitoradaVH.iniciarMonitoramento({
				codAgendamentoUsuario: cfgsAberturaRecursosTO.codAgendamentoUsuario,
				codProvaFeita: cfgsAberturaRecursosTO.codProvaFeita,
				isTelaRecursos: true,
				onMonitoramentoIniciado: async () => {
					resolve();
				},
				onMonitoramentoInterrompido: async () => {
					await this.exibirAlerta({
						titulo: this.getMsg("MSG_VH_APR_190"),
						msg: this.getMsg("MSG_VH_APR_191"),
						botoes: [{label: "OK"}]
					});
					await this.sairDaTelaDeRecursos(cfgsAberturaRecursosTO.codProvaFeita);
				},
				onErroMonitoramento: (msgErroParaUsuario, msgErroParaAuditoria, msgErroIntegracaoParaAuditoria, codProvaFeita, codAgendamentoUsuario) => {
					pausaPFVH.onErroFiscalizacaoMonitorada(msgErroParaUsuario, msgErroParaAuditoria, msgErroIntegracaoParaAuditoria, codProvaFeita, codAgendamentoUsuario);
				},
				onMonitoramentoFinalizado: (msgErroParaUsuario, msgErroParaAuditoria, msgErroIntegracaoParaAuditoria, codProvaFeita, codAgendamentoUsuario) => {
					pausaPFVH.onErroFiscalizacaoMonitorada(msgErroParaUsuario, msgErroParaAuditoria, msgErroIntegracaoParaAuditoria, codProvaFeita, codAgendamentoUsuario);
				},
				onEventoParaRegistroEmLog: (msgErro, msgErroIntegracao, codProvaFeita, codAgendamentoUsuario) => {
					this.logger.error(msgErro)
					this.logger.error(msgErroIntegracao)
					// monitoramentoPFVH.onEventoParaRegistroEmLog(msgErro, msgErroIntegracao, codProvaFeita, codAgendamentoUsuario);
				},
			});

		});
	}

	async apresentarOpcoesFaseRecursos(to: CfgsAberturaRecursosTO, isPosEncerramentoDeProva: boolean) {

		if (to.tipoFaseRecurso !== TipoFaseRecurso.FASE_RECURSO) return;
		if (to.situacaoRecursos === SituacaoRecursos.EM_RECURSOS) return;
		
		if (!isPosEncerramentoDeProva) return;

		return new Promise<void>((resolve, reject) => {
				
			this.setSubtitulo(to.tituloProva);

			if (to.isFaseRecursosVigente) {
				this.addTexto(this.getMsg("FP_FRONT_RecursoVH_045"));
				this.addTexto(this.getMsg("FP_FRONT_RecursoVH_046", UtilData.toDDMMYYYYHHMM(to.dataFim)));
			} else {
				this.addTexto(this.getMsg("FP_FRONT_RecursoVH_047", UtilData.toDDMMYYYYHHMM(to.dataInicio), UtilData.toDDMMYYYYHHMM(to.dataFim)));
			}

			let classeCol = "col-md-6";

			if (to.isFaseRecursosVigente) {
				classeCol = "col-md-4";
			}

			const botoes: CfgsAddBotao[] = [];

			if (!to.podeAgendarRecursos) {
				botoes.push({
					label: "Quero voltar em outro horário",
					classe: `btn-lg ${classeCol}`,
					onClick: async () => {
						reject(new Error("Avaliado optou por voltar em outro horário para os recursos"));						
					}
				});
			}

			botoes.push({
				label: this.getMsg(this.getMsg("FP_FRONT_RecursoVH_048")),
				classe: `btn-lg ${classeCol}`,
				onClick: async () => {

					if (to.podeAgendarRecursos) {

						await this.exibirAlerta({
							msg: this.getMsg("FP_FRONT_RecursoVH_049"),
							botoes: [{
								label: this.getMsg("FP_FRONT_RecursoVH_050"),
							}, {
								label: this.getMsg("FP_FRONT_RecursoVH_051"),
								classe: "btn-primary",
								onClick: async () => {
									await this.call("RecursoFCD/recusarAgendamentoRecurso", to.codProvaFeita);
									reject(new Error("Não tem interesse em recursos e não quis agendar."));
								}
							}]
						});
						
					} else if (await this.confirmouDesistencia(to.codProvaFeita)) {
						reject(new Error("Encerramento de recursos pois avaliado não tem interesse."));
					}
				}
			});

			if (to.podeAgendarRecursos) {
				botoes.push({
					label: this.getMsg("FP_FRONT_RecursoVH_052"),
					classe: `btn-lg ${classeCol} ${!to.isFaseRecursosVigente ? 'btn-primary' :''}`,
					onClick: async () => {
						
						this.addPopup({
							id: "dataAgendamentoRecurso",
							titulo: this.getMsg("FP_FRONT_RecursoVH_053"),
							cssContent: 'overflow: visible !important',
							botoes: [{
								label: this.getMsg("FP_FRONT_RecursoVH_050"),
								onClick: async () => {
									await this.fecharPopup("dataAgendamentoRecurso")
								}
							}, {
								label: this.getMsg("FP_FRONT_RecursoVH_054"),
								classe: "btn-primary",
								onClick: async () => {
									this.verificarObrigatorios();
									const dataRecursos = this.getValor("dataRecursos");
									await this.call("RecursoFCD/agendarAberturaRecursos", dataRecursos, to.codProvaFeita);
									await this.fecharPopup("dataAgendamentoRecurso")

									await this.exibirAlerta({
										titulo: this.getMsg("FP_FRONT_RecursoVH_043"),
										msg: this.getMsg("FP_FRONT_RecursoVH_055", UtilData.toDDMMYYYYHHMM(dataRecursos))
									});

									reject(new Error("Avaliado agendou recursos."));
								}
							}]
						})

						this.addTexto(this.getMsg("FP_FRONT_RecursoVH_056"));

						this.addCampoTexto({
							label: this.getMsg("FP_FRONT_RecursoVH_057"),
							id: "dataRecursos",
							tipo: "DATAHORA",
							classe: "col-md-12",
							obrigatorio: true
						});

						this.exibirPopups();
					}
				});
			}
			
			if (to.isFaseRecursosVigente) {
				botoes.push({
					label: this.getMsg("FP_FRONT_RecursoVH_058"),
					classe: `btn-lg ${classeCol} btn-success`,
					onClick: async () => {
						resolve();
					}
				});
			}

			this.addGrupoBotoes({
				botoes,
				fullWidth: true
			});

			this.exibir();
		});
	}

	async apresentarOpcoesFaseAplicacao(to: CfgsAberturaRecursosTO): Promise<boolean> {

		if (to.tipoFaseRecurso !== TipoFaseRecurso.FASE_APLICACAO) return false;

		if ([
			SituacaoRecursos.EM_RECURSOS,
			SituacaoRecursos.ENCERRADO_SEM_INTERESSE,
			SituacaoRecursos.ENCERRADO
		].includes(to.situacaoRecursos)) {
			throw new Error("Não pode prosseguir com recursos na situação " + to.situacaoRecursos.id);
		}

		return new Promise<boolean>(async (resolve, reject) => {

			this.setSubtitulo(to.tituloProva);

			await this.addComponentesInstrucoesRecursos(to);

			this.addGrupoBotoes({
				css: "width: 100%",
				botoes: [{
					label: this.getMsg("FP_FRONT_RecursoVH_048"),
					classe: "btn-lg col-md-6",
					onClick: async () => {
						if (await this.confirmouDesistencia(to.codProvaFeita)) {
							reject(new Error("Encerramento de recursos pois avaliado não tem interesse."));
						}
					}
				}, {
					id: "botao_interpor_recursos",
					label: this.getMsg("FP_FRONT_RecursoVH_058"),
					classe: "btn-lg col-md-6 btn-success",
					onClick: async () => {
						resolve(true);
					},
				}]
			});

			this.append("</div>");

			this.exibir();
		});
	}

	async exibirLiberacaoFiscal(to: CfgsAberturaRecursosTO): Promise<void> {

		if (to.situacaoRecursos !== SituacaoRecursos.AGUARDANDO_LIBERACAO_FISCAL) return;
		if (!to.deveAguardarLiberacaoFiscal) return;

		const isLiberado = await this.call("RecursoFCD/verificarLiberacaoFiscal", to.codProvaFeita);

		if (isLiberado === true) return;

		return new Promise<void>((resolve) => {

			this.limpar();

			this.setTitulo(this.getMsg("FP_FRONT_RecursoVH_042"));
			this.setSubtitulo(this.getMsg("FP_FRONT_RecursoVH_060"));
			
			if (to.isChatProctoringHabilitado) {
				this.addTexto(this.getMsg("FP_FRONT_AberturaRecursosVH_005"));	
			} else {
				this.addTexto(this.getMsg("FP_FRONT_RecursoVH_061"));
				this.addTexto(this.getMsg("FP_FRONT_RecursoVH_062"));
			}


			this.addGrupoBotoes({
				classe: "pull-right",
				botoes: [{
					label: this.getMsg("FP_FRONT_RecursoVH_063"),
					tooltip: this.getMsg("FP_FRONT_RecursoVH_064"),
					classe: "btn-primary btn-lg",
					id: "btnVerificacaoAcessoRecursos",
					onClick: async () => {

						const isLiberado = await this.call("RecursoFCD/verificarLiberacaoFiscal", to.codProvaFeita);

						if (isLiberado) {
							// await this.exibirAlerta({
							// 	titulo: this.getMsg("FP_FRONT_RecursoVH_065"),
							// 	msg: this.getMsg("FP_FRONT_RecursoVH_066"),
							// 	botoes: [{
							// 		label: this.getMsg("FP_FRONT_RecursoVH_059"),
							// 		classe: "btn-primary",
							// 	}]
							// });
							resolve();
							return;
						}

						this.exibirAlerta({
							titulo: this.getMsg("FP_FRONT_RecursoVH_067"),
							msg: this.getMsg("FP_FRONT_RecursoVH_068")
						});

						this.disable("btnVerificacaoAcessoRecursos");

						$('#btnVerificacaoAcessoRecursos').html(`<i class='fa fa-clock'></i> ${this.getMsg("FP_FRONT_RecursoVH_069")}`);
						$('#msgAguardar').slideDown(300);

						setTimeout(() => {
							this.enable("btnVerificacaoAcessoRecursos");
							$('#btnVerificacaoAcessoRecursos').text(this.getMsg("FP_FRONT_RecursoVH_063"));
							$('#msgAguardar').slideUp(300);

							this.exibir();
						}, 15000)
					}
				}]
			});

			this.append("</div>");

			this.exibir();
		});
	}

	async exibirInstrucoesRecursos(to: CfgsAberturaRecursosTO) {

		if (to.situacaoRecursos === SituacaoRecursos.EM_RECURSOS) return;

		return new Promise<void>(async (resolve) => {

			this.limpar();
			
			this.setTitulo(this.getMsg("FP_FRONT_RecursoVH_042"));
			this.setSubtitulo("Instruções");

			await this.addComponentesInstrucoesRecursos(to);

			this.addGrupoBotoes({
				classe: "pull-right",
				botoes: [{
					label: this.getMsg("FP_FRONT_RecursoVH_059"),
					classe: "btn-primary btn-lg",
					onClick: async () => {
						resolve();
					}
				}]
			});

			this.exibir();
		});
	}

	async addComponentesInstrucoesRecursos(to: CfgsAberturaRecursosTO) {

		const instrucoesAberturaRecursoTO: InstrucoesAberturaRecursoTO = await this.call("RecursoFCD/recuperarInstrucoesRecursos", to.codProvaFeita);

		let listaInstrucoes: {texto: string}[] = [];
		let listaAlertas: string[] = [];

		listaInstrucoes.push({texto: this.getMsg("FP_FRONT_RecursoPFVH_012", instrucoesAberturaRecursoTO.tituloProva)});
		listaInstrucoes.push({texto: this.getMsg("MSG_VH_RC_51")});

		if (instrucoesAberturaRecursoTO.dataInicio && instrucoesAberturaRecursoTO.dataFim) {
			listaInstrucoes.push({texto: this.getMsg("FP_FRONT_RecursoVH_072", UtilData.toDDMMYYYYHHMMSS(instrucoesAberturaRecursoTO.dataInicio), UtilData.toDDMMYYYYHHMMSS(instrucoesAberturaRecursoTO.dataFim))});
		}

		if (instrucoesAberturaRecursoTO.isApresentarSomenteQuestoesErradasEmRecurso) {
			listaInstrucoes.push({texto: this.getMsg("FP_FRONT_RecursoVH_073")});
		}

		if (instrucoesAberturaRecursoTO.isAvaliadoVisualizaGabaritoRecursos) {
			listaInstrucoes.push({texto: this.getMsg("FP_FRONT_RecursoVH_074")});
		}

		if (instrucoesAberturaRecursoTO.tempoTotalAberturaRecursos) {
			listaInstrucoes.push({texto: this.getMsg("FP_FRONT_RecursoVH_075", instrucoesAberturaRecursoTO.tempoTotalAberturaRecursos)});
		}

		if (instrucoesAberturaRecursoTO.tempoPorQuestaoAberturaRecursos) {
			listaInstrucoes.push({texto: this.getMsg("FP_FRONT_RecursoVH_076", instrucoesAberturaRecursoTO.tempoPorQuestaoAberturaRecursos)});
		}

		if (instrucoesAberturaRecursoTO.isMonitoramentoSaidaAbaAtivo) {
			listaAlertas.push(`<h3>${this.getMsg("FP_FRONT_RecursoVH_077")}</h3>`);
		}

		if (instrucoesAberturaRecursoTO.isProctoringHabilitado) {
			listaAlertas.push(`<h3>${this.getMsg("FP_FRONT_RecursoVH_079")}</h3>`);
		}

		this.addListaComMarcadores(listaInstrucoes, this.getMsg("FP_FRONT_InicioPFVH_026"));

		if (this.hasValue(instrucoesAberturaRecursoTO.instrucoesPersonalizadas)) {
			this.append(`
				<div class='col-md-12' style='margin-top: 20px; margin-bottom: 20px'>
					${instrucoesAberturaRecursoTO.instrucoesPersonalizadas}
				</div>
			`);
		}

		if (listaAlertas.length) {
			this.addTextoErro({
				texto: listaAlertas.join("<br />"),
				css: "margin-bottom: 30px"
			});
		}
	}

	async interporRecurso(codProvaFeita: number, numRecurso?: number) {

		cronoRecursosVH.pararCronometroRecursos();
		this.recursoAtual = numRecurso || 0;

		const to: ExibicaoTelaInterposicaoRecursoTO = await this.call(
			"RecursoFCD/recuperarDadosInterposicaoRecurso", 
			codProvaFeita, 
			true
		);

		if (to.isRegrasMonSaidaAbaRecursosViolada === true) {
			await this.exibirAlerta({ msg: this.getMsg("FP_FRONT_RecursoPFVH_013") });
			await this.sairDaTelaDeRecursos(codProvaFeita);
			return;
		}

		const millisRestantes = to.milisegundosRestantesParaAberturaRecurso;

		if (this.hasValue(millisRestantes) && millisRestantes <= 0) {
			await this.handleTempoEsgotadoTelaRecursos(codProvaFeita);
			return;
		}

		if (to.listRecursosInterpostosDoUsuarioTO.length === 0) {
			await this.exibirAlerta({ msg: this.getMsg("FP_FRONT_RecursoVH_003") });
			await this.sairDaTelaDeRecursos(codProvaFeita);
			return;
		}

		const isApresentarQuestaoPorQuestaoEmRecurso = to.isApresentarQuestaoPorQuestaoEmRecurso;

		this.limpar();
		this.setTitulo(this.getMsg("MSG_VH_RC_50") + " <small>" + to.tituloProva + "</small>");

		this.append(`
			<div id="interpor-recurso" cod-prova-feita="${codProvaFeita}">
		`);

		let recursoSendoRespondidoNavegacaoPorQuestao: RecursosInterpostosDoUsuarioTO = null;

		this.addTexto(this.getMsg("MSG_VH_RC_51"));
		let possuiRecursosParaEnviar = false;

		for (let i = 0; i < to.listRecursosInterpostosDoUsuarioTO.length; i++) {

			const recursosInterpostosDoUsuarioTO = to.listRecursosInterpostosDoUsuarioTO[i];

			if (isApresentarQuestaoPorQuestaoEmRecurso && (this.recursoAtual != i)) {
				continue;
			}

			if (recursosInterpostosDoUsuarioTO.millisRestantesParaInterposicao != null) {
				recursoSendoRespondidoNavegacaoPorQuestao = recursosInterpostosDoUsuarioTO;
			}

			let pontuacaoDaRespostaQuestaoTO = null;
			const pontuacaoDaProvaFeitaTO = to.pontuacaoDaProvaFeitaTO;

			if (pontuacaoDaProvaFeitaTO) {
				pontuacaoDaRespostaQuestaoTO = aplicacaoProvaVH.getPontuacaoDaRespostaQuestaoTO(
					pontuacaoDaProvaFeitaTO, 
					recursosInterpostosDoUsuarioTO.exibicaoQuestaoTO
				);
			}

			exibicaoQuestaoVH.exibirQuestao(recursosInterpostosDoUsuarioTO.exibicaoQuestaoTO, {
				isExibirEstatisticas: false,
				isExibirComentarios: to.isExibirComentariosPublicosRecursos,
				isMostrarOperacoes: false,
				isExibirNomeSecao: true,
				conceito: pontuacaoDaRespostaQuestaoTO ? aplicacaoProvaVH.getNomeConceito(pontuacaoDaProvaFeitaTO.listaExibicaoMencaoTO, pontuacaoDaRespostaQuestaoTO.codMencao) : null,
				exibirConceito: to.isResultadoPorMencao,
			});

			this.addEspacamentoHorizontal("10px");
			let isTempoEsgotado = recursosInterpostosDoUsuarioTO.millisRestantesParaInterposicao != null && recursosInterpostosDoUsuarioTO.millisRestantesParaInterposicao <= 0;

			if (recursosInterpostosDoUsuarioTO.textoRecurso == null) {
				if (isTempoEsgotado) {
					this.addTexto(`<strong>${this.getMsg("FP_FRONT_RecursoVH_004")}</strong>`);
				} else {
					const codRespostaQuestao = recursosInterpostosDoUsuarioTO.exibicaoQuestaoTO.exibicaoRQTO.codRespostaQuestao;
					this.addTextArea({
						id: "textoRecurso_" + codRespostaQuestao,
						label: this.getMsg("MSG_VH_RC_52"),
						classe: "col-md-12",
						cssTextarea: "min-height: 120px; width: 600px",
						isCopyPasteCutDesabilitado: true
					});
					if(to.isUploadArquivo){
						this.append("<div class='col-md-12 flex-row-center'>");
						this.addZonaUpload({
							id: "importacaoArquivoRecurso_" + codRespostaQuestao,
							label: this.getMsg("MSG_VH_RC_64"),
							classe: "col-md-4"
						});
						this.append("</div>");
					}
					possuiRecursosParaEnviar = true;
				}
			} else {

				this.append("<blockquote style='float: left'>")
				this.append(`<small>${this.getMsg("FP_FRONT_RecursoVH_005", UtilData.toDDMMYYYY(recursosInterpostosDoUsuarioTO.dataCadastro))}</small>`)
				if(recursosInterpostosDoUsuarioTO.pathMidia){
					this.append(`
						<small>
							<a href="${recursosInterpostosDoUsuarioTO.pathMidia}" style="font-size: 1rem;">
								<i class="fa fa-files-o" aria-hidden="true"></i>
								${UtilArquivo.getNomeArquivo(recursosInterpostosDoUsuarioTO.pathMidia)}
							</a>
						</small>
					`);
				}
				this.append("<p>" + recursosInterpostosDoUsuarioTO.textoRecurso + "</p>")

				if (recursosInterpostosDoUsuarioTO.isRecursoComResposta !== true) {
					this.addLink({
						label: "<i class='fa  fa-trash-o'></i> Excluir Recurso",
						css: "padding-left: 0px;",
						onClick: async () => {
							if (await this.confirmar(this.getMsg("MSG_VH_RC_53"))) {
								await this.call("RecursoFCD/removerRecurso", recursosInterpostosDoUsuarioTO.codDuvida);
								this.interporRecurso(codProvaFeita, this.recursoAtual);
								return true;
							}
							return false;
						},
						visivel: !isTempoEsgotado
					});
				}

				this.append("</blockquote>");
			}

			if (recursosInterpostosDoUsuarioTO.dataResposta != null) {
				this.append("<blockquote class='pull-right'>")
				if (recursosInterpostosDoUsuarioTO.tipoRespostaRecurso === TipoRespostaRecurso.INDEFERIMENTO) {
					this.append(`<p><strong>${this.getMsg("FP_FRONT_RecursoVH_006")}</strong></p>`);
				} else if (recursosInterpostosDoUsuarioTO.tipoRespostaRecurso === TipoRespostaRecurso.DEFERIMENTO_PARCIAL) {
					this.append(`<p><strong>${this.getMsg("FP_FRONT_RecursoVH_011")}</strong></p>`);
				} else if (recursosInterpostosDoUsuarioTO.tipoRespostaRecurso === TipoRespostaRecurso.DEFERIMENTO) {
					this.append(`<p><strong>${this.getMsg("FP_FRONT_RecursoVH_007")}</strong></p>`);
				}
				this.append(`<small>${this.getMsg("MSG_VH_RC_54")} ${UtilData.toDDMMYYYY(recursosInterpostosDoUsuarioTO.dataResposta)}</small>`)
				this.append(`<p>${recursosInterpostosDoUsuarioTO.textoResposta}</p>`)
				this.append("</blockquote>")
			}

			this.addEspacamentoHorizontal();
		}

		const cfgsCronoRecursosTO: CfgsCronoRecursosTO = cronoRecursosVH.buildCfgsCronoRecursosTO(to, recursoSendoRespondidoNavegacaoPorQuestao);

		this.addTexto("<i>" + this.getMsg("MSG_VH_RC_55") + "</i>");

		if (isApresentarQuestaoPorQuestaoEmRecurso || possuiRecursosParaEnviar || to.milisegundosRestantesParaAberturaRecurso) {

			this.append(`
				<div barra-navegacao-recursos>
			`);

			const isComBtnProxima = isApresentarQuestaoPorQuestaoEmRecurso && (this.recursoAtual + 1) < to.listRecursosInterpostosDoUsuarioTO.length;

			if (isApresentarQuestaoPorQuestaoEmRecurso && this.recursoAtual > 0) {
				const key = UtilBoot.getKeymaster();
				key.unbind("command+left,ctrl+left");
				key("command+left,ctrl+left", function () {
					$("#botao_anterior").click();
				});

				this.addBotao({
					id: "botao_anterior",
					label: `
						<span class='hidden-xs'> 
							<i class='fa fa-arrow-left'></i> ${this.getMsg("MSG_VH_RC_58")}
						</span>
						<span class='visible-xs'>
							<i class='fa fa-arrow-left'></i> ${this.getMsg("MSG_VH_APR_181")}
						</span>
					`,
					classe: `btn-sm btn-success btn-primary`,
					css: "font-size: 110%",
					onClick: async () => {
						await this.salvarInterposicoes();
						this.recursoAtual--;
						this.interporRecurso(codProvaFeita, this.recursoAtual);
					},
				});

			} else {
				this.addBotao({
					label: this.getMsg("MSG_VH_RC_56"),
					classe: `btn-sm`,
					css: "font-size: 110%",
					onClick: async () => {
						await this.sairDaTelaDeRecursos(codProvaFeita);
					}
				});
			}

			this.append(
				cronoRecursosVH.gerarHtmlCronometroRecursos(cfgsCronoRecursosTO)
			);

			if (isComBtnProxima) {

				const key = UtilBoot.getKeymaster();
				key.unbind("command+right,ctrl+right");
				key("command+right,ctrl+right", function () {
					$("#botao_avancar").click();
				});

				this.addBotao({
					id: "botao_avancar",
					label: `
						<span class="hidden-xs">
							${this.getMsg("MSG_VH_RC_59")} <i class="fa fa-arrow-right"></i>
						</span>
						<span class="visible-xs">
							${this.getMsg("MSG_VH_APR_180")} <i class="fa fa-arrow-right"></i>
						</span>
					`,
					classe: `btn-sm btn-success`,
					css: "font-size: 110%",
					onClick: async () => {
						await this.salvarInterposicoes();
						this.recursoAtual++;
						this.interporRecurso(codProvaFeita, this.recursoAtual);
					}
				});
			}

			this.addBotao({
				label: isApresentarQuestaoPorQuestaoEmRecurso ? this.getMsg("FP_FRONT_RecursoVH_009") : this.getMsg("MSG_VH_RC_57"),
				classe: `btn-sm btn-primary`,
				css: "font-size: 110%",
				onClick: async () => {
					await this.salvarInterposicoes();
					await this.exibirAlerta({
						msg: this.getMsg("FP_FRONT_RecursoPFVH_010"),
						botoes: [{
							label: this.getMsg("FP_FRONT_RecursoPFVH_011"),
							classe: "btn-primary",
							onClick: async () => {
								await this.call("RecursoFCD/encerrarInterposicaoRecursos", codProvaFeita, this.getMsg("FP_FRONT_RecursoPFVH_009"));
								await this.sairDaTelaDeRecursos(codProvaFeita);
							}
						}]
					});
				}
			});

			this.append(`
				</div>
			`);
		}

		this.exibir();

		cronoRecursosVH.iniciarCronometroRecursos({
			cfgsCronoRecursosTO, 
			onTempoProvaEsgotado: async () => {
				await this.salvarInterposicoes();
				await this.handleTempoEsgotadoTelaRecursos(to.codProvaFeita);
			}, 
			onTempoQuestaoEsgotado: async (msDecorridos) => {
				await this.salvarInterposicoes();
				await this.interporRecurso(to.codProvaFeita, 0);
			}
		});

		if (to.segundosLimiteSaidaMonitoramento) {
			await monSaidaAbaRecursosVH.monitorarSaidaDoNavegador(codProvaFeita, to.segundosLimiteSaidaMonitoramento, 0);
		}

		this.append("</div>")
	}
	
	async salvarInterposicoes() {
		let listaInterposicaoRecursoTO = [];

		$("[id^='textoRecurso']").each((i, node) => {
			let id = node.id;
			let codRespostaQuestao = id.substring(id.indexOf("_") + 1);
			let interposicaoRecursoTO = {
				textoRecurso: this.getValor(node),
				codRespostaQuestao: codRespostaQuestao,
				uploadMidia: this.getValor("importacaoArquivoRecurso_" + codRespostaQuestao)
			}

			listaInterposicaoRecursoTO.push(interposicaoRecursoTO);
		});

		await this.call("RecursoFCD/interporRecurso", listaInterposicaoRecursoTO);
	}

	async handleTempoEsgotadoTelaRecursos(codProvaFeita: number) {

		await this.call("RecursoFCD/encerrarInterposicaoRecursos", codProvaFeita, this.getMsg("FP_FRONT_RecursoPFVH_009"));

		await this.exibirAlerta({
			titulo: this.getMsg("FP_FRONT_RecursoVH_070"),
			msg: this.getMsg("FP_FRONT_RecursoVH_071")
		});

		await this.sairDaTelaDeRecursos(codProvaFeita);		
	}

	async sairDaTelaDeRecursos(codProvaFeita: number) {

		await cronoRecursosVH.pararCronometroRecursos();

		try {
			await fiscalizacaoMonitoradaVH.pararMonitoramento();
		} catch (ignored) {}

		const visualizacaoResultadoAutorizadaTO: VisualizacaoResultadoAutorizadaTO = await this.call(
			"AplicacaoProvaFCD/verificarVisualizacaoResultadoAutorizada", 
			codProvaFeita
		);

		if (visualizacaoResultadoAutorizadaTO.isEncerrarSecaoLinkExterno) {
			await this.exibirAlerta({ msg: visualizacaoResultadoAutorizadaTO.msgEncerrarLinkExterno });
			await loginVH.handleClickBotaoSair(true);

		} else if (visualizacaoResultadoAutorizadaTO.isVisualizacaoResultadoAutorizada) {
			await aplicacaoProvaVH.exibirDetalhesProvaRealizada(codProvaFeita);

		} else {
			UtilHash.carregarTelaInicial();
		}
	}

	async confirmouDesistencia(codProvaFeita: number) {
		let confirmou = false;

		await this.exibirAlerta({
			msg: this.getMsg("FP_FRONT_AberturaRecursosVH_001"),
			botoes: [{
				label: this.getMsg("FP_FRONT_AberturaRecursosVH_002")
			}, {
				label: this.getMsg("FP_FRONT_AberturaRecursosVH_003"),
				classe: "btn-primary",
				onClick: async () => {
					confirmou = true;
				}
			}]
		});

		if (confirmou) {
			const msg = this.getMsg("FP_FRONT_AberturaRecursosVH_004")
			await this.call("RecursoFCD/encerrarInterposicaoRecursos", codProvaFeita, msg);
		}

		return confirmou;
	}
}

type CfgsAberturaRecursosTO = {
	codProvaFeita: number;
	codAgendamentoUsuario: number;
	tituloProva: string;
	dataInicio: string;
	dataFim: string;
	isFaseRecursosVigente: boolean;
	tipoFaseRecurso: TipoFaseRecurso;
	situacaoRecursos: SituacaoRecursos;
	deveAguardarLiberacaoFiscal: boolean;
	isProctoringHabilitado: boolean;
	isChatProctoringHabilitado: boolean;
	podeAgendarRecursos: boolean;
}
type ExibicaoTelaInterposicaoRecursoTO = {
	codProvaFeita: number;
	codAgendamentoUsuario: number;
	tituloProva: string;
	isApresentarQuestaoPorQuestaoEmRecurso: boolean;
	isApresentarSomenteQuestoesErradasEmRecurso: boolean;
	isExibirComentariosPublicosRecursos: boolean;
	isNaoMostrarRespostaRecurso: boolean;
	isUploadArquivo: boolean;
	isResultadoPorMencao: boolean;
	isRegrasMonSaidaAbaRecursosViolada: boolean;
	milisegundosRestantesParaAberturaRecurso: number;
	segundosLimiteSaidaMonitoramento: number;
	pontuacaoDaProvaFeitaTO: any;
	minutosCronometroParaInterposicao: number;
	listRecursosInterpostosDoUsuarioTO: RecursosInterpostosDoUsuarioTO[];
}
type RecursosInterpostosDoUsuarioTO = {
	codRespostaQuestao: number;
	codDuvida: number;
	numQuestaoNaProva: string;
	textoRecurso: string;
	dataCadastro: string;
	millisRestantesParaInterposicao: number;
	isRecursoComResposta: boolean;
	dataResposta: string;
	textoResposta: string;
	tipoRespostaRecurso: TipoRespostaRecurso;
	exibicaoQuestaoTO: any;
	pathMidia: string;
}
type InstrucoesAberturaRecursoTO = {
	dataInicio: string;
	dataFim: string;
	tituloProva: string;
	instrucoesPersonalizadas: string;
	isApresentarSomenteQuestoesErradasEmRecurso: boolean;
	isApresentarQuestaoPorQuestaoEmRecurso: boolean;
	isAvaliadoVisualizaGabaritoRecursos: boolean;
	isMonitoramentoSaidaAbaAtivo: boolean;
	isProctoringHabilitado: boolean;
	tempoTotalAberturaRecursos: number;
	tempoPorQuestaoAberturaRecursos: number;
}
class TipoFaseRecurso extends EnumFP {
	
	static FASE_RECURSO = new TipoFaseRecurso("FASE_RECURSO");
	static FASE_APLICACAO = new TipoFaseRecurso("FASE_APLICACAO");

	constructor(id: string) {
		super(TipoFaseRecurso.name, id, "");
	}	
}
class SituacaoRecursos extends EnumFP {
	
	static AGUARDANDO_AVALIADO = new SituacaoRecursos("AGUARDANDO_AVALIADO", "FP_FRONT_RecursoVH_035", "#aaa");
	static AGENDADO = new SituacaoRecursos("AGENDADO", "FP_FRONT_RecursoVH_036", "#22a");
	static AGUARDANDO_LIBERACAO_FISCAL = new SituacaoRecursos("AGUARDANDO_LIBERACAO_FISCAL", "FP_FRONT_RecursoVH_037", "#a22");
	static LIBERADO = new SituacaoRecursos("LIBERADO", "FP_FRONT_RecursoVH_038", "#aa2");
	static EM_RECURSOS = new SituacaoRecursos("EM_RECURSOS", "FP_FRONT_RecursoVH_039", "#a22");
	static ENCERRADO_SEM_INTERESSE = new SituacaoRecursos("ENCERRADO_SEM_INTERESSE", "FP_FRONT_RecursoVH_040", "#aaa");
	static ENCERRADO = new SituacaoRecursos("ENCERRADO", "FP_FRONT_RecursoVH_041", "#2a2");

	cor: string;

	constructor(id: string, idMsg: string, cor: string) {
		super(SituacaoRecursos.name, id, idMsg);
		this.cor = cor;
	}
}

const aberturaRecursosVH = new AberturaRecursosVH();